<template>
  <b-tabs
    pills
  >
    <b-tab active>
      <template #title>
        <feather-icon
          icon="ClipboardIcon"
          size="16"
          class="mr-0 mr-sm-50"
        />
        <span class="d-none d-sm-inline">{{ $root.VNameFrau }}</span>
      </template>
      <vorsorgeeinrichtung
        :ist-mutter="1"
        class="mt-2 pt-75"
      />
    </b-tab>

    <b-tab>
      <template #title>
        <feather-icon
          icon="ClipboardIcon"
          size="16"
          class="mr-0 mr-sm-50"
        />
        <span class="d-none d-sm-inline">{{ $root.VNameMann }}</span>
      </template>
      <vorsorgeeinrichtung
        :ist-mutter="0"
        class="mt-2 pt-75"
      />
    </b-tab>

    <b-tab>
      <template #title>
        <feather-icon
          icon="DivideCircleIcon"
          size="16"
          class="mr-0 mr-sm-50"
        />
        <span class="d-none d-sm-inline">Ausgleich</span>
      </template>
      <ausgleich
        class="mt-2 pt-75"
      />
    </b-tab>

  </b-tabs>

</template>

<script>
import {
  BTab, BTabs,
} from 'bootstrap-vue'
import Vorsorgeeinrichtung from './Vorsorgeeinrichtung.vue'
import Ausgleich from './Ausgleich.vue'

export default {
  components: {
    BTab,
    BTabs,
    Vorsorgeeinrichtung,
    Ausgleich,
  },
  data() {
    return {
    }
  },
  async mounted() {
    // this.$root.$refs.Grunddaten = this // Register to reload data when changing number of kidz in Allgemein...
  },
}
</script>

<style>

</style>
