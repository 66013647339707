<template>
  <div v-if="(!vi || !v)">Loading Please wait...
  </div>
  <div v-else>
    <b-form>

      <app-collapse type="shadow" class="mb-1" v-show="tst || (!vi.IstPensionFrau && !vi.IstPensionMann && (vi.HatVEFrau || vi.HatVEMann))">
        <app-collapse-item title="Vorsorgeausgleich - Verrechnung Austrittsleistung(en)">

          <b-row>
            <b-col cols="12" md="12">
              <blockquote>
                <b>Information</b><br>
                Die ehelichen Austrittsleistungen werden verrechnet, sofern vorhanden inkl. WEF-Vorbezüge.<br>
                Bei WEFV, wird automatisch ein Beleg mit der Berechnung erstellt, abgelegt und in die Beilagen aufgenommen.<br>
                Die Berechnung fliesst automatisch in die Konvention.
            </blockquote>
            </b-col>
          </b-row>

          <b-row>
            <!-- <b-col cols="12" md="3" v-show="vi.IVRenteProzFrau > 0 && vi.HypoALFrau > 0">
              <b-form-group label-align="right"
                :label="'Hypothetische Austrittsleistung ' + vi.VnameFrau"
                label-for="jBVGFrau"
              >
                <b-form-input type="number" class="text-right" :disabled="true"
                  v-model="vi.HypoALFrau"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12" md="3" v-show="vi.IVRenteProzMann > 0 && vi.HypoALMann > 0">
              <b-form-group label-align="right"
                :label="'Hypothetische Austrittsleistung ' + vi.VnameMann"
                label-for="jBVGFrau"
              >
                <b-form-input type="number" class="text-right" :disabled="true"
                  v-model="vi.HypoALMann"
                />
              </b-form-group>
            </b-col> -->

            <b-col cols="12" md="2">
              <b-form-group label-align="center"
                :label="'% an ' + vi.VnameFrau"
                label-for="aufteilSchluesselProzFrau"
              >
              <vue-slider
                v-model="v.AusgleichProzFrau"
                :min="0"
                :max="100"
                :tooltip="'always'"
                :tooltip-placement="'bottom'"
              />
              </b-form-group>
            </b-col>

            <b-overlay :show="isCalc" no-wrap></b-overlay>  <!-- obscures row content, otherwise enclose content to be obscured... -->
            <b-col cols="12" md="1">
              <b-button
                variant="primary"
                class="mt-2"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                @click="setAustritt"
              >
                Calc
              </b-button>
            </b-col>

            <b-col cols="12" md="3">
              <b-form-group label-align="right"
                label="Zu teilende Austrittsleistung"
                label-for="zTeilen"
              >
                <b-form-input v-facade="'########'" :formatter="formatNumber" class="text-right" :disabled="true" v-model="v.ZuTeilen" />
              </b-form-group>
            </b-col>

            <b-col cols="12" md="3">
              <b-form-group label-align="right"
                :label="'Betrag zugunsten ' + (anteilFrau < 0 ? vi.VnameMann : vi.VnameFrau)"
                label-for="jBVGFrau"
              >
                <b-form-input v-facade="'########'" :formatter="formatNumber" class="text-right" :disabled="true" v-model="v.AnteilFrau" />
              </b-form-group>
            </b-col>

          </b-row>

          <b-row v-show="v.AusgleichProzFrau !== 50">

            <b-col cols="12" md="12">
              <b-form-group
                label="Begründung Abweichung Teilung 50:50"
                label-for="begruendung"
              >
                <b-form-textarea
                  v-model="v.Begruendung"
                  rows="2"
                  max-rows="4"
                />
              </b-form-group>
            </b-col>

          </b-row>

          <b-row v-show="!vi.HatVEFrau && !vi.IstPensionFrau && vi.IVRenteProzFrau < 70">
            <b-col cols="12" md="4">
              <b-form-group
                :label="'Ausgleich via Auffangeinrichtung ' + this.$root.VNameFrau + '?'"
                label-for="ausgleichAE"
              >
                <b-form-checkbox
                  v-model="v.AEFrau"
                  class="custom-control-primary"
                  name="check-button"
                  switch
                />
              </b-form-group>
            </b-col>
            <b-col cols="12" md="8" v-show="!v.AEFrau">
              <b-form-group
                label="Name, Vertragsnr. und Adresse Freizügigkeitseinrichtung"
                label-for="ausgleichFE"
              >
                <b-form-textarea
                  v-model="v.FEFrau"
                  rows="2"
                  max-rows="4"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row v-show="!vi.HatVEMann && !vi.IstPensionMann && vi.IVRenteProzMann < 70">
            <b-col cols="12" md="4">
              <b-form-group
              :label="'Ausgleich via Auffangeinrichtung ' + this.$root.VNameMann + '?'"
                label-for="ausgleichAE"
              >
                <b-form-checkbox
                  v-model="v.AEMann"
                  class="custom-control-primary"
                  name="check-button"
                  switch
                />
              </b-form-group>
            </b-col>
            <b-col cols="12" md="8" v-show="!v.AEMann">
              <b-form-group
                label="Name, Vertragsnr. und Adresse Freizügigkeitseinrichtung"
                label-for="ausgleichFE"
              >
                <b-form-textarea
                  v-model="v.FEMann"
                  rows="2"
                  max-rows="4"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12" md="3">
              <b-button
              variant="primary"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                @click="speichernVorsorge"
              >
                Speichern
              </b-button>
            </b-col>
          </b-row>

        </app-collapse-item>
      </app-collapse>

      <app-collapse type="shadow" class="mb-1" v-show="tst || (((vi.IstBVGRenteFrau || vi.RenteEheFrau > 0) || (vi.IstBVGRenteMann || vi.RenteEheMann > 0)) && ((!vi.IstPensionFrau && !vi.HatVEFrau) || (!vi.IstPensionMann && !vi.HatVEMann)))">
        <app-collapse-item title="Pensionierung - Verrechnung Renten">

          <b-row>
            <b-col cols="12" md="12">
              <blockquote>
                <b>Information</b><br>
                Das Gericht entscheidet über die Rentenanteile, welche in lebenslange Renten umgerechnet und verrechnet werden.<br>
                Hat einer BVG-Rente und der andere ist nicht pensioniert, wird Rente übertragen.
            </blockquote>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12" md="3">
              <b-form-group label-align="right"
                :label="'Eheliche, BVG-Rente(n) p. a. ' + vi.VnameFrau"
                label-for="jBVGFrau"
              >
                <b-form-input v-facade="'#####'" :formatter="formatNumber" class="text-right" :disabled="true" v-model="vi.RenteEheFrau"
                  @blur="applyFormat($event, 'vi.RenteEheFrau')"
                  @focus="removeFormat"
                />
              </b-form-group>

            </b-col>

            <b-col cols="12" md="3">
              <b-form-group label-align="right"
                :label="'Eheliche, BVG-Rente(n) p. a. ' + vi.VnameMann"
                label-for="jBVGFrau"
              >
                <b-form-input v-facade="'#####'" :formatter="formatNumber" class="text-right" :disabled="true" v-model="vi.RenteEheMann"
                  @blur="applyFormat($event, 'vi.RenteEheMann')"
                  @focus="removeFormat"
                />
              </b-form-group>

            </b-col>

            <b-col cols="12" md="3" v-show="vi.IVRenteFrau > 0">
              <b-form-group label-align="right"
                :label="'Eheliche, BVG-IV-Rente(n) p. a. ' + vi.VnameFrau"
                label-for="jBVGIVFrau"
              >
                <b-form-input v-facade="'#####'" :formatter="formatNumber" class="text-right" :disabled="true" v-model="vi.IVRenteFrau"
                  @blur="applyFormat($event, 'vi.IVRenteFrau')"
                  @focus="removeFormat"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12" md="3" v-show="vi.IVRenteMann > 0">
              <b-form-group label-align="right"
                :label="'Eheliche, BVG-IV-Rente(n) p. a. ' + vi.VnameMann"
                label-for="jBVGIVMann"
              >
                <b-form-input v-facade="'#####'" :formatter="formatNumber" class="text-right" :disabled="true" v-model="vi.IVRenteMann"
                  @blur="applyFormat($event, 'vi.IVRenteMann')"
                  @focus="removeFormat"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row  v-show="!vi.HatVEFrau && !vi.IstPensionFrau && vi.IVRenteProzFrau < 70">
            <b-col cols="12" md="3">
              <b-form-group
                label="Ausgleich via Auffangeinrichtung?"
                label-for="ausgleichAE"
              >
                <b-form-checkbox
                  v-model="v.AEFrau"
                  class="custom-control-primary"
                  name="check-button"
                  switch
                />
              </b-form-group>
            </b-col>
            <b-col cols="12" md="9" v-show="!v.AEFrau">
              <b-form-group
                label="Name, Vertragsnr. und Adresse Freizügigkeitseinrichtung"
                label-for="ausgleichFE"
              >
                <b-form-textarea
                  v-model="v.FEFrau"
                  rows="2"
                  max-rows="4"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row  v-show="!vi.HatVEMann && !vi.IstPensionMann && vi.IVRenteProzMann < 70">
            <b-col cols="12" md="3">
              <b-form-group
                label="Ausgleich via Auffangeinrichtung?"
                label-for="ausgelichAE"
              >
                <b-form-checkbox
                  v-model="v.AEFrau"
                  class="custom-control-primary"
                  name="check-button"
                  switch
                />
              </b-form-group>
            </b-col>
            <b-col cols="12" md="9" v-show="!v.AEMann">
              <b-form-group
                label="Name, Vertragsnr. und Adresse Freizügigkeitseinrichtung"
                label-for="ausgleichFE"
              >
                <b-form-textarea
                  v-model="v.FEMann"
                  rows="2"
                  max-rows="4"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row v-show="(!vi.HatVEFrau && !vi.IstPensionFrau && vi.IVRenteProzFrau < 70) || (!vi.HatVEMann && !vi.IstPensionMann && vi.IVRenteProzMann < 70)" >
            <b-col cols="12" md="3">
              <b-button
              variant="primary"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                @click="speichernVorsorge"
              >
                Speichern
              </b-button>
            </b-col>
          </b-row>

        </app-collapse-item>
      </app-collapse>

      <app-collapse type="shadow" class="mb-1" v-show="tst || (((vi.IstBVGRenteFrau || vi.RenteEheFrau > 0) || (vi.IstBVGRenteMann || vi.RenteEheMann > 0)) && ((!vi.IstPensionFrau && vi.HatVEFrau) || (!vi.IstPensionMann && vi.HatVEMann)))">
        <app-collapse-item title="Pensionierung & Austrittsleistung - Verrechnung oder Ausgleich Rente und Austrittsleistung">

          <b-row>
            <b-col cols="12" md="12">
              <blockquote>
                <b>Information</b><br>
                Je nach individueller Situation ist die Verrechnung oder der Ausgleich der unterschiedlichen Ansprüche vorzunehmen.<br>
                Die Verrechnung erfolgt indem entweder die Rente zu Kapital umgerechnet oder das Austrittskapital verrentet, beide gegenübergestellt und verrechnet werden.<br>
                Beim Ausgleich erhält der pensionierte Ehegatte die Hälfte der Austrittsleistung des anderen und der andere eine lebenslange Rente oder den kapitalisierten Betrag.<br>
                Bei der Gegenüberstellung der Ansprüche kann sich herausstellen, dass weder ein Ausgleich noch eine Verrechnung erfolgen sollte - jeder behält was er hat.<br>
            </blockquote>
            </b-col>
          </b-row>

          <b-row>
              <b-col cols="12" md="3" v-show="vi.RenteEheFrau > 0">
                <b-form-group label-align="right"
                  :label="'Eheliche, jährliche BVG-Rente(n) ' + vi.VnameFrau"
                  label-for="jBVGFrau"
                >
                  <b-form-input v-facade="'#####'" :formatter="formatNumber" class="text-right" :disabled="true" v-model="vi.RenteEheFrau"
                    @blur="applyFormat($event, 'vi.RenteEheFrau')"
                    @focus="removeFormat"
                  />
                </b-form-group>
              </b-col>

              <b-col cols="12" md="3" v-show="vi.RenteEheMann > 0">
                <b-form-group label-align="right"
                  :label="'Eheliche, jährliche BVG-Rente(n) ' + vi.VnameMann"
                  label-for="jBVGFrau"
                >
                  <b-form-input v-facade="'#####'" :formatter="formatNumber" class="text-right" :disabled="true" v-model="vi.RenteEheMann"
                    @blur="applyFormat($event, 'vi.RenteEheMann')"
                    @focus="removeFormat"
                  />
                </b-form-group>
              </b-col>

              <b-col cols="12" md="2">
                <b-form-group label-align="center"
                  :label="'% an ' + vi.VnameFrau"
                  label-for="aufteilSchluesselProzFrau"
                >
                <vue-slider
                  v-model="v.AusgleichProzFrau"
                  :min="0"
                  :max="100"
                  :tooltip="'always'"
                  :tooltip-placement="'right'"
                />
                </b-form-group>
              </b-col>

              <b-overlay :show="isCalc" no-wrap></b-overlay>  <!-- obscures row content, otherwise enclose content to be obscured... -->
              <b-col cols="12" md="1">
                <b-button
                  variant="primary"
                  class="mt-2"
                  :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                  @click="setAustritt"
                >
                  Calc
                </b-button>
              </b-col>

              <b-col cols="12" md="3">
                <b-form-group label-align="right"
                  label="Zu teilende Austrittsleistung"
                  label-for="zTeilen"
                >
                  <b-form-input v-facade="'########'" :formatter="formatNumber" class="text-right" :disabled="true" v-model="v.ZuTeilen"
                    @blur="applyFormat($event, 'v.ZuTeilen')"
                    @focus="removeFormat"
                  />
                </b-form-group>
              </b-col>

              <b-col cols="12" md="3">
                <b-form-group label-align="right"
                  :label="'Betrag zugunsten ' + (anteilFrau < 0 ? vi.VnameMann : vi.VnameFrau)"
                  label-for="jBVGFrau"
                >
                  <b-form-input v-facade="'########'" :formatter="formatNumber" class="text-right" :disabled="true" v-model="v.AnteilFrau"
                    @blur="applyFormat($event, 'v.AnteilFrau')"
                    @focus="removeFormat"
                  />
                </b-form-group>
              </b-col>

            </b-row>

            <b-row v-show="v.AusgleichProzFrau !== 50">

              <b-col cols="12" md="12">
                <b-form-group
                  label="Begründung Abweichung Teilung 50:50"
                  label-for="begruendung"
                >
                  <b-form-textarea
                    v-model="v.Begruendung"
                    rows="2"
                    max-rows="4"
                  />
                </b-form-group>
              </b-col>

            </b-row>

            <b-row>

              <b-col cols="12" md="12">
                <b-form-group
                  label="Vorgehen Verrechnung / Ausgleich"
                  label-for="eigentum"
                >
                  <v-select
                    v-model="v.EntRenteAL"
                    :options="verrAusgl"
                    :reduce="val => val.value"
                    :clearable="false"
                  />
                </b-form-group>
              </b-col>

            </b-row>

            <b-row>
            <b-col cols="12" md="3">
              <b-button
              variant="primary"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                @click="speichernVorsorge"
              >
                Speichern
              </b-button>
            </b-col>
          </b-row>

        </app-collapse-item>
      </app-collapse>

      <app-collapse type="shadow" class="mb-1" v-show="tst || (vi.VorsorgeBetragFrau > 0 || vi.VorsorgeBetragMann > 0)">
        <app-collapse-item title="Vorsorge ausserhalb BVG - Entschädigung">

          <b-row>
            <b-col cols="12" md="12">
              <blockquote>
                <b>Information</b><br>
                Der Ausgleich von Vorsorgeguthaben ausserhalb des BVG erfolgt mit einer Entschädigung.<br>
            </blockquote>
            </b-col>
          </b-row>

          <b-row>
              <b-col cols="12" md="3">
                <b-form-group label-align="right"
                  :label="'Kapital ausserhalb BVG ' + vi.VnameFrau"
                  label-for="nBVGFrau"
                >
                  <b-form-input v-facade="'#######'" :formatter="formatNumber" class="text-right" :disabled="true" v-model="vi.VorsorgeBetragFrau" />
                </b-form-group>

              </b-col>

              <b-col cols="12" md="3">
                <b-form-group label-align="right"
                  :label="'Kapital ausserhalb BVG ' + vi.VnameMann"
                  label-for="nBVGMann"
                >
                  <b-form-input v-facade="'#######'" :formatter="formatNumber" class="text-right" :disabled="true" v-model="vi.VorsorgeBetragMann" />
                </b-form-group>
              </b-col>

              <b-col cols="12" md="2">
                <b-form-group label-align="right"
                  label="Auszugleichender Betrag"
                  label-for="nBVGausgleich"
                >
                  <b-form-input v-facade="'#######'" :formatter="formatNumber" class="text-right" :disabled="true" v-model="vi.NBVGBetrag" />
                </b-form-group>
              </b-col>

              <b-col cols="12" md="2">
                <b-form-group label-align="center"
                  :label="'% an ' + vi.VnameFrau"
                  label-for="nBVGausgleichProz"
                >
                <vue-slider
                  v-model="v.NBVGProzFrau"
                  :min="0"
                  :max="100"
                  :tooltip="'always'"
                  :tooltip-placement="'right'"
                />
                </b-form-group>
              </b-col>

              <b-col cols="12" md="2">
                <b-form-group label-align="right"
                  label="Ausgleich"
                  label-for="nBVGausgleichB"
                >
                  <b-form-input v-facade="'#######'" :formatter="formatNumber" class="text-right" :disabled="true" :value="NBVGBetragCalc / 100 * v.NBVGProzFrau" />
                </b-form-group>
              </b-col>

          </b-row>

          <b-row>
            <b-col cols="12" md="3">
              <b-button
              variant="primary"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                @click="speichernVorsorge"
              >
                Speichern
              </b-button>
            </b-col>
          </b-row>

        </app-collapse-item>
      </app-collapse>

      <app-collapse type="shadow" class="mb-1" v-show="tst || (!vi.HatVEFrau && !vi.HatVEMann)">
        <app-collapse-item title="Keine Vorsorgeguthaben - Entschädigung">

          <b-row>
            <b-col cols="12" md="12">
              <blockquote>
                <b>Information</b><br>
                Sind kein Vorsorgeguthaben vorhanden kann der Ausgleich mit einer Entschädigung erfolgen.<br>
            </blockquote>
            </b-col>
          </b-row>

          <b-row>
              <b-col cols="12" md="3">
                <b-form-group label-align="right"
                  label="Entschädigung"
                  label-for="abfindKeineVorsorge"
                >
                  <b-form-input v-facade="'#######'" :formatter="formatNumber" class="text-right" v-model="v.NVorsorgeEntschaed"
                    @blur="applyFormat($event, 'v.NVorsorgeEntschaed')"
                    @focus="removeFormat"
                  />
                </b-form-group>

              </b-col>
              <b-col cols="12" md="2">
                <b-form-group
                  :label="'Entschädigung an ' + vi.VnameFrau"
                  label-for="abfindAn"
                >
                  <b-form-checkbox
                    v-model="v.NVorsorgeEntschaedAnFrau"
                    class="custom-control-primary"
                    name="check-button"
                    switch
                  />
                </b-form-group>
              </b-col>
          </b-row>

          <b-row>
            <b-col cols="12" md="3">
              <b-button
              variant="primary"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                @click="speichernVorsorge"
              >
                Speichern
              </b-button>
            </b-col>
          </b-row>

        </app-collapse-item>
      </app-collapse>

      <app-collapse type="shadow" class="mb-1" v-show="tst || (!vi.IstGuetertrennung && (vi.AuszahlungFrau > 0 || vi.AuszahlungMann > 0))">
        <app-collapse-item title="Bar- & Kapitalauszahlungen - Entschädigung">

          <b-row>
            <b-col cols="12" md="12">
              <blockquote>
                <b>Information</b><br>
                Bei Bar- und Kapitalauszahlungen wird das nicht mehr in der Vorsorge enthaltene Kapital mit einer Entschädigung ausgeglichen.<br>
            </blockquote>
            </b-col>
          </b-row>

          <b-table
            striped
            hover
            responsive
            class="position-relative"
            :items="va"
            primary-key="AuszahlungID"
            :fields="fieldsAuszahlungen"
          >
          <template v-slot:cell(Art)="row">
              <b-form-input :disabled="true"
                  v-model="row.item.ArtBez"
                />
            </template>
            <template v-slot:cell(Vorname)="row">
              <b-form-input :disabled="true"
                  v-model="row.item.Vorname"
                />
            </template>
            <template v-slot:cell(Betrag)="row">
              <b-form-input v-facade="'#######'" :formatter="formatNumber" :disabled="true" v-model="row.item.Betrag" class="text-right" />
            </template>
            <template v-slot:cell(KapitalScheidung)="row">
              <b-form-input v-facade="'#######'" :formatter="formatNumber" :disabled="true" v-model="row.item.KapitalScheidung" class="text-right" />
            </template>
            <template v-slot:cell(AbfindungProzFrau)="row">
              <vue-slider
                  v-model="row.item.AbfindungProzFrau"
                  :min="0"
                  :max="100"
                  :tooltip="'always'"
                  :tooltip-placement="'right'"
                />
            </template>
            <template v-slot:cell(Abfindung)="row">
              <b-form-input v-facade="'#######'" :formatter="formatNumber" :disabled="row.item.AbfindungProzFrau !== 0"
                :value="Math.round(Math.abs((row.item.Vorname === vi.VnameFrau ? row.item.KapBetrag : 0) - (row.item.KapBetrag / 100 * row.item.AbfindungProzFrau)))"
                class="text-right"
              />
            </template>

          </b-table>

          <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="speichernAuszahlungen"
          >
            Speichern
          </b-button>
        </app-collapse-item>
      </app-collapse>

      <b-overlay :show="isCalc" no-wrap></b-overlay>
      <b-row>
        <b-col cols="12" md="10">
          <div class="mb-1 text-right">
            <b-button
              variant="primary"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              @click="setAustritt"
            >
            Vorsorgeausgleich generieren
            </b-button>
          </div>
        </b-col>
        <b-col cols="12" md="2">
          <div class="mb-1 text-right">
            <b-button
              :disabled="v.DosBelegID === null"
              variant="primary"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              @click="showVA"
            >
            Anzeigen
            </b-button>
          </div>
        </b-col>
      </b-row>

    </b-form>
    <div v-show="false">

      <pdf-viewer
        ref="pv"
        :modal-i-d="modalID"
        :pdf-in="pdfIn"
        :file-name="dateiName"
      />
    </div>

  </div>
</template>

<script>

import {
  BForm, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BOverlay, BButton, BTable, BFormTextarea,
} from 'bootstrap-vue'
import VueSlider from 'vue-slider-component'
import vSelect from 'vue-select'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import vorsorge from '@/service/vorsorge'
import beleg from '@/service/beleg'
import PdfViewer from '../../compo/PdfViewer.vue'
// import bwt from '@/service/bwtafel' // Beispiel um die Barwerttaflen zu benutzen um die Rente zu kapitalisieren...

export default {
  components: {
    BForm,
    AppCollapse,
    AppCollapseItem,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BOverlay,
    BButton,
    BFormTextarea,
    VueSlider,
    vSelect,
    BTable,
    PdfViewer,
  },
  data() {
    const verrAusgl = [
      { value: 1, label: 'Verrechnung kapitalisierte lebenslange Rente mit Austrittsleistung - Zustimmung Ehegatten und Pensionskassen erforderlich' },
      { value: 2, label: 'Verrechnung lebenslange Rente mit verrenteter Austrittsleistung - Durchführbarkeitserklärung' },
      { value: 3, label: 'Ausgleich Umrechnung Rentenanteil in lebenslange Rente und Teilung Austrittsleistung - Durchführbarkeitserklärung' },
      { value: 4, label: 'Ausgleich Umrechnung lebenslange Rente in Kapital und Teilung Austrittsleistung - Vereinbarung Pensionskasse und berechtigte Person' },
      { value: 5, label: 'Weder Verrechnung noch Ausgleich, jeder behält was er hat - Prüfung Gericht' },
    ]

    return {
      tst: this.$root.currDosID === 3528, // for testing purpose of all areas - must be set to false for production, better to create several dossiers for each test case...
      verrAusgl,
      vi: null,
      v: null,
      va: null,
      isCalc: false,
      NBVGBetragCalc: 0,
      NBVGBetrag: 0,
      anteilFrau: 0,
      pdfIn: null,
      dateiName: null,
      modalID: 'ausgleich',
      fieldsAuszahlungen: [
        { key: 'ArtBez', label: 'Auszahlungsart' }, // eslint-disable-line
        { key: 'Vorname', label: 'Auszahlung an' }, // eslint-disable-line
        { key: 'Betrag', label: 'Betrag', thClass: 'text-right' }, // eslint-disable-line
        { key: 'KapitalScheidung', label: 'Kapitalwert Scheidung', thClass: 'text-right' }, // eslint-disable-line
        { key: 'AbfindungProzFrau', label: '% Betrag an ' + this.$root.VNameFrau }, // eslint-disable-line
        { key: 'Abfindung', label: 'Ausgleich', thClass: 'text-right' }, // eslint-disable-line
      ],
    }
  },
  async created() {
    // console.log(bwt.bwfMann[67]['zs3.5']) // Beispiel um auf den Barwertfaktor Mann 67-jährig bei Scheidung mit Zinssatz 3.5%
    const ri = await vorsorge.getVorsorgeInfo(this.$root.currDosID)
    if (ri.data.length > 0) {
      this.vi = ri.data[0]
      this.NBVGBetragCalc = this.vi.NBVGBetrag
    }
    const rv = await vorsorge.getVorsorge(this.$root.currDosID)
    if (rv.data.length > 0) {
      this.v = rv.data[0]
      this.anteilFrau = this.v.AnteilFrau
    }

    const ra = await vorsorge.getVEAuszahlungVorsorge(this.$root.currDosID)
    if (ra.data.length > 0) this.va = ra.data
  },
  methods: {
    formatNumber(value) {
      if (typeof value === 'string') {
        value = value.replace(/\B(?=(\d{3})+(?!\d))/g, "'") // eslint-disable-line
        return value
      }
      return false
    },
    formatBetrag(val) {
      let v = val.replaceAll(/[’']/g, '')
      v = Intl.NumberFormat('de-CH').format(v)
      return v
    },
    // applyFormatTbl(event, row, value) { // eslint-disable-line
    //   event.target.value = event.target.value.replace(/\B(?=(\d{3})+(?!\d))/g, "'") // eslint-disable-line
    //   let ix = 0
    //   for (; ix < this.va.length; ix++) if (this.va[ix].AuszahlungID === row.item.AuszahlungID) break // eslint-disable-line
    //   this.va[ix][value] = event.target.value
    //   this.isEditing = false
    // },
    applyFormat(event, value) {
      event.target.value = (event.target.value || '0').replace(/^0+(?!$)/, '').replace(/\B(?=(\d{3})+(?!\d))/g, "'") // eslint-disable-line
      const parts = value.split('.')
      if (parts.length === 2) this[parts[0]][parts[1]] = event.target.value // eslint-disable-line
      else this[parts[0]] = event.target.value // eslint-disable-line
      this.isEditing = false
    },
    removeFormat(event) {
      this.isEditing = true
      event.target.value = event.target.value.replace(/[^\d]/g, '') // eslint-disable-line
      if (event.target.value === '0') event.target.value = '' // eslint-disable-line
    },
    async speichernVorsorge() {
      await vorsorge.putVorsorge(this.v)
      this.$root.showToast('Speichern', 'Vorsorgeausgleich erfolgreich gespeichert!')
    },
    async speichernAuszahlungen() {
      for (let i = 0; i < this.va.length; i++) this.va[i].Abfindung = Math.abs((this.va[i].Vorname === this.vi.VnameFrau ? this.va[i].KapBetrag : 0) - (this.va[i].KapBetrag / 100 * this.va[i].AbfindungProzFrau)) // eslint-disable-line
      await vorsorge.putVEAuszahlungVorsorge(this.va)
      this.$root.showToast('Entschädigung Auszahlungen', 'Entschädigung(en) für Auszahlungen wurde(n) erfolgreich gespeichert!')
    },
    async setAustritt() {
      // console.log(this.$root.currDosID)
      this.isCalc = true
      await vorsorge.putVorsorge(this.v) // saving the percentage for calculation...
      const r = await vorsorge.getVorsorgeAusgleich(this.$root.currDosID, this.v.DosBelegID, this.$g.user.Sprache)
      if (r != null) {
        this.v.ZuTeilen = r.data.ZuTeilen
        // this.v.AnteilFrau = Intl.NumberFormat('de-CH').format(r.data.AnteilFrau)
        this.anteilFrau = r.data.AnteilFrau // for condition in template, when value formated not working...
        this.v.AnteilFrau = r.data.AnteilFrau
        // this.v.AnteilMann = Intl.NumberFormat('de-CH').format(r.data.AnteilMann)
        this.v.AnteilMann = r.data.AnteilMann
        await vorsorge.putVorsorge(this.v) // saving the calculated values...
      }

      const rv = await vorsorge.getVorsorge(this.$root.currDosID)
      if (rv.data.length > 0) {
        this.v = rv.data[0]
        this.anteilFrau = this.v.AnteilFrau
      }

      this.$root.showToast('Vorsorgausgleich generieren', 'Vorsorgeausgleich generieret, bereit zum Anzeigen!')
      this.isCalc = false
    },
    async showVA() {
      const rb = await beleg.getBelegDatei(this.$root.currDosID, this.v.DosBelegID)
      if (rb !== null) {
        this.dateiName = 'Vorsorgeausgleich.pdf'
        this.pdfIn = rb.data
        this.$refs.pv.show()
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-slider.scss';
</style>
