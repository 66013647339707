import axios from '@axios'

export default {
  async getVorsorgeKonfig(dosID) {
    try {
      const r = await axios.get('vorsorge/getVorsorgeKonfig', {
        params: {
          dosID,
        },
      })
      return r
    } catch (error) {
      // console.log(error)
      this.errored = true
    }
    return null
  },
  async getVorsorgeeinrichtung(vEID, dosID, eltID) {
    const dosBriefID = 0
    try {
      const r = await axios.get('vorsorge/getVorsorgeeinrichtung', {
        params: {
          vEID,
          dosID,
          eltID,
          dosBriefID,
        },
      })
      return r
    } catch (error) {
      // console.log(error)
      this.errored = true
    }
    return null
  },
  async getVorsorgeeinrichtungSel(dosID, eltID) {
    try {
      const r = await axios.get('vorsorge/getVorsorgeeinrichtungSel', {
        params: {
          dosID,
          eltID,
        },
      })
      return r
    } catch (error) {
      // console.log(error)
      this.errored = true
    }
    return null
  },
  async putVorsorgeeinrichtung(data) {
    try {
      const d = JSON.parse(JSON.stringify(data))
      d.LeistungVorehe = (d.LeistungVorehe ?? '0').toString().replaceAll(/[’']/g, '')
      d.LeistungVoreheAufgezinst = (d.LeistungVoreheAufgezinst ?? '0').toString().replaceAll(/[’']/g, '')
      d.LeistungScheidung = (d.LeistungScheidung ?? '0').toString().replaceAll(/[’']/g, '')
      d.EintrittLeistung95 = (d.EintrittLeistung95 ?? '0').toString().replaceAll(/[’']/g, '')
      d.AustrittLeistung95 = (d.AustrittLeistung95 ?? '0').toString().replaceAll(/[’']/g, '')
      d.VorsorgeBetrag = (d.VorsorgeBetrag ?? '0').toString().replaceAll(/[’']/g, '')
      d.BVGIVRenteProz = (d.BVGIVRenteProz ?? '0').toString().replaceAll(/[’']/g, '')
      d.IVRente = (d.IVRente ?? '0').toString().replaceAll(/[’']/g, '')
      d.IVRenteKuerzung = (d.IVRenteKuerzung ?? '0').toString().replaceAll(/[’']/g, '')
      d.HypoLeistung = (d.HypoLeistung ?? '0').toString().replaceAll(/[’']/g, '')
      d.AktiveLeistung = (d.AktiveLeistung ?? '0').toString().replaceAll(/[’']/g, '')
      d.RenteJahr = (d.RenteJahr ?? '0').toString().replaceAll(/[’']/g, '')
      d.RenteJahrEhe = (d.RenteJahrEhe ?? '0').toString().replaceAll(/[’']/g, '')
      d.LeistungPension = (d.LeistungPension ?? '0').toString().replaceAll(/[’']/g, '')
      d.KapitalzahlungPension = (d.KapitalzahlungPension ?? '0').toString().replaceAll(/[’']/g, '')
      d.Rentenausfall = (d.Rentenausfall ?? '0').toString().replaceAll(/[’']/g, '')
      d.KapitalzahlungScheidung = (d.KapitalzahlungScheidung ?? '0').toString().replaceAll(/[’']/g, '')
      const r = await axios.get('vorsorge/getVorsorgeeinrichtungInsUpd', {
        params: {
          data: JSON.stringify(d),
        },
      })
      return r
    } catch (error) {
      // console.log(error)
      this.errored = true
    }
    return null
  },
  // Create consists of 2 steps: 1. Create DB-entry by korrespondenz.putDossierBrief (Brief-Controller), 2. Create it using the template with following method...
  async createBriefVorsorge(dosBriefID, sprache) {
    try {
      const r = await axios.get('vorsorge/getDossierBriefVorsorgeCreate', {
        params: {
          dosBriefID,
          sprache,
        },
      })
      return r
    } catch (error) {
      // console.log(error)
      this.errored = true
    }
    return null
  },
  async getAustrittsleistungenVorHeirat95(heirat, scheidung, vor95, leistungVor95, nach95, leistungNach95) {
    try {
      const r = await axios.get('vorsorge/GetAustrittleistungen1995', {
        params: {
          heirat,
          scheidung,
          vor95,
          leistungVor95,
          nach95,
          leistungNach95,
        },
      })
      return r
    } catch (error) {
      // console.log(error)
      this.errored = true
    }
    return null
  },
  async getVEEinlagen(vID) {
    const vEID = vID === null ? 0 : vID
    try {
      const r = await axios.get('vorsorge/getVEEinlagen', {
        params: {
          vEID,
        },
      })
      return r
    } catch (error) {
      // console.log(error)
      this.errored = true
    }
    return null
  },
  async putVEEinlagen(data, dosID) {
    try {
      const d = JSON.parse(JSON.stringify(data))
      for (let i = 0; i < d.length; i += 1) {
        d[i].BetragEigen = (d[i].BetragEigen ?? '0').toString().replaceAll(/[’']/g, '')
        d[i].BetragEigenAZ = (d[i].BetragEigenAZ ?? '0').toString().replaceAll(/[’']/g, '')
        d[i].BetragErrungen = (d[i].BetragErrungen ?? '0').toString().replaceAll(/[’']/g, '')
      }
      const r = await axios.get('vorsorge/getVEEinlageInsUpd', {
        params: {
          data: JSON.stringify(d),
          dosID,
        },
      })
      return r
    } catch (error) {
      // console.log(error)
      this.errored = true
    }
    return null
  },
  async getVEEinlagenDelete(einlageID, dosID, dosBelegID) {
    try {
      const r = await axios.get('vorsorge/getVEEinlageDelete', {
        params: {
          einlageID,
          dosID,
          dosBelegID,
        },
      })
      return r
    } catch (error) {
      // console.log(error)
      this.errored = true
    }
    return null
  },
  async getVEAuszahlungen(vID) {
    const vEID = vID === null ? 0 : vID
    try {
      const r = await axios.get('vorsorge/getVEAuszahlungen', {
        params: {
          vEID,
        },
      })
      return r
    } catch (error) {
      // console.log(error)
      this.errored = true
    }
    return null
  },
  async putVEAuszahlung(data) {
    try {
      const d = JSON.parse(JSON.stringify(data))
      for (let i = 0; i < d.length; i += 1) {
        d[i].Betrag = (d[i].Betrag ?? '0').toString().replaceAll(/[’']/g, '')
        d[i].Rentenausfall = (d[i].Rentenausfall ?? '0').toString().replaceAll(/[’']/g, '')
        d[i].KapitalScheidung = (d[i].KapitalScheidung ?? '0').toString().replaceAll(/[’']/g, '')
      }
      const r = await axios.get('vorsorge/getVEAuszahlungInsUpd', {
        params: {
          data: JSON.stringify(d),
        },
      })
      return r
    } catch (error) {
      // console.log(error)
      this.errored = true
    }
    return null
  },
  async getVEAuszahlungDelete(auszahlungID) {
    try {
      const r = await axios.get('vorsorge/getVEAuszahlungDelete', {
        params: {
          auszahlungID,
        },
      })
      return r
    } catch (error) {
      // console.log(error)
      this.errored = true
    }
    return null
  },
  async getWEFObjekt(dosID) {
    try {
      const r = await axios.get('vorsorge/getWEFObjekt', {
        params: {
          dosID,
        },
      })
      return r
    } catch (error) {
      // console.log(error)
      this.errored = true
    }
    return null
  },
  async putWEFObjekt(data) {
    try {
      const d = JSON.parse(JSON.stringify(data))
      d.KapitalwertScheidung = (d.KapitalwertScheidung ?? '0').toString().replaceAll(/[’']/g, '')
      d.BetragEntwidmet = (d.BetragEntwidmet ?? '0').toString().replaceAll(/[’']/g, '')
      d.Rentenausfall = (d.Rentenausfall ?? '0').toString().replaceAll(/[’']/g, '')
      const r = await axios.get('vorsorge/getWEFObjektInsUpd', {
        params: {
          data: JSON.stringify(d),
        },
      })
      return r
    } catch (error) {
      // console.log(error)
      this.errored = true
    }
    return null
  },
  async getWEFTrans(vID) {
    const vEID = vID === null ? 0 : vID
    try {
      const r = await axios.get('vorsorge/getWEFTrans', {
        params: {
          vEID,
        },
      })
      return r
    } catch (error) {
      // console.log(error)
      this.errored = true
    }
    return null
  },
  async putWEFTrans(data, dosID) {
    try {
      const d = JSON.parse(JSON.stringify(data))
      for (let i = 0; i < d.length; i += 1) {
        d[i].Betrag = (d[i].Betrag ?? '0').toString().replaceAll(/[’']/g, '')
        d[i].LeistungTrans = (d[i].LeistungTrans ?? '0').toString().replaceAll(/[’']/g, '')
        d[i].BetragEigen = (d[i].BetragEigen ?? '0').toString().replaceAll(/[’']/g, '')
        d[i].BetragErrungen = (d[i].BetragErrungen ?? '0').toString().replaceAll(/[’']/g, '')
        d[i].Verlust = (d[i].Verlust ?? '0').toString().replaceAll(/[’']/g, '')
        d[i].Rentenausfall = (d[i].Rentenausfall ?? '0').toString().replaceAll(/[’']/g, '')
        d[i].KapitalwertScheidung = (d[i].KapitalwertScheidung ?? '0').toString().replaceAll(/[’']/g, '')
      }
      const r = await axios.get('vorsorge/getWEFTransInsUpd', {
        params: {
          data: JSON.stringify(d),
          dosID,
        },
      })
      return r
    } catch (error) {
      // console.log(error)
      this.errored = true
    }
    return null
  },
  async getWEFTransDelete(wEFTransID, dosID, belegID) {
    const dosBelegID = belegID === null ? 0 : belegID
    try {
      const r = await axios.get('vorsorge/getWEFTransDelete', {
        params: {
          wEFTransID,
          dosID,
          dosBelegID,
        },
      })
      return r
    } catch (error) {
      // console.log(error)
      this.errored = true
    }
    return null
  },
  async getVorsorgeInfo(dosID) {
    try {
      const r = await axios.get('vorsorge/getVorsorgeInfo', {
        params: {
          dosID,
        },
      })
      return r
    } catch (error) {
      // console.log(error)
      this.errored = true
    }
    return null
  },
  async getVorsorge(dosID) {
    try {
      const r = await axios.get('vorsorge/getVorsorge', {
        params: {
          dosID,
        },
      })
      return r
    } catch (error) {
      // console.log(error)
      this.errored = true
    }
    return null
  },
  async putVorsorge(data) {
    try {
      const d = JSON.parse(JSON.stringify(data))
      d.NBVGBetrag = (d.NBVGBetrag ?? '0').toString().replaceAll(/[’']/g, '')
      d.NVorsorgeEntschaed = (d.NVorsorgeEntschaed ?? '0').toString().replaceAll(/[’']/g, '')
      d.AusgleichProzFrau = (d.AusgleichProzFrau ?? '0').toString().replaceAll(/[’']/g, '')
      d.ZuTeilen = (d.ZuTeilen ?? '0').toString().replaceAll(/[’']/g, '')
      d.AnteilFrau = (d.AnteilFrau ?? '0').toString().replaceAll(/[’']/g, '')
      d.AnteilMann = (d.AnteilMann ?? '0').toString().replaceAll(/[’']/g, '')
      const r = await axios.get('vorsorge/getVorsorgeUpd', {
        params: {
          data: JSON.stringify(d),
        },
      })
      return r
    } catch (error) {
      // console.log(error)
      this.errored = true
    }
    return null
  },
  async getVorsorgeAusgleich(dosID, dosBelegID, sprache) {
    try {
      const r = await axios.get('vorsorge/getVorsorgeAusgleich', {
        params: {
          dosID,
          dosBelegID: dosBelegID === null ? -1 : dosBelegID,
          sprache,
        },
      })
      return r
    } catch (error) {
      // console.log(error)
      this.errored = true
    }
    return null
  },
  async getVEAuszahlungVorsorge(dosID) {
    try {
      const r = await axios.get('vorsorge/getVEAuszahlungVorsorge', {
        params: {
          dosID,
        },
      })
      return r
    } catch (error) {
      // console.log(error)
      this.errored = true
    }
    return null
  },
  async putVEAuszahlungVorsorge(data) {
    const d = JSON.parse(JSON.stringify(data))
    for (let i = 0; i < d.length; i += 1) {
      d[i].Betrag = (d[i].Betrag ?? '0').toString().replaceAll(/[’']/g, '')
      d[i].KapitalScheidung = (d[i].KapitalScheidung ?? '0').toString().replaceAll(/[’']/g, '')
      d[i].Abfindung = (d[i].Abfindung ?? '0').toString().replaceAll(/[’']/g, '')
    }
    try {
      const r = await axios.get('vorsorge/getVEAuszahlungVorsorgeUpd', {
        params: {
          data: JSON.stringify(d),
        },
      })
      return r
    } catch (error) {
      // console.log(error)
      this.errored = true
    }
    return null
  },
}
