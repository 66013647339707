import axios from '@axios'

export default {
  async getDossierBrief(dosBriefID, sprache) {
    try {
      const r = await axios.get('brief/getDossierBrief', {
        params: {
          dosBriefID,
          sprache,
        },
      })
      return r
    } catch (error) {
      // console.log(error)
      this.errored = true
    }
    return null
  },
  async getDossierBriefe(dosID, art, sprache) {
    try {
      const r = await axios.get('brief/getDossierBriefe', {
        params: {
          dosID,
          art,
          sprache,
        },
      })
      return r
    } catch (error) {
      // console.log(error)
      this.errored = true
    }
    return null
  },
  async createBrief(dosBriefID, sprache) { // 2. step - creates the document from the appropriate template...
    try {
      const r = await axios.get('brief/getDossierBriefCreate', {
        params: {
          dosBriefID,
          sprache,
        },
      })
      return r
    } catch (error) {
      // console.log(error)
      this.errored = true
    }
    return null
  },
  async putDossierBrief(data) { // 1. step - create record in DossierBrief, no create from template...
    try {
      const r = await axios.get('brief/getDossierBriefInsUpd', {
        params: {
          data: JSON.stringify(data),
        },
      })
      return r
    } catch (error) {
      // console.log(error)
      this.errored = true
    }
    return null
  },
  async getBriefDatei(dosBriefID, istWord) { // Download...
    try {
      const r = await axios.get('brief/getDossierBriefDatei', {
        responseType: 'blob',
        headers: { Accept: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' },
        params: {
          dosBriefID,
          istWord,
        },
      })
      return r
    } catch (error) {
      // console.log(error)
    }
    return null
  },
  async getDossierBriefSFDT(dosBriefID) { // Download sfdt...
    try {
      const r = await axios.get('brief/getDossierBriefSFDT', {
        params: {
          dosBriefID,
        },
      })
      return r
    } catch (error) {
      // console.log(error)
    }
    return null
  },
  async getConvertDoc2SFDT(datei) { // Convert uploaded doc 2 sfdt...
    const formData = new FormData()
    try {
      formData.append('brief', datei)
      const r = await axios.post('brief/postConvertDoc2SFDT', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      return r
    } catch (error) {
      // console.log(error)
    }
    return null
  },
  async postBrief(datei, dosBriefID) {
    const formData = new FormData()
    try {
      formData.append('brief', datei)
      const r = await axios.post('brief/postDossierBrief', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
        params: {
          dosBriefID,
        },
      })
      return r
    } catch (error) {
      // console.log(error)
    }
    return null
  },
  async postBriefSFDT(formData, dosBriefID) {
    try {
      const r = await axios.post('brief/postDossierBrief', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
        params: {
          dosBriefID,
        },
      })
      return r
    } catch (error) {
      // console.log(error)
    }
    return null
  },
  async getDossierVollmacht(vornamek, namek, art, mandantid, vornameb, nameb, sprache) { // Download sfdt...
    try {
      const r = await axios.get('brief/getDossierVollmachtCreate', {
        params: {
          vornamek,
          namek,
          art,
          mandantid,
          vornameb,
          nameb,
          sprache,
        },
      })
      return r
    } catch (error) {
      // console.log(error)
    }
    return null
  },
  async getGerichtSel(dosID) {
    try {
      const r = await axios.get('sproc/getGerichtSel', {
        params: {
          dosID,
        },
      })
      return r
    } catch (error) {
      // console.log(error)
      this.errored = true
    }
    return null
  },
  async getGericht(gerichtID) {
    try {
      const r = await axios.get('sproc/getGericht', {
        params: {
          gerichtID,
        },
      })
      return r
    } catch (error) {
      // console.log(error)
      this.errored = true
    }
    return null
  },
}
