<template>

  <!-- <div v-if="pdfAPIReady === false || pdfReady === false">
    <div class="w-100 text-center">
      <h3 v-if="pdfAPIReady" />
      <div
        id="pdf-view"
        style="height: 50vh;"
      />
    </div>
  </div>
  <div v-else>
    <div
      v-if="pdfAPIReady === true && pdfReady === true"
      class="w-100 text-center"
    >
      <div
        id="pdf-view"
        style="height: 75vh;"
      />
    </div>
  </div> -->

  <b-card>

    <b-modal
      :id='modalID'
      title="PDF Viewer"
      ok-title="Schliessen"
      cancel-title="Abbrechen"
      cancel-variant="outline-secondary"
      size="xl"
      :hide-footer="hideFooter"
      no-close-on-backdrop
    >
      <!-- <b-row>
        <b-col md="3 text-right mt-2">
          <b-button
            variant="primary"
            @click="download()"
          >
            Herunterladen
          </b-button>
        </b-col>
      </b-row>
 -->
      <div class="w-100 text-center"
        id="pdf-view"
        style="height: 75vh;">
      </div>

    </b-modal>

  </b-card>

</template>

<script>
import {
  BCard, // BRow, BCol, BButton,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    // BButton,
    // BRow,
    // BCol,
  },
  props: {
    pdfIn: Blob,
    fileName: String,
    modalID: { // Only necessary when the modal component is used more than one time in the same form or in more than one tab-item, as in Vorsorge...
      default: 'modal',
      type: String,
    },
  },
  data() {
    const ADOBE_KEY = (window.location.href.toString().includes('localhost') ? 'b128238126aa454b8bb908b2a06cd02f' : 'a86fda26efa441559b28d422cbadb90b')
    const viewerConfig = {
      embedMode: 'LIGHT_BOX',
      showDownloadPDF: true,
      showAnnotationTools: false,
      showBookmarks: false,
      // theme: 'dark', // doesn't work..
    }
    return {
      file: null,
      pdf: null,
      pdfReady: false,
      ADOBE_KEY,
      viewerConfig,
      pdfAPIReady: this.$root.pdfAPIReady,
      hideFooter: true,
    }
  },
  methods: {
    async show() {
      this.$bvModal.show(this.modalID)
      await this.$nextTick() // Trick to get $refs not to be undefined...
      this.loadShowPdf()
    },
    async loadShowPdf() {
      // this.pdf = new Blob(this.pdfIn, { type: 'application/pdf' })
      await this.previewPDF()
    },
    async previewPDF() {
      if (this.pdfAPIReady) {
        this.pdfReady = false
        const av = new window.AdobeDC.View({ clientId: this.ADOBE_KEY, divId: 'pdf-view', locale: (this.$g.user.Sprache === 'fr' ? 'fr-FR' : 'de-DE') })
        av.previewFile({ content: { promise: Promise.resolve(this.pdfIn.arrayBuffer()) }, metaData: { fileName: this.fileName }, configuration: this.viewerConfig }) // eslint-disable-line
        this.pdfReady = true
      }
    },
    async download() {
      const fileURL = window.URL.createObjectURL(this.pdfIn)
      const fileLink = document.createElement('a')
      fileLink.href = fileURL
      fileLink.setAttribute('download', what === 'v' ? 'Vollmacht.pdf' : ((what === 'b' ? this.begehren : this.vereinbarung) + '.pdf')) // eslint-disable-line
      document.body.appendChild(fileLink)
      fileLink.click()
    },
  },
}

</script>
