<template>
  <div v-if="(!vk || !ve || !b || !be || !ee || !ba || !wo)">Loading Please wait...
  </div>
  <div v-else>
    <b-form>

      <b-row>
        <b-col cols="12" md="2" v-show="(ves.length > 1)">
          <b-form-group
            label="Einrichtung wählen"
            label-for="ve"
          >
            <v-select
              v-model="vEID"
              :options="ves"
              :reduce="val => val.value"
              :clearable="false"
              @input="changeVE"
            />
          </b-form-group>
        </b-col>

        <b-col v-show="ve.VEID === null" cols="12" md="3">
          <b-form-group
            label="PK oder Freizügigkeit vorhanden?"
            label-for="VE-FE"
          >
            <b-form-checkbox
              v-model="ve.HatVE"
              class="custom-control-primary"
              name="check-button"
              switch
            />
          </b-form-group>
        </b-col>

        <b-col cols="12" md="3" v-show="vk.Art === 1">
          <b-form-group
            label="BVG IV-Rente vor Pensionierung?"
            label-for="IVVorPension"
          >
            <b-form-checkbox
              v-model="istBVGIVVorPension"
              :disabled="true"
              class="custom-control-primary mt-1"
              name="check-button"
              switch
            />
          </b-form-group>
        </b-col>

        <b-col cols="12" md="3" v-show="vk.Art === 1">
          <b-form-group
            label="Bezug BVG Alters- und/oder IV-Rente?"
            label-for="BVGRente"
          >
            <b-form-checkbox
              v-model="ve.IstBVGRente"
              :disabled="true"
              class="custom-control-primary mt-1"
              name="check-button"
              switch
            />
          </b-form-group>
        </b-col>

        <b-col cols="12" :md="((ves.length > 1) ? 4 : 6) + ((vk.Art !== 1) ? 6 : 0)" v-show="ve.VEID !== null">
          <div class="mb-1 text-right">
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              class="mt-2 ml-1"
              v-b-tooltip.hover.top="'Weitere Einrichtung hinzufügen?'"
              @click="addVE"
            >
              <feather-icon
                icon="PlusCircleIcon"
                class="mr-50"
              />
              <span class="align-middle">Neue Einrichtung</span>
            </b-button>
          </div>
        </b-col>

      </b-row>

      <app-collapse v-show="ve.HatVE" type="shadow" class="mb-1">
        <app-collapse-item ref="collVE" id="collVE" :title="'Vorsorgeeinrichtung' + (ve.HatVE && ve.Name !== null ? ': ' + ve.Name : '')">

          <b-row v-show="ve.VEID !== null || ve.HatVE">
            <b-col cols="12" md="3">
              <b-form-group
                label="Pensionskasse?"
                label-for="IstVE"
              >
                <b-form-checkbox
                  v-model="ve.IstVE"
                  class="custom-control-primary"
                  name="check-button"
                  switch
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row v-show="ve.HatVE">

            <b-col cols="12" md="3">
              <b-form-group
                label="Pensionskasse, bzw. Freizügigkeitseinrichtung"
                label-for="name"
              >
                <b-form-input
                  v-model="ve.Name"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12" md="3" v-show="ve.IstVE">
              <b-form-group
                label="Vertrags-Nr."
                label-for="Vertrag"
              >
                <b-form-input
                  v-model="ve.VertragsNr"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12" md="3" v-show="ve.IstVE">
              <b-form-group
                label="Versicherten-Nr."
                label-for="Versicherter"
              >
                <b-form-input
                  v-model="ve.VersichertenNr"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12" md="3" v-show="!ve.IstVE">
              <b-form-group
                label="Konto/Police 1"
                label-for="kp1"
              >
                <b-form-input
                  v-model="ve.KontoPolice1"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12" md="3" v-show="!ve.IstVE">
              <b-form-group
                label="Konto/Police 2"
                label-for="kp2"
              >
                <b-form-input
                  v-model="ve.KontoPolice2"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12" md="3" v-show="vk.Art === 1">
              <b-form-group
                label="Eintrittsdatum"
                label-for="Eintritt"
              >
                <flat-pickr
                  id="eintritt"
                  v-model="ve.Eintritt"
                  :config="config"
                  class="form-control"
                />
              </b-form-group>
            </b-col>

          </b-row>

          <b-row v-show="ve.VEID !== null || ve.HatVE">
            <b-col cols="12" md="3">
              <b-form-group
                label="WEF-Vorbezug vorhanden?"
                label-for="WEFV"
              >
                <b-form-checkbox
                  v-model="ve.HatWEFV"
                  class="custom-control-primary"
                  name="check-button"
                  switch
                />
              </b-form-group>
            </b-col>

            <b-col cols="12" md="3" v-show="vk.Art === 1">
              <b-form-group
                label="Ausgleich aus BVG-Obligatorium in %"
                label-for="AusglOblig%"
              >
                <vue-slider
                  v-model="ve.AusgleichObligProz"
                  :min="0"
                  :max="100"
                  :tooltip="'always'"
                  :tooltip-placement="'right'"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12" md="3" v-show="(ve.VEID !== null || ve.HatVE) && !ve.IstPension && vk.Art === 1 && vk.IstHeiratVor1995 && ve.Eintritt > '1995-01-01'">
              <b-form-group
                label="Letzte bekannte Vorsorgeeinrichtung vor Heirat"
                label-for="LetzteVEVorHeirat"
              >
                <b-form-input
                  v-model="ve.VEVorHeirat1995"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12" md="3" v-show="(ve.VEID !== null || ve.HatVE) && !ve.IstPension && vk.Art === 1 && vk.IstHeiratVor1995 && ve.Eintritt > '1995-01-01'">
              <b-form-group
                label="Erste bekannte Vorsorgeeinrichtung nach Heirat"
                label-for="ErsteVENachHeirat"
              >
                <b-form-input
                  v-model="ve.VENachHeirat1995"
                />
              </b-form-group>
            </b-col>

          </b-row>

          <b-row v-show="!ve.IstPension && vk.Art === 1 && ve.HatVE">
            <!-- <b-col cols="12" md="3"> !-- Adresse wird in DossierBrief gespeichert... --
              <b-form-group
                label="Adresse"
                label-for="Adresse"
              >
                <b-form-textarea
                  v-model="ve.Adresse"
                  rows="2"
                  max-rows="3"
                />
              </b-form-group>
            </b-col> -->

            <b-col cols="12" md="3">
              <b-form-group
                :label="(ve.IstVE ? 'Austrittsleistung' : 'Freizügigkeitsleistung') + ' bei Heirat'" label-align="right"
                label-for="ALHeirat"
              >
                <b-form-input v-facade="'########'" :formatter="formatNumber" class="text-right" v-model="ve.LeistungVorehe"
                  @blur="applyFormat($event, 've.LeistungVorehe')"
                  @focus="removeFormat"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12" md="3">
              <b-form-group
                label="... aufgezinst bis Einleitung Scheidung" label-align="right"
                label-for="ALHeirat"
              >
                <b-form-input v-facade="'########'" :formatter="formatNumber" class="text-right" v-model="ve.LeistungVoreheAufgezinst"
                  @blur="applyFormat($event, 've.LeistungVoreheAufgezinst')"
                  @focus="removeFormat"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12" md="3">
              <b-form-group
                label="... bei Einleitung Scheidung" label-align="right"
                label-for="ALScheidung"
              >
                <b-form-input v-facade="'########'" :formatter="formatNumber" class="text-right" v-model="ve.LeistungScheidung"
                  @blur="applyFormat($event, 've.LeistungScheidung')"
                  @focus="removeFormat"
                />
              </b-form-group>
            </b-col>

            <b-col v-show="vk.IstHeiratVor1995 && ve.Eintritt > '1995-01-01'" cols="12" md="3">
              <b-form-group
                :label="(ve.IstVE ? 'Austrittsleistung' : 'Freizügigkeitsleistung') + ' nicht bekannt'"
                label-for="HeiratVor95"
              >
                <b-form-checkbox
                  v-model="IstCalcAL95"
                  class="custom-control-primary"
                  name="check-button"
                  switch
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row v-show="(IstCalcAL95 && vk.Art === 1)">

            <b-col cols="12" md="3">
              <b-form-group
                label="Datum letzt bekannte Leistung vor Heirat"
                label-for="ALHeirat"
              >
                <flat-pickr
                  id="vor95"
                  v-model="ve.Eintritt95"
                  :config="config"
                  class="form-control"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12" md="3">
              <b-form-group
                label="Leistung vor Heirat" label-align="right"
                label-for="ALHeirat"
              >
                <b-form-input v-facade="'########'" :formatter="formatNumber" class="text-right" v-model="ve.EintrittLeistung95"
                  @blur="applyFormat($event, 've.EintrittLeistung95')"
                  @focus="removeFormat"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12" md="3">
              <b-form-group
                label="Datum erst bekannte Leistung nach Heirat"
                label-for="ALScheidung"
              >
                <flat-pickr
                  id="vor95"
                  v-model="ve.Austritt95"
                  :config="config"
                  class="form-control"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12" md="2">
              <b-form-group
                label="Leistung nach Heirat" label-align="right"
                label-for="ALHeirat"
              >
                <b-form-input v-facade="'########'" :formatter="formatNumber" class="text-right" v-model="ve.AustrittLeistung95"
                  @blur="applyFormat($event, 've.AustrittLeistung95')"
                  @focus="removeFormat"
                />
              </b-form-group>
            </b-col>

            <b-overlay :show="isCalc" no-wrap></b-overlay>  <!-- obscures row content, otherwise enclose content to be obscured... -->
            <b-col cols="12" md="1">
              <b-button
                variant="primary"
                class="mt-2"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                @click="setAustritt"
              >
                Calc
              </b-button>
            </b-col>
          </b-row>

          <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="speichernVE"
          >
            Speichern
          </b-button>

        </app-collapse-item>
      </app-collapse>

      <app-collapse v-show="(ve.VEID !== null && b !== null)" type="shadow" class="mb-1">
        <app-collapse-item title="Brief an Einrichtung / Antwort">
          <b-row>
            <b-col cols="12" md="4">
              <b-form-group
                label="Anrede"
                label-for="anrede"
              >
                <b-form-input
                  v-model="b.Anrede"
                  trim
                  placeholder="Sehr geehrte..."
                />
              </b-form-group>
            </b-col>
            <b-col cols="12" md="4">
              <b-form-group
                label="Ort, Datum"
                label-for="ortDatum"
              >
                <b-form-input
                  v-model="b.OrtDatum"
                  trim
                  placeholder="Ort, Datum..."
                />
              </b-form-group>
            </b-col>
            <b-col cols="12" md="4">
              <b-form-group
                label="Adresse"
                label-for="adresse"
              >
                <b-form-textarea
                  v-model="b.Adresse"
                  rows="2"
                  max-rows="4"
                />
              </b-form-group>
            </b-col>

          </b-row>

          <b-overlay :show="isCalc" no-wrap></b-overlay>  <!-- obscures row content, otherwise enclose content to be obscured... -->
          <b-row>
            <b-col cols="12" md="2">
              <b-button
                variant="primary"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                @click="createBrief()"
              >
                Brief erstellen
              </b-button>
            </b-col>

            <b-col cols="12" md="2">
              <b-button
                :disabled="ve.DosBriefID === null"
                variant="primary"
                @click="show()"
              >
                Bearbeiten
              </b-button>
            </b-col>

            <b-col cols="12" md="2">
              <b-button
                :disabled="ve.DosBriefID === null"
                variant="primary"
                @click="handleBeleg(belegTyp.VEAntwort, null)"
              >
                Antwort Beleg
              </b-button>
            </b-col>

          </b-row>

          <!-- <b-row>
            <b-col cols="12" md="6 mt-1">
              <b-form-file
                v-model="file"
                accept=".pdf"
                :placeholder="be === null || be.DateiName === null ? 'Antwortbeleg auswählen oder hier ablegen...' : be.DateiName"
                drop-placeholder="Datei hier ablegen..."
              />
              <small
                v-show="file !== null && file.size > maxFileSize"
                class="text-danger"
              >{{ $g.browserLocale === 'fr' ? 'La grandeur du ficher ne doit pas depasser 500 KB!' : 'Die Datei darf nicht grösser als 500 KB sein!' }}</small>
            </b-col>

            <b-col cols="12" md="3 mt-1">
              <b-button
                :disabled="file === null"
                variant="primary"
                @click="speichernBeleg()"
              >
                Antwort speichern
              </b-button>
            </b-col>

            <b-col cols="12" md="3 mt-1 text-right">
              <b-button
                variant="primary"
                :disabled="ve.DosBelegID === null"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                @click="downloadBeleg"
              >
                Herunterladen
              </b-button>
            </b-col>
          </b-row> -->

          <div v-show="false">
            <word-editor
              ref="we"
              dateiName="Vereinbarung"
              :modal-i-d="modalID"
              :doc-in="docIn"
              :modal-height="modalHeight"
              @getDocBlob="getDocBlob"
            />
          </div>

        </app-collapse-item>
      </app-collapse>

      <app-collapse v-show="ve.VEID !== null && ve.HatWEFV" type="shadow" class="mb-1">
        <app-collapse-item title="Wohneigentum (WEF) und Transaktionen">
          <b-row>
            <b-col cols="12" md="2">
              <b-form-group
                label="Datum Liegenschaft"
                label-for="datWEFObj"
              >
                <flat-pickr
                  id="datWEFObj"
                  v-model="wo.Gekauft"
                  :config="config"
                  class="form-control"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12" md="2">
              <b-form-group
                label="Grundstück-Nr"
                label-for="woGrundstueck"
              >
                <b-form-input
                  v-model="wo.GrundStueckNr"
                  trim
                />
              </b-form-group>
            </b-col>

            <b-col cols="12" md="2">
                <b-form-group
                  label="Vorsorgenehmer:in WEF"
                  label-for="eigentum"
                >
                  <v-select
                    v-model="wo.Eigentum"
                    :options="wEFEigentum"
                    :reduce="val => val.value"
                    :clearable="false"
                  />
                </b-form-group>
              </b-col>

            <b-col cols="12" md="4">
              <b-form-group
                label="Beschreibung"
                label-for="woBdeschrieb"
              >
                <b-form-input
                  v-model="wo.Beschrieb"
                  trim
                />
              </b-form-group>
            </b-col>

            <b-col cols="12" md="2">
              <b-button
                variant="primary"
                class="mt-2"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                @click="handleBeleg(belegTyp.WEFObjekt, null)"
              >
                Grundbuchauszug
              </b-button>
            </b-col>

          </b-row>

          <b-row>
            <b-col cols="12" md="2">
              <b-form-group
                label="Entwidmet am"
                label-for="datWEFObjEntwidmet"
              >
                <flat-pickr
                  id="datWEFObjEntwidmet"
                  v-model="wo.Entwidmet"
                  :config="config"
                  class="form-control"
                />
              </b-form-group>
            </b-col>

            <!-- <b-col cols="12" md="2" v-show="wo.Entwidmet !== null">
                  <b-form-group
                    label="Betrag entwidmet" label-align="right"
                    label-for="betragentwidmet"
                  >
                    <b-form-input type="number" class="text-right"
                      v-model="wo.BetragEntwidmet"
                    />
                  </b-form-group>
              </b-col>

              <b-col cols="12" md="2" v-show="wo.Entwidmet !== null">
                  <b-form-group
                    label="Rentenausfall p.a." label-align="right"
                    label-for="rentenausfall"
                  >
                    <b-form-input type="number" class="text-right"
                      v-model="wo.Rentenausfall"
                      @blur="setKapitalwert"
                    />
                  </b-form-group>
              </b-col>

              <b-col cols="12" md="2" v-show="wo.Entwidmet !== null">
                  <b-form-group
                    label="Kapitalwert Scheidung" label-align="right"
                    label-for="kapWertScheidungEntw"
                  >
                    <b-form-input type="number" class="text-right"
                      v-model="wo.KapitalwertScheidung"
                    />
                  </b-form-group>
              </b-col> -->

          </b-row>

          <b-table v-show="!ve.IstPension"
            ref="selectableTable"
            selectable
            select-mode="single"
            striped
            hover
            responsive
            class="position-relative mt-1"
            :items="wt"
            primary-key="WEFTransID"
            selected-variant="primary"
            :fields="fieldsWEFTrans"
            @row-selected="showWEFTrans"
          >
            <template #row-details="row">
              <b-row v-show="row.item.TransTyp==='V'">
                <b-col cols="12" md="12">
                  <blockquote>
                    <b>Information</b><br>
                    Beim Verkauf kann der Betrag auf ein Freizügigkeitskonto oder eine -police übertragen werden, falls in den nächsten zwei Jahren die Absicht zum Kauf von Wohneigentum besteht. Dazu muss eine neue Einrichtung erfasst werden.
                </blockquote>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12" md="2">
                <b-form-group
                  label="Typ"
                  label-for="typ"
                >
                  <v-select
                    v-model="row.item.TransTyp"
                    :options="transTyp"
                    :reduce="val => val.value"
                    :clearable="false"
                    @input="setTransEntwidmet(row.item)"
                  />
                </b-form-group>
                </b-col>

                <b-col cols="12" md="2">
                  <b-form-group
                    label="Datum"
                    label-for="datTrans"
                  >
                    <flat-pickr
                      id="eeDatum"
                      v-model="row.item.Datum"
                      :config="config"
                      class="form-control"
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="12" md="2" v-show="row.item.TransTyp==='B'">
                  <b-form-group
                    label="Verpfändet"
                    label-for="vorbezugVerpfaennd"
                  >
                    <b-form-checkbox
                      v-model="row.item.IstVerpfaendet"
                      class="custom-control-primary"
                      name="check-button"
                      switch
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="12" md="2" v-show="row.item.TransTyp!=='R'">
                  <b-form-group label-align="right"
                    label="Betrag"
                    label-for="vorbezugBetrag"
                  >
                    <b-form-input v-facade="'########'" :formatter="formatNumber" class="text-right" v-model="row.item.Betrag"
                      @blur="applyFormatWT($event, row, 'Betrag')"
                      @focus="removeFormat"
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="12" md="2" v-show="row.item.TransTyp==='R'">
                  <b-form-group label-align="right"
                    label="Eigengut"
                    label-for="wefRueckEG"
                  >
                    <b-form-input v-facade="'########'" :formatter="formatNumber" class="text-right" v-model="row.item.BetragEigen"
                      @blur="applyFormatWT($event, row, 'BetragEigen')"
                      @focus="removeFormat"
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="12" md="2" v-show="row.item.TransTyp==='R'">
                  <b-form-group label-align="right"
                    label="Errungenschaft"
                    label-for="wefRueckErr"
                  >
                    <b-form-input v-facade="'########'" :formatter="formatNumber" class="text-right" v-model="row.item.BetragErrungen"
                      @blur="applyFormatWT($event, row, 'BetragErrungen')"
                      @focus="removeFormat"
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="12" md="2" v-show="row.item.TransTyp==='V'">
                  <b-form-group label-align="right"
                    label="Verlust"
                    label-for="verlust"
                  >
                    <b-form-input v-facade="'########'" :formatter="formatNumber" class="text-right" v-model="row.item.Verlust"
                      @blur="applyFormatWT($event, row, 'Verlust')"
                      @focus="removeFormat"
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="12" md="2" v-show="!row.item.IstVerpfaendet">
                  <b-form-group label-align="right"
                    :label="'Austrittsleist. ' + (row.item.TransTyp==='B' ? 'Vorbezug' : row.item.TransTyp==='R' ? 'Rückzahlung' : row.item.TransTyp==='V' ? 'Verkauf' : 'Entwidmung')"
                    label-for="vorbezugBetrag"
                  >
                    <b-form-input v-facade="'########'" :formatter="formatNumber" class="text-right" v-model="row.item.LeistungTrans"
                      @blur="applyFormatWT($event, row, 'LeistungTrans')"
                      @focus="removeFormat"
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="12" md="2" v-show="row.item.TransTyp==='E' && wo.Entwidmet !== null">
                  <b-form-group
                    label="Rentenausfall p.a." label-align="right"
                    label-for="rentenausfall"
                  >
                    <!-- <b-form-input v-facade="'########'" :formatter="formatNumber" class="text-right" v-model="row.item.Rentenausfall"
                      @blur="setKapitalwert(row.item)"
                    /> -->
                    <b-form-input v-facade="'########'" class="text-right" v-model="row.item.Rentenausfall"
                      @blur="setKapitalwert(row.item)"
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="12" md="2" v-show="row.item.TransTyp==='E' && wo.Entwidmet !== null">
                    <b-form-group
                      label="Kapitalwert Scheidung" label-align="right"
                      label-for="kapWertScheidungEntw"
                    >
                      <b-form-input v-facade="'########'" :formatter="formatNumber" class="text-right" v-model="row.item.KapitalwertScheidung"
                        @blur="applyFormatWT($event, row, 'KapitalwertScheidung')"
                        @focus="removeFormat"
                      />
                    </b-form-group>
                </b-col>

                <b-col cols="12" md="1" v-show="row.item.WEFTransID > 0 && ((row.item.TransTyp==='R' && row.item.BetragEigen !== '0') || row.item.TransTyp==='V')">
                  <b-button
                  variant="primary"
                  class="mb-1 mb-sm-0 mr-0 mr-sm-1 mt-2"
                  :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                  @click="handleBeleg(belegTyp.WEFTrans, row.item)"
                  >
                    Beleg
                  </b-button>
                </b-col>

                <b-col cols="12" md="1">
                  <b-button
                    v-show="row.item.WEFTransID > 0"
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    v-b-tooltip.hover.top="'Transaktion löschen?'"
                    size="sm"
                    variant="outline-primary"
                    class="btn-icon mt-2"
                    @click="deleteWEFTrans(row.item)"
                  >
                    <feather-icon icon="XCircleIcon" />
                  </b-button>
                </b-col>

              </b-row>
            </template>

          </b-table>

          <b-button
              variant="primary"
              class="mt-1"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              @click="speichernWEFObjekt"
            >
              Speichern
          </b-button>

          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            class="mt-1 ml-1"
            @click="addWEFTrans"
          >
            <feather-icon
              icon="PlusCircleIcon"
              class="mr-50"
            />
            <span class="align-middle">Transaktion</span>
          </b-button>

        </app-collapse-item>
      </app-collapse>

      <app-collapse v-show="istBVGIVVorPension && vk.Art === 1 && ve.IstVE" type="shadow" class="mb-1">
        <app-collapse-item title="IV-Rente vor Pensionierung">

        <b-row v-show="istBVGIVVorPension && vk.Art === 1 && ve.IstVE">
          <b-col cols="12" md="3">
            <b-form-group
              label="Invaliditätsgrad in %"
              label-for="IVProz"
            >
              <vue-slider
                v-model="ve.BVGIVRenteProz"
                :min="40"
                :max="100"
                :tooltip="'always'"
                :tooltip-placement="'right'"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12" md="3">
            <b-form-group
              label="IV-Rente jährlich, ungekürzt" label-align="right"
              label-for="IVRenteJahr"
            >
              <b-form-input v-facade="'########'" :formatter="formatNumber" class="text-right" v-model="ve.IVRente"
                @blur="applyFormat($event, 've.IVRente')"
                @focus="removeFormat"
              />
            </b-form-group>
          </b-col>

          <b-col cols="12" md="3">
            <b-form-group
              label="Allfällige Kürzung aus Überentschädigung" label-align="right"
              label-for="IVRenteJahr"
            >
              <b-form-input v-facade="'########'" :formatter="formatNumber" class="text-right" v-model="ve.IVRenteKuerzung"
                @blur="applyFormat($event, 've.IVRenteKuerzung')"
                @focus="removeFormat"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row v-show="istBVGIVVorPension && vk.Art === 1 && ve.IstVE">
          <b-col cols="12" md="3">
            <b-form-group
              label="Hypothetische Austrittsleistung" label-align="right"
              label-for="HypoAL"
            >
              <b-form-input v-facade="'########'" :formatter="formatNumber" class="text-right" v-model="ve.HypoLeistung"
                @blur="applyFormat($event, 've.HypoLeistung')"
                @focus="removeFormat"
              />
            </b-form-group>
          </b-col>

          <b-col v-show="ve.BVGIVRenteProz < 70" cols="12" md="3">
            <b-form-group
              label="Aktive Austrittsleistung" label-align="right"
              label-for="HypoAL"
            >
              <b-form-input v-facade="'########'" :formatter="formatNumber" class="text-right" v-model="ve.AktiveLeistung"
                @blur="applyFormat($event, 've.AktiveLeistung')"
                @focus="removeFormat"
              />
            </b-form-group>
          </b-col>
        </b-row>
        </app-collapse-item>
      </app-collapse>

      <app-collapse v-show="(ve.IstPension)" type="shadow" class="mb-1">
        <app-collapse-item title="Pensionierung">
          <b-row>
            <b-col cols="12" md="3" v-show="ve.IstVE">
              <b-form-group
                label="Austrittsleistung Pensionierung" label-align="right"
                label-for="ALPension"
              >
                <b-form-input v-facade="'########'" :formatter="formatNumber" class="text-right" v-model="ve.LeistungPension"
                  @blur="applyFormat($event, 've.LeistungPension')"
                  @focus="removeFormat"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12" md="3">
              <b-form-group
                label="Kapitalauszahlung Pensionsionierung" label-align="right"
                label-for="KapzahlPension"
              >
                <b-form-input v-facade="'########'" :formatter="formatNumber" class="text-right" v-model="ve.KapitalzahlungPension"
                  @blur="applyFormat($event, 've.KapitalzahlungPension')"
                  @focus="removeFormat"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12" md="3">
              <b-form-group
                label="Rentenausfall p.a." label-align="right"
                label-for="Rentenausfall"
              >
                <!-- <b-form-input v-facade="'########'" :formatter="formatNumber" class="text-right" v-model="ve.Rentenausfall"
                  @blur="setPensionKapital"
                /> -->
                <b-form-input v-facade="'########'" class="text-right" v-model="ve.Rentenausfall"
                  @blur="setPensionKapital"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12" md="3">
              <b-form-group
                label="Kapitalwert Scheidungseinleitung" label-align="right"
                label-for="KapzahwertScheid"
              >
                <b-form-input v-facade="'########'" :formatter="formatNumber" class="text-right" v-model="ve.KapitalzahlungScheidung"
                  @blur="applyFormat($event, 've.KapitalzahlungScheidung')"
                  @focus="removeFormat"
                />
              </b-form-group>
            </b-col>

          </b-row>

          <b-row v-show="ve.IstVE">
            <b-col v-show="vk.Art === 1" cols="12" md="3">
              <b-form-group
                label="Jährliche BVG-Rente" label-align="right"
                label-for="BVGRente"
              >
                <b-form-input v-facade="'########'" :formatter="formatNumber" class="text-right" v-model="ve.RenteJahr"
                  @blur="applyFormat($event, 've.RenteJahr')"
                  @focus="removeFormat"
                />
              </b-form-group>
            </b-col>

            <b-col v-show="vk.Art === 1" cols="12" md="3">
              <b-form-group
                label="Ehelicher Anteil BVG-Rente" label-align="right"
                label-for="BVGRenteEhe"
              >
                <b-form-input v-facade="'########'" :formatter="formatNumber" class="text-right" v-model="ve.RenteJahrEhe"
                  @blur="applyFormat($event, 've.RenteJahrEhe')"
                  @focus="removeFormat"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row v-show="ve.IstVE">
            <b-col cols="12" md="3">
              <b-form-group
                label="IV-Rente jährlich, ungekürzt" label-align="right"
                label-for="IVRenteJahr"
              >
                <b-form-input v-facade="'########'" :formatter="formatNumber" class="text-right" v-model="ve.IVRente"
                  @blur="applyFormat($event, 've.IVRente')"
                  @focus="removeFormat"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12" md="3">
              <b-form-group
                label="Allfällige Kürzung aus Überentschädigung" label-align="right"
                label-for="IVRenteJahr"
              >
                <b-form-input v-facade="'########'" :formatter="formatNumber" class="text-right" v-model="ve.IVRenteKuerzung"
                  @blur="applyFormat($event, 've.IVRenteKuerzung')"
                  @focus="removeFormat"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="speichernVE"
          >
            Speichern
          </b-button>

        </app-collapse-item>
      </app-collapse>

      <app-collapse v-show="(ve.VEID !== null && ve.IstVE && vk.Art === 1)" type="shadow" class="mb-1">
        <app-collapse-item title="Einmaleinlagen">
          <b-table
            striped
            hover
            responsive
            class="position-relative"
            :items="ee"
            primary-key="EinlageID"
            selected-variant="primary"
            :fields="fieldsEinlagen"
          >
          <template v-slot:cell(Datum)="row">
            <flat-pickr
                  id="eeDatum"
                  v-model="row.item.Datum"
                  :config="config"
                  class="form-control"
                />
            </template>
            <template v-slot:cell(BetragEigen)="row">
              <b-form-input v-facade="'########'" :formatter="formatNumber" v-model="row.item.BetragEigen" class="text-right"
                @blur="applyFormatEE($event, row, 'BetragEigen')"
                @focus="removeFormat"
              />
            </template>
            <template v-slot:cell(BetragEigenAZ)="row">
              <b-form-input v-facade="'########'" :formatter="formatNumber" v-model="row.item.BetragEigenAZ" class="text-right"
                @blur="applyFormatEE($event, row, 'BetragEigenAZ')"
                @focus="removeFormat"
              />
            </template>
            <template v-slot:cell(BetragErrungen)="row">
              <b-form-input v-facade="'########'" :formatter="formatNumber" v-model="row.item.BetragErrungen" class="text-right"
                @blur="applyFormatEE($event, row, 'BetragErrungen')"
                @focus="removeFormat"
              />
            </template>
            <template v-slot:cell(Beschrieb)="row">
              <b-form-textarea
                  v-model="row.item.Beschrieb"
                  rows="2"
                  max-rows="4"
                />
            </template>
            <template v-slot:cell(HandleBeleg)="row">
              <b-button
                v-show="row.item.EinlageID > 0 && (row.item.BetragEigen !== '0' || row.item.DosBelegID !== null)"
                variant="primary"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                @click="handleBeleg(belegTyp.Einlage, row.item)"
              >
                Beleg
              </b-button>
            </template>
            <template v-slot:cell(DeleteEinlage)="row">
              <b-button
                :disabled="row.item.EinlageID <= 0"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                v-b-tooltip.hover.top="'Einlage löschen?'"
                size="sm"
                variant="outline-primary"
                class="btn-icon"
                @click="deleteEinlage(row.item)"
              >
                <feather-icon icon="XCircleIcon" />
              </b-button>
            </template>

          </b-table>

          <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="speichernEinlagen"
          >
            Speichern
          </b-button>

          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            @click="addEinlage"
          >
            <feather-icon
              icon="PlusCircleIcon"
              class="mr-50"
            />
            <span class="align-middle">Weitere Einlage</span>
          </b-button>

        </app-collapse-item>
      </app-collapse>

      <app-collapse v-show="ve.VEID !== null" type="shadow" class="mb-1">
        <app-collapse-item title="Bar- und/oder Kapitalauszahlungen">
          <b-table
            striped
            hover
            responsive
            class="position-relative"
            :items="ba"
            primary-key="AuszahlungID"
            selected-variant="primary"
            :fields="fieldsAuszahlungen"
          >
            <template v-slot:cell(Datum)="row">
              <flat-pickr
                id="eeDatum"
                v-model="row.item.Datum"
                :config="config"
                class="form-control"
              />
            </template>
            <template v-slot:cell(Betrag)="row">
              <b-form-input v-facade="'########'" :formatter="formatNumber" v-model="row.item.Betrag" class="text-right"
                @blur="applyFormatBA($event, row, 'Betrag')"
                @focus="removeFormat"
              />
            </template>
            <template v-slot:cell(Rentenausfall)="row">
              <!-- <b-form-input v-facade="'########'" :formatter="formatNumber" v-model="row.item.Rentenausfall" class="text-right"
                @blur="setBarKapitalwert(row.item)"
              /> -->
              <b-form-input v-facade="'########'" v-model="row.item.Rentenausfall" class="text-right"
                @blur="setBarKapitalwert(row.item)"
              />
            </template>
            <template v-slot:cell(Zweck)="row">
              <b-form-textarea
                  v-model="row.item.Zweck"
                  rows="2"
                  max-rows="4"
                />
            </template>
            <template v-slot:cell(KapitalScheidung)="row">
              <b-form-input v-facade="'########'" :formatter="formatNumber" v-model="row.item.KapitalScheidung" class="text-right"
                @blur="applyFormatBA($event, row, 'KapitalScheidung')"
                @focus="removeFormat"
              />
            </template>
            <template v-slot:cell(DeleteAuszahlung)="row">
              <b-button
                :disabled="row.item.AuszahlungID <= 0"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                v-b-tooltip.hover.top="'Auszahlung löschen?'"
                size="sm"
                variant="outline-primary"
                class="btn-icon"
                @click="deleteAuszahlung(row.item)"
              >
                <feather-icon icon="XCircleIcon" />
              </b-button>
            </template>

          </b-table>

          <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="speichernAuszahlungen"
          >
            Speichern
          </b-button>

          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            @click="addAuszahlung"
          >
            <feather-icon
              icon="PlusCircleIcon"
              class="mr-50"
            />
            <span class="align-middle">Weitere Auszahlung</span>
          </b-button>

        </app-collapse-item>
      </app-collapse>

      <app-collapse v-show="(ve.VEID !== null && vk.Art === 1)" type="shadow" class="mb-1">
        <app-collapse-item title="Vorsorgegelder ausserhalb BVG">
          <b-row>
            <b-col cols="12" md="3">
              <b-form-group
                label="Vorsorgegelder ausserhalb BVG vorhanden?"
                label-for="VorsorgeAusserhalb"
              >
                <b-form-checkbox
                  v-model="ve.HatNichtBVG"
                  class="custom-control-primary"
                  name="check-button"
                  switch
                />
              </b-form-group>
            </b-col>

            <b-col v-show="ve.HatNichtBVG" cols="12" md="6">
                  <b-form-group
                    label="Beschreibung (ausländische Vorsorge, Vorruhestandskonto, Adresse, etc.)"
                    label-for="beschriebVorsorgeAusserhalb"
                  >
                    <b-form-textarea
                      v-model="ve.VorsorgeBeschrieb"
                      rows="2"
                      max-rows="4"
                    />
                  </b-form-group>
                </b-col>

            <b-col v-show="ve.HatNichtBVG" cols="12" md="3">
              <b-form-group
                label="Betrag" label-align="right"
                label-for="VorsorgeAusserhalbBetrag"
              >
                <b-form-input v-facade="'########'" :formatter="formatNumber" class="text-right" v-model="ve.VorsorgeBetrag"
                  @blur="applyFormat($event, 've.VorsorgeBetrag')"
                  @focus="removeFormat"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="speichernVE"
          >
            Speichern
          </b-button>
        </app-collapse-item>
      </app-collapse>

    </b-form>

    <b-modal
      :id="modalIDBeleg"
      title="Beleg"
      cancel-title="Abbrechen"
      ok-title="Speichern"
      cancel-variant="outline-secondary"
      size="lg"
      centered
      no-close-on-backdrop
      @ok="speichernBeleg"
    >
      <div v-if="!ve">
      </div>
      <div v-else>
        <b-row>
            <b-col cols="12" md="9 mt-1">
              <b-form-file
                v-model="file"
                accept=".pdf"
                :placeholder="be === null || be.DateiName === null ? 'Antwortbeleg auswählen oder hier ablegen...' : be.DateiName"
                drop-placeholder="Datei hier ablegen..."
              />
              <small
                v-show="file !== null && file.size > maxFileSize"
                class="text-danger"
              >{{ $g.browserLocale === 'fr' ? 'La grandeur du ficher ne doit pas depasser 500 KB!' : 'Die Datei darf nicht grösser als 500 KB sein!' }}</small>
            </b-col>

            <b-col cols="12" md="3 mt-1 text-right">
              <b-button
                variant="primary"
                :disabled="be.DosBelegID === null"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                @click="downloadBeleg"
              >
                Herunterladen
              </b-button>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12" md="9 mt-1">
              <b-form-group
                label="Bemerkung"
                label-for="bemBeleg"
              >
                <b-form-textarea
                  v-model="be.Bemerkung"
                  rows="2"
                  max-rows="4"
                />
              </b-form-group>
            </b-col>

          </b-row>

        </div>
    </b-modal>

  </div>
</template>

<script>

import Vue from 'vue'

import {
  BButton, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BFormFile, BFormTextarea, BTable, BOverlay, VBTooltip, ModalPlugin,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import VueSlider from 'vue-slider-component'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import { German } from 'flatpickr/dist/l10n/de'
import { French } from 'flatpickr/dist/l10n/fr'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import vorsorge from '@/service/vorsorge'
import brief from '@/service/korrespondenz'
import beleg from '@/service/beleg'
import bwt from '@/service/bwtafel' // Beispiel um die Barwerttaflen zu benutzen um die Rente zu kapitalisieren...
import wordEditor from '../../compo/WordEditor.vue'

Vue.use(ModalPlugin)

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BForm,
    BFormInput,
    BFormCheckbox,
    BFormFile,
    BFormTextarea,
    BOverlay,
    VueSlider,
    flatPickr,
    vSelect,
    wordEditor,
    AppCollapse,
    AppCollapseItem,
    BTable,
  },
  props: {
    istMutter: Number,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    const transTyp = [
      { value: 'B', label: this.$g.user.Sprache === 'fr' ? 'Prélèvement' : 'Vorbezug' },
      { value: 'R', label: this.$g.user.Sprache === 'fr' ? 'Remboursement' : 'Rückzahlung' },
      { value: 'V', label: this.$g.user.Sprache === 'fr' ? 'Vente' : 'Verkauf' },
      { value: 'E', label: this.$g.user.Sprache === 'fr' ? 'Désaffectation' : 'Entwidmung' },
    ]

    const wEFEigentum = [
      // { value: 0, label: this.$g.user.Sprache === 'fr' ? 'Copropriété' : 'Miteigentum' }, // TODO: Implement later..
      { value: 1, label: this.$root.VNameFrau },
      { value: 2, label: this.$root.VNameMann },
    ]

    const ves = []

    return {
      vEID: 0,
      isEditing: false,
      transTyp,
      wEFEigentum,
      ves,
      modalID: this.istMutter === 1 ? 'modalMutter' : 'modalVater',
      modalIDBeleg: (this.istMutter === 1 ? 'modalMutter' : 'modalVater') + 'Beleg',
      vk: null,
      ve: null,
      vi: null,
      b: null,
      be: null,
      ee: null,
      ba: null,
      wo: null,
      wt: null,
      isCalc: false,
      istBVGIVVorPension: null,
      IstCalcAL95: false,
      docIn: null,
      blobOut: null,
      file: null,
      maxFileSize: 5000000,
      modalHeight: window.innerHeight - 300,
      config: {
        wrap: true, // set wrap to true only when using 'input-group'
        altFormat: 'j M, Y',
        altInput: true,
        allowInput: true,
        // dateFormat: 'd.m.Y',
        locale: this.$g.user.Sprache === 'fr' ? French : German,
      },
      fieldsEinlagen: [
        { key: 'Datum', label: 'Datum', sortable: true },
        { key: 'BetragEigen', label: 'Betrag Eigengut', thClass: 'text-right', tdClass: 'text-right' }, // eslint-disable-line
        { key: 'BetragEigenAZ', label: 'Eigengut aufgezinst', thClass: 'text-right', tdClass: 'text-right' }, // eslint-disable-line
        { key: 'BetragErrungen', label: 'Betrag Errungen.', thClass: 'text-right', tdClass: 'text-right' }, // eslint-disable-line
        { key: 'Beschrieb', label: 'Beschreibung', thStyle: { width: "25%" }, }, // eslint-disable-line
        { key: 'HandleBeleg', label: '' }, // eslint-disable-line
        { key: 'DeleteEinlage', label: '' }, // eslint-disable-line
      ],
      fieldsAuszahlungen: [
        { key: 'Datum', label: 'Datum', sortable: true },
        { key: 'Betrag', label: 'Betrag', thClass: 'text-right', tdClass: 'text-right' }, // eslint-disable-line
        { key: 'Rentenausfall', label: 'Rentenausfall p.a.', thClass: 'text-right', tdClass: 'text-right' }, // eslint-disable-line
        { key: 'Zweck', label: 'Zweck/Grund' }, // eslint-disable-line
        { key: 'KapitalScheidung', label: 'Kapitalwert Scheidung', thClass: 'text-right', tdClass: 'text-right' }, // eslint-disable-line
        { key: 'DeleteAuszahlung', label: '' }, // eslint-disable-line
      ],
      fieldsWEFTrans: [
        { key: 'Typ', label: 'Transaktion' }, // eslint-disable-line
        { key: 'DatumTbl', label: 'Datum' },
        { key: 'Betrag', label: 'Betrag', thClass: 'text-right', tdClass: 'text-right' }, // eslint-disable-line
      ],
      belegTyp: {
        VEAntwort: 1,
        Einlage: 2,
        WEFObjekt: 3,
        WEFTrans: 4,
      },
      currBelegTyp: 1,
      currRowBeleg: null,
      currRowWEFTrans: null,
    }
  },
  async created() {
    const rk = await vorsorge.getVorsorgeKonfig(this.$root.currDosID)
    if (rk.data.length > 0) {
      this.vk = rk.data[0]
      this.wEFEigentum[0].Value = this.vk.FrauID
      this.wEFEigentum[1].Value = this.vk.MannID
    }

    const rs = await vorsorge.getVorsorgeeinrichtungSel(this.$root.currDosID, this.istMutter === 1 ? this.vk.FrauID : this.vk.MannID)
    this.ves = rs.data !== null ? rs.data : []

    if (rs.data !== null && rs.data.length > 0) this.vEID = rs.data[0].value === null ? 0 : rs.data[0].value

    const ri = await vorsorge.getVorsorgeInfo(this.$root.currDosID)
    if (ri.data.length > 0) this.vi = ri.data[0]

    this.initialize()
  },
  methods: {
    formatNumber(value) {
      if (!this.isEditing && typeof value === 'string') {
        value = value.replace(/\B(?=(\d{3})+(?!\d))/g, "'") // eslint-disable-line
        return value
      }
      return false
    },
    applyFormat(event, value) {
      event.target.value = (event.target.value || '0').replace(/^0+(?!$)/, '').replace(/\B(?=(\d{3})+(?!\d))/g, "'") // eslint-disable-line
      const parts = value.split('.')
      if (parts.length === 2) this[parts[0]][parts[1]] = event.target.value // eslint-disable-line
      else this[parts[0]] = event.target.value // eslint-disable-line
      this.isEditing = false
    },
    applyFormatWT(event, row, value) { // eslint-disable-line
      event.target.value = (event.target.value || '0').replace(/^0+(?!$)/, '').replace(/\B(?=(\d{3})+(?!\d))/g, "'") // eslint-disable-line
      let ix = 0
      for (; ix < this.wt.length; ix++) if (this.wt[ix].WEFTransID === row.item.WEFTransID) break // eslint-disable-line
      this.wt[ix][value] = event.target.value
      this.isEditing = false
    },
    applyFormatEE(event, row, value) { // eslint-disable-line
      event.target.value = (event.target.value || '0').replace(/^0+(?!$)/, '').replace(/\B(?=(\d{3})+(?!\d))/g, "'") // eslint-disable-line
      let ix = 0
      for (; ix < this.ee.length; ix++) if (this.ee[ix].EinlageID === row.item.EinlageID) break // eslint-disable-line
      this.ee[ix][value] = event.target.value
      this.isEditing = false
    },
    applyFormatBA(event, row, value) { // eslint-disable-line
      event.target.value = (event.target.value || '0').replace(/^0+(?!$)/, '').replace(/\B(?=(\d{3})+(?!\d))/g, "'") // eslint-disable-line
      let ix = 0
      for (; ix < this.ba.length; ix++) if (this.ba[ix].AuszahlungID === row.item.AuszahlungID) break // eslint-disable-line
      this.ba[ix][value] = event.target.value
      this.isEditing = false
    },
    removeFormat(event) {
      this.isEditing = true
      event.target.value = event.target.value.replace(/[^\d]/g, '') // eslint-disable-line
      if (event.target.value === '0') event.target.value = '' // eslint-disable-line
    },
    formatBetrag(val) {
      let v = val.replaceAll(/[’']/g, '')
      v = Intl.NumberFormat('de-CH').format(v)
      return v
    },
    async changeVE() {
      this.initialize()
    },
    async initialize() {
      const rv = await vorsorge.getVorsorgeeinrichtung(this.vEID, this.$root.currDosID, this.istMutter === 1 ? this.vk.FrauID : this.vk.MannID)
      if (rv.data.length > 0) {
        this.ve = rv.data[0]
        this.istBVGIVVorPension = !this.ve.IstPension && this.ve.IVRenteProz > 0
      }

      const rb = await brief.getDossierBrief(this.ve.DosBriefID === null ? 0 : this.ve.DosBriefID, this.$g.user.Sprache)
      if (rb != null && rb.data !== null) this.b = rb.data[0]

      const r = await beleg.getDossierBeleg(this.ve.DosBelegID === null ? -1 : this.ve.DosBelegID, this.$root.currDosID, this.ve.DosBelegID === null ? 13 : this.ve.DosBelegID, this.$g.user.BenSprache)
      if (r.data.length > 0) this.be = r.data[0]

      const re = await vorsorge.getVEEinlagen(this.ve.VEID)
      if (re.data.length > 0) this.ee = re.data

      const ra = await vorsorge.getVEAuszahlungen(this.ve.VEID)
      if (ra.data.length > 0) this.ba = ra.data

      const rwo = await vorsorge.getWEFObjekt(this.$root.currDosID)
      if (rwo.data.length > 0) {
        if (rwo.data[0].Eigentum > 0) rwo.data[0].Eigentum = rwo.data[0].Eigentum === rwo.data[0].FrauID ? 1 : 2
        this.wo = rwo.data[0]
      }

      if (this.ve.VEID !== null) {
        const rwt = await vorsorge.getWEFTrans(this.ve.VEID)
        if (rwt.data.length > 0) {
          for (let i = 0; i < rwt.data.length; i += 1) rwt.data[i]._showDetails = false // eslint-disable-line
          this.wt = rwt.data
        }
      }
    },
    async speichernVE() {
      if (this.ve.VEID === null) this.ve.VEID = 0
      this.ve.HatVE = 1
      const r = await vorsorge.putVorsorgeeinrichtung(this.ve)
      if (this.ve.VEID === 0) {
        if (r !== null && r.data !== null) { // Speichern ohne VE, nichts oder Vorsorge ausserhalb BVG...
          this.ve.VEID = r.data[0]
        } else {
          this.ve.VEID = null
          this.ve.HatVE = 0
        }
      }

      this.$root.showToast('Speichern', 'Vorsorgeeinrichtungsdaten erfolgreich gespeichert!')
    },
    async addVE() {
      const rv = await vorsorge.getVorsorgeeinrichtung(0, 3510, 0) // Gets empty ve to initialize the form...
      if (rv.data.length > 0) {
        rv.data[0].DosID = this.$root.currDosID
        rv.data[0].EltID = this.istMutter === 1 ? this.vk.FrauID : this.vk.MannID
        this.ve = rv.data[0]
        this.ve.HatVE = true
        this.$refs.collVE.updateVisible(true) // see ..\@core\components\app-collapse\AppCollapseItem.vue

        const np = JSON.parse(JSON.stringify(this.ves)).slice(0, 1)
        np[0].value = 0
        np[0].label = '<Neue Einrichtung>'
        this.ves.unshift(np[0])
        this.vEID = 0
      }
    },
    addEinlage() {
      const np = JSON.parse(JSON.stringify(this.ee)).slice(0, 1)
      np[0].EinlageID = -(this.ee.length + 1)
      np[0].Datum = null
      np[0].BetragEigen = 0
      np[0].BetragErrungen = 0
      np[0].Beschrieb = ''
      np[0].DosBelegID = null
      this.ee.unshift(np[0])
    },
    addAuszahlung() {
      const np = JSON.parse(JSON.stringify(this.ba)).slice(0, 1)
      np[0].AuszahlungID = -(this.ba.length + 1)
      np[0].Datum = null
      np[0].Betrag = 0
      np[0].Zweck = ''
      np[0].KapitalScheidung = 0
      this.ba.unshift(np[0])
    },
    addWEFTrans() {
      const np = JSON.parse(JSON.stringify(this.wt)).slice(0, 1)
      np[0].WEFTransID = -(this.wt.length + 1)
      np[0].VEID = this.ve.VEID
      np[0].TransTyp = 'R'
      np[0].Typ = 'Rückzahlung'
      np[0].Datum = null
      np[0].DatumTbl = ''
      np[0].Betrag = 0
      np[0].LeistungTrans = 0
      np[0].IstVerpfaendet = 0
      np[0].IstVerkauf = 0
      np[0].BetragEigen = 0
      np[0].BetragErrungen = 0
      np[0].Freizuegigkeit = ''
      np[0].DosBelegID = null
      np[0]._showDetails = true // eslint-disable-line
      this.wt.push(np[0])
    },
    async speichernEinlagen() {
      for (let i = 0; i < this.ee.length; i += 1) {
        this.ee[i].VEID = this.ve.VEID
        this.ee[i].EinlageID = this.ee[i].EinlageID === 1 ? 0 : this.ee[i].EinlageID
      }

      await vorsorge.putVEEinlagen(this.ee, this.$root.currDosID)

      const re = await vorsorge.getVEEinlagen(this.ve.VEID)
      if (re.data.length > 0) this.ee = re.data

      this.$root.showToast('Beilangen gespeichert', 'Einmaleinlagen wurde erfolgreich gespeichert!')
    },
    async deleteEinlage(item) {
      if (await this.$root.msgBoxConfirm('Soll die Einmaleinlage wirklich gelöscht werden?')) {
        await vorsorge.getVEEinlagenDelete(item.EinlageID, this.$root.currDosID, item.DosBelegID)

        const re = await vorsorge.getVEEinlagen(this.ve.VEID)
        if (re.data.length > 0) this.ee = re.data

        this.$root.showToast('Einlage gelöscht', 'Einlage wurde gelöscht!')
      }
    },
    async speichernAuszahlungen() {
      for (let i = 0; i < this.ba.length; i += 1) {
        this.ba[i].VEID = this.ve.VEID
        this.ba[i].AuszahlungID = this.ba[i].AuszahlungID === 1002 ? 0 : this.ba[i].AuszahlungID
      }

      await vorsorge.putVEAuszahlung(this.ba)

      const re = await vorsorge.getVEAuszahlungen(this.ve.VEID)
      if (re.data.length > 0) this.ba = re.data

      this.$root.showToast('Auszahlungen gespeichert', 'Auszahlungen wurde erfolgreich gespeichert!')
    },
    async deleteAuszahlung(item) {
      if (await this.$root.msgBoxConfirm('Soll die Auszahlung wirklich gelöscht werden?')) {
        await vorsorge.getVEAuszahlungDelete(item.AuszahlungID)

        const re = await vorsorge.getVEAuszahlungen(this.ve.VEID)
        if (re.data.length > 0) this.ba = re.data

        this.$root.showToast('Auszahlung gelöscht', 'Auszahlung wurde gelöscht!')
      }
    },
    async speichernWEFObjekt() {
      if (this.wo.DosID === 0) this.wo.DosID = this.$root.currDosID

      if (this.wo.Eigentum > 0) this.wo.Eigentum = this.wo.Eigentum === 1 ? this.wo.FrauID : this.wo.MannID

      await vorsorge.putWEFObjekt(this.wo)

      if (this.wt !== null && this.wt.length !== null) {
        for (let i = 0; i < this.wt.length; i += 1) this.wt[i].VEID = this.ve.VEID
        await vorsorge.putWEFTrans(this.wt, this.$root.currDosID)
      }

      const rwt = await vorsorge.getWEFTrans(this.ve.VEID)
      if (rwt.data.length > 0) {
        for (let i = 0; i < rwt.data.length; i += 1) rwt.data[i]._showDetails = false // eslint-disable-line
        this.wt = rwt.data
      }

      this.$root.showToast('WEF-Liegenschaft / Transaktionen gespeichert', 'WEF-Liegenschaft und Transaktionen wurde erfolgreich gespeicher!')
    },
    async showWEFTrans(items) {
      if (items.length > 0) this.currRow = items[0]
      this.currRow._showDetails = !this.currRow._showDetails  // eslint-disable-line
    },
    async deleteWEFTrans(item) {
      if (await this.$root.msgBoxConfirm('Soll die Transaktion wirklich gelöscht werden?')) {
        await vorsorge.getWEFTransDelete(item.WEFTransID, this.$root.currDosID, item.DosBelegID)

        const rwt = await vorsorge.getWEFTrans(this.ve.VEID)
        if (rwt.data.length > 0) {
          for (let i = 0; i < rwt.data.length; i += 1) rwt.data[i]._showDetails = false // eslint-disable-line
          this.wt = rwt.data
        }

        this.$root.showToast('Transaktion gelöscht', 'Transaktion wurde erfolgreich gelöscht!')
      }
    },
    async createBrief() {
      if (this.ve.DosBriefID === null || await this.$root.msgBoxConfirm('Soll der bestehende Brief überschrieben werden?')) {
        this.isCalc = true
        this.b.DosBriefID = this.ve.DosBriefID
        this.b.DosID = this.$root.currDosID
        this.b.VorlageID = 6
        const r = await brief.putDossierBrief(this.b)
        if (r !== null) {
          this.b.DosBriefID = r.data[0]
          this.ve.DosBriefID = r.data[0]
        }

        await vorsorge.putVorsorgeeinrichtung(this.ve) // DosBriefID speichern...

        await vorsorge.createBriefVorsorge(this.ve.DosBriefID, this.$g.user.Sprache)

        this.$root.showToast('Brief an Vorsorgeeinrichtung', 'Brief an Vorsorgeeinrichtung erfolgreich erstellt!')
        this.isCalc = false
      }
    },
    async setAustritt() {
      this.isCalc = true
      const el95 = (this.ve.EintrittLeistung95 ?? '0').toString().replaceAll(/[’']/g, '')
      const al95 = (this.ve.AustrittLeistung95 ?? '0').toString().replaceAll(/[’']/g, '')
      const r = await vorsorge.getAustrittsleistungenVorHeirat95(this.vk.Heirat, this.vk.Scheidungseinleitung, this.ve.Eintritt95, el95, this.ve.Austritt95, al95)
      if (r != null) {
        this.ve.LeistungVorehe = Intl.NumberFormat('de-CH').format(r.data.AustrittsleistungHeirat)
        this.ve.LeistungVoreheAufgezinst = Intl.NumberFormat('de-CH').format(r.data.AufgezinstScheidung)
      }
      this.isCalc = false
    },
    async setKapitalwert(item) {
      item.KapitalwertScheidung = await this.getBarwert(item.Rentenausfall, item.KapitalwertScheidung) // eslint-disable-line
    },
    async setPensionKapital() {
      this.ve.KapitalzahlungScheidung = await this.getBarwert(this.ve.Rentenausfall, this.ve.KapitalzahlungScheidung)
    },
    async setBarKapitalwert(item) {
      item.KapitalScheidung = await this.getBarwert(item.Rentenausfall, item.KapitalScheidung) // eslint-disable-line
    },
    async getBarwert(rentenausfall, kapitalwert) {
      let rente = (rentenausfall ?? '0').toString().replaceAll(/[’']/g, '')
      let kwert = (kapitalwert ?? '0').toString().replaceAll(/[’']/g, '')
      rente = rente === null || rente === '' ? 0 : parseInt(rente, 10)
      kwert = kwert === null || kwert === '' ? 0 : parseInt(kwert, 10)
      if (this.istMutter === 1) return Intl.NumberFormat('de-CH').format((kwert === null || kwert === 0) ? Math.round(bwt.bwfFrau[this.vi.FrauAlterScheidung]['zs3.5'] * rente) : kwert)
      return Intl.NumberFormat('de-CH').format((kwert === null || kwert === 0) ? Math.round(bwt.bwfMann[this.vi.MannAlterScheidung]['zs3.5'] * rente) : kwert)
    },
    async setTransEntwidmet(item) {
      if (item.TransTyp === 'E') item.Datum = this.wo.Entwidmet // eslint-disable-line
    },
    async show() { // shows brief...
      const r = await brief.getDossierBriefSFDT(this.ve.DosBriefID)
      if (r != null) this.docIn = r.data
      this.$refs.we.show()
    },
    async getDocBlob(blob, doc) { // Speichern aufgerufen von word editor componente...
      this.blobOut = blob
      this.docIn = doc
      await brief.postBriefSFDT(this.blobOut, this.ve.DosBriefID)
      this.$root.showToast('Brief speichern', 'Brief wurde erfolgreich gespeichert!')
    },
    async handleBeleg(belegTyp, item) { // set current belegType for save, get beleg and show it in the modal...
      this.currBelegTyp = belegTyp
      switch (belegTyp) {
        case this.belegTyp.VEAntwort: {
          const ra = await beleg.getDossierBeleg(this.ve.DosBelegID === null ? -1 : this.ve.DosBelegID, this.$root.currDosID, this.ve.DosBelegID === null ? 13 : this.ve.DosBelegID, this.$g.user.BenSprache)
          if (ra.data.length > 0) {
            this.be = ra.data[0]
            this.be.Bemerkung = this.be.Bemerkung === null ? `Antwort von ${this.ve.Name}` : this.be.Bemerkung
          }
          break
        } case this.belegTyp.Einlage: {
          this.currRowBeleg = item
          const re = await beleg.getDossierBeleg(item.DosBelegID === null ? -1 : item.DosBelegID, this.$root.currDosID, item.DosBelegID === null ? 17 : item.DosBelegID, this.$g.user.BenSprache)
          if (re.data.length > 0) {
            this.be = re.data[0]
            this.be.Bemerkung = this.be.Bemerkung === null ? `Beleg Einmaleinlage aus Eigengut ${this.istMutter ? this.$root.VNameFrau : this.$root.VNameMann}` : this.be.Bemerkung
          }
          break
        } case this.belegTyp.WEFObjekt: {
          const rwo = await beleg.getDossierBeleg(this.wo.DosBelegID === null ? -1 : this.wo.DosBelegID, this.$root.currDosID, this.wo.DosBelegID === null ? 25 : this.wo.DosBelegID, this.$g.user.BenSprache)
          if (rwo.data.length > 0) {
            this.be = rwo.data[0]
            this.be.Bemerkung = this.be.Bemerkung === null ? `Antwort von ${this.ve.Name}` : this.be.Bemerkung
          }
          break
        } case this.belegTyp.WEFTrans: {
          this.currRowWEFTrans = item
          const rwt = await beleg.getDossierBeleg(item.DosBelegID === null ? -1 : item.DosBelegID, this.$root.currDosID, item.DosBelegID === null ? 31 : item.DosBelegID, this.$g.user.BenSprache)
          if (rwt.data.length > 0) {
            this.be = rwt.data[0]
            this.be.Bemerkung = this.be.Bemerkung === null ? (item.TransTyp === 'R' ? 'Rückzahlung WEFV' : item.TransTyp === 'V' ? 'Verkauf WEFV' : 'Entwidmung WEFV') : this.be.Bemerkung // eslint-disable-line
          }
          break
        } default:
      }

      this.$bvModal.show(this.modalIDBeleg)
      await this.$nextTick() // Trick to get $refs not to be undefined...
    },
    async speichernBeleg(bvModalEvent) { // save the beleg and set the DosBelegID...
      bvModalEvent.preventDefault()

      this.be.Von = this.istMutter === 1 ? 3 : 4
      this.be.IstGericht = 1
      const r = await beleg.putDossierBeleg(this.be)

      switch (this.currBelegTyp) {
        case this.belegTyp.VEAntwort: {
          if (r != null && this.ve.DosBelegID === null) {
            this.ve.DosBelegID = r.data[0]
            this.be.DosBelegID = r.data[0]
            await vorsorge.putVorsorgeeinrichtung(this.ve)
          }
          break
        } case this.belegTyp.Einlage: {
          if (r != null && this.currRowBeleg.DosBelegID === null) {
            this.currRowBeleg.DosBelegID = r.data[0]
            this.be.DosBelegID = r.data[0]
            this.speichernEinlagen()
          }
          break
        } case this.belegTyp.WEFObjekt: {
          if (r != null && this.wo.DosBelegID === null) {
            this.wo.DosBelegID = r.data[0]
            this.be.DosBelegID = r.data[0]
            this.speichernWEFObjekt()
          }
          break
        } case this.belegTyp.WEFTrans: {
          if (r != null && this.currRowWEFTrans.DosBelegID === null) {
            this.currRowWEFTrans.DosBelegID = r.data[0]
            this.be.DosBelegID = r.data[0]
            this.speichernWEFObjekt()
          }
          break
        } default:
      }

      if (this.file !== null) await beleg.postBelegDatei(this.$root.currDosID, this.file, this.be.DosBelegID)
      this.$root.showToast('Beleg speichern', 'Beleg wurde erfolgreich gespeichert!')

      this.$nextTick(() => {
        this.$bvModal.hide(this.modalIDBeleg)
      })
    },
    async downloadBeleg() {
      const r = await beleg.getBelegDatei(this.$root.currDosID, this.be.DosBelegID, this.be.Mime)
      const fileURL = window.URL.createObjectURL(r.data)
      const fileLink = document.createElement('a')
      fileLink.href = fileURL
      fileLink.setAttribute('download', this.be.DateiName)
      document.body.appendChild(fileLink)
      fileLink.click()
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-slider.scss';
</style>
