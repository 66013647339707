<template>

  <b-card>

    <b-modal
      :id="modalID"
      title="Editor"
      cancel-title="Abbrechen"
      :ok-title="isSave ? 'Speichern' : 'Schliessen'"
      cancel-variant="outline-secondary"
      size="xl"
      no-close-on-backdrop
      @ok="handleOK"
    >
      <b-row>

        <b-col md="3 text-left mt-2">
          <b-button
            variant="primary"
            class="btn-icon mb-2"
            @click="print()"
          >
            <feather-icon
                icon="PrinterIcon"
                class="mr-50"
              />
              <span class="align-middle">Drucken</span>
            <feather-icon icon="" />
          </b-button>
        </b-col>

        <b-col md="3 text-right mt-2" v-show="isSave">
          <b-button
            variant="primary"
            @click="downloadWord()"
          >
            Word herunterladen
          </b-button>
        </b-col>

        <b-col md="3 text-right mt-2" v-show="isSave">
          <b-button
            :disabled="file === null"
            variant="primary"
            @click="uploadWord()"
          >
            Word hochladen
          </b-button>
        </b-col>

        <b-col md="3 text-center mt-2" v-show="isSave">
          <div class="w-100 text-center mb-1">
            <b-form-file
              v-model="file"
              :state="Boolean(file)"
              placeholder="Datei wählen oder hier ablegen..."
              drop-placeholder="Datei hier ablegen..."
            />
          </div>
        </b-col>

      </b-row>
      <div id="app">
        <ejs-documenteditorcontainer
          ref="docEdiCon"
          :height="modalHeight"
          :service-url="serviceUrl"
          :enable-toolbar="true"
          :enable-print="true"
          :toolbar-items="toolbarItems"
          :show-properties-pane="false"
        />
      </div>
    </b-modal>

  </b-card>

</template>

<script>
import Vue from 'vue'
import {
  BCard, ModalPlugin, BButton, BFormFile, BRow, BCol,
} from 'bootstrap-vue'
import { DocumentEditorContainerPlugin, Toolbar } from '@syncfusion/ej2-vue-documenteditor'
// import { DocumentEditorContainerComponent, Toolbar } from '@syncfusion/ej2-vue-documenteditor'
import { L10n, setCulture } from '@syncfusion/ej2-base'

import db from '@/service/korrespondenz'

import localeDeutsch from '../assets/syncfusion-locales/de.json'
import localeFrancais from '../assets/syncfusion-locales/fr.json'

Vue.use(ModalPlugin)
Vue.use(DocumentEditorContainerPlugin)
// Vue.use(DocumentEditorContainerComponent)

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BFormFile,
  },
  props: {
    dateiName: String,
    docIn: String,
    modalHeight: Number,
    isSave: {
      default: true,
      type: Boolean,
    },
    modalID: { // Only necessary when the modal component is used more than one time in the same form or in more than one tab-item, as in Vorsorge...
      default: 'modal',
      type: String,
    },
  },
  data() {
    return {
      file: null,
      doc: null,
      serviceUrl: 'https://ej2services.syncfusion.com/production/web-services/api/documenteditor/',
      // serviceUrl: null, // some functionalities are not available like formated copy/past - when needed, server must be built and installed => .net core or mvc or phyton...
      toolbarItems: [
        // 'Print', 'PageSetup', 'Separator', 'Undo', 'Redo', 'Separator', 'Image', 'Table', 'Hyperlink', 'Separator', 'Header', 'Footer', 'Break', 'Separator', 'Find',
        'PageSetup', 'Separator', 'Undo', 'Redo', 'Separator', 'Image', 'Table', 'Hyperlink', 'Separator', 'Header', 'Footer', 'Break', 'Separator', 'Find',
      ],
    }
  },
  provide: {
    DocumentEditorContainer: [Toolbar],
  },
  async created() {
    L10n.load(this.$g.user.Sprache === 'fr' ? localeFrancais : localeDeutsch)
    setCulture(this.$g.user.Sprache === 'fr' ? 'fr' : 'de')
  },
  methods: {
    print() {
      this.$refs.docEdiCon.ej2Instances.documentEditor.print()
    },
    async show() {
      this.$bvModal.show(this.modalID)
      await this.$nextTick() // Trick to get $refs not to be undefined...
      this.$refs.docEdiCon.ej2Instances.documentEditor.open(this.docIn)
    },
    async handleOK(bvModalEvent) {
      bvModalEvent.preventDefault()
      this.$refs.docEdiCon.ej2Instances.documentEditor.saveAsBlob('Docx').then(doc => {
        const docBlob = new FormData()
        docBlob.append('data', doc)
        const docSFDT = this.$refs.docEdiCon.ej2Instances.documentEditor.serialize()
        this.$emit('getDocBlob', docBlob, docSFDT)
      })
      await this.$nextTick(() => {
        this.$bvModal.hide(this.modalID)
      })
    },
    async downloadWord() {
      this.$refs.docEdiCon.ej2Instances.documentEditor.saveAsBlob('Docx').then(doc => {
        const fileURL = window.URL.createObjectURL(doc)
        const fileLink = document.createElement('a')
        fileLink.href = fileURL
        fileLink.setAttribute('download', this.dateiName + '.docx')
        document.body.appendChild(fileLink)
        fileLink.click()
      })
    },
    async uploadWord() {
      if (this.file === null) return
      this.doc = await db.getConvertDoc2SFDT(this.file) // eslint-disable-line
      this.$refs.docEdiCon.ej2Instances.documentEditor.open(this.doc.data)
    },
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/pages/page-misc.scss';
  @import '../../node_modules/@syncfusion/ej2-base/styles/bootstrap5.css';
  @import '../../node_modules/@syncfusion/ej2-buttons/styles/bootstrap5.css';
  @import '../../node_modules/@syncfusion/ej2-inputs/styles/bootstrap5.css';
  @import '../../node_modules/@syncfusion/ej2-popups/styles/bootstrap5.css';
  @import '../../node_modules/@syncfusion/ej2-lists/styles/bootstrap5.css';
  @import '../../node_modules/@syncfusion/ej2-navigations/styles/bootstrap5.css';
  @import '../../node_modules/@syncfusion/ej2-splitbuttons/styles/bootstrap5.css';
  @import '../../node_modules/@syncfusion/ej2-dropdowns/styles/bootstrap5.css';
  @import '../../node_modules/@syncfusion/ej2-documenteditor/styles/bootstrap5.css';
</style>
