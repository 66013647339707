/* eslint-disable */
export default {
    bwfFrau: [
      {
      "zs0": 88.77,
        "zs0.25": 79.48,
        "zs0.5": 71.5,
        "zs0.75": 64.61,
        "zs1": 58.65,
        "zs1.25": 53.46,
        "zs1.5": 48.95,
        "zs1.75": 44.99,
        "zs2": 41.52,
        "zs2.25": 38.45,
        "zs2.5": 35.74,
        "zs2.75": 33.33,
        "zs3": 31.18,
        "zs3.25": 29.27,
        "zs3.5": 27.54,
        "zs3.75": 25.99,
        "zs4": 24.59,
        "zs4.25": 23.32,
        "zs4.5": 22.17,
        "zs4.75": 21.12,
        "zs5": 20.16
        },
        {
        "zs0": 87.96,
        "zs0.25": 78.85,
        "zs0.5": 71.01,
        "zs0.75": 64.23,
        "zs1": 58.36,
        "zs1.25": 53.25,
        "zs1.5": 48.78,
        "zs1.75": 44.87,
        "zs2": 41.43,
        "zs2.25": 38.39,
        "zs2.5": 35.7,
        "zs2.75": 33.31,
        "zs3": 31.17,
        "zs3.25": 29.26,
        "zs3.5": 27.55,
        "zs3.75": 26.01,
        "zs4": 24.61,
        "zs4.25": 23.34,
        "zs4.5": 22.19,
        "zs4.75": 21.14,
        "zs5": 20.18
        },
        {
        "zs0": 86.98,
        "zs0.25": 78.07,
        "zs0.5": 70.38,
        "zs0.75": 63.73,
        "zs1": 57.95,
        "zs1.25": 52.92,
        "zs1.5": 48.52,
        "zs1.75": 44.65,
        "zs2": 41.25,
        "zs2.25": 38.25,
        "zs2.5": 35.59,
        "zs2.75": 33.22,
        "zs3": 31.1,
        "zs3.25": 29.21,
        "zs3.5": 27.5,
        "zs3.75": 25.97,
        "zs4": 24.58,
        "zs4.25": 23.32,
        "zs4.5": 22.17,
        "zs4.75": 21.13,
        "zs5": 20.17
        },
        {
        "zs0": 85.99,
        "zs0.25": 77.27,
        "zs0.5": 69.73,
        "zs0.75": 63.21,
        "zs1": 57.53,
        "zs1.25": 52.58,
        "zs1.5": 48.24,
        "zs1.75": 44.43,
        "zs2": 41.07,
        "zs2.25": 38.1,
        "zs2.5": 35.47,
        "zs2.75": 33.12,
        "zs3": 31.02,
        "zs3.25": 29.14,
        "zs3.5": 27.45,
        "zs3.75": 25.92,
        "zs4": 24.54,
        "zs4.25": 23.29,
        "zs4.5": 22.15,
        "zs4.75": 21.11,
        "zs5": 20.15
        },
        {
        "zs0": 85,
        "zs0.25": 76.46,
        "zs0.5": 69.08,
        "zs0.75": 62.68,
        "zs1": 57.1,
        "zs1.25": 52.23,
        "zs1.5": 47.96,
        "zs1.75": 44.2,
        "zs2": 40.89,
        "zs2.25": 37.95,
        "zs2.5": 35.34,
        "zs2.75": 33.02,
        "zs3": 30.94,
        "zs3.25": 29.07,
        "zs3.5": 27.39,
        "zs3.75": 25.88,
        "zs4": 24.5,
        "zs4.25": 23.26,
        "zs4.5": 22.12,
        "zs4.75": 21.09,
        "zs5": 20.14
        },
        {
        "zs0": 84,
        "zs0.25": 75.66,
        "zs0.5": 68.43,
        "zs0.75": 62.15,
        "zs1": 56.67,
        "zs1.25": 51.88,
        "zs1.5": 47.67,
        "zs1.75": 43.97,
        "zs2": 40.7,
        "zs2.25": 37.79,
        "zs2.5": 35.21,
        "zs2.75": 32.91,
        "zs3": 30.85,
        "zs3.25": 29,
        "zs3.5": 27.33,
        "zs3.75": 25.83,
        "zs4": 24.46,
        "zs4.25": 23.22,
        "zs4.5": 22.09,
        "zs4.75": 21.06,
        "zs5": 20.12
        },
        {
        "zs0": 83,
        "zs0.25": 74.85,
        "zs0.5": 67.77,
        "zs0.75": 61.61,
        "zs1": 56.23,
        "zs1.25": 51.52,
        "zs1.5": 47.38,
        "zs1.75": 43.73,
        "zs2": 40.5,
        "zs2.25": 37.63,
        "zs2.5": 35.08,
        "zs2.75": 32.8,
        "zs3": 30.76,
        "zs3.25": 28.93,
        "zs3.5": 27.27,
        "zs3.75": 25.78,
        "zs4": 24.42,
        "zs4.25": 23.19,
        "zs4.5": 22.07,
        "zs4.75": 21.04,
        "zs5": 20.1
        },
        {
        "zs0": 82.01,
        "zs0.25": 74.04,
        "zs0.5": 67.11,
        "zs0.75": 61.07,
        "zs1": 55.79,
        "zs1.25": 51.16,
        "zs1.5": 47.09,
        "zs1.75": 43.49,
        "zs2": 40.3,
        "zs2.25": 37.47,
        "zs2.5": 34.95,
        "zs2.75": 32.69,
        "zs3": 30.67,
        "zs3.25": 28.85,
        "zs3.5": 27.21,
        "zs3.75": 25.72,
        "zs4": 24.38,
        "zs4.25": 23.15,
        "zs4.5": 22.03,
        "zs4.75": 21.01,
        "zs5": 20.07
        },
        {
        "zs0": 81.01,
        "zs0.25": 73.22,
        "zs0.5": 66.44,
        "zs0.75": 60.53,
        "zs1": 55.35,
        "zs1.25": 50.8,
        "zs1.5": 46.78,
        "zs1.75": 43.24,
        "zs2": 40.1,
        "zs2.25": 37.3,
        "zs2.5": 34.81,
        "zs2.75": 32.58,
        "zs3": 30.57,
        "zs3.25": 28.77,
        "zs3.5": 27.14,
        "zs3.75": 25.67,
        "zs4": 24.33,
        "zs4.25": 23.11,
        "zs4.5": 22,
        "zs4.75": 20.98,
        "zs5": 20.05
        },
        {
        "zs0": 80.01,
        "zs0.25": 72.41,
        "zs0.5": 65.78,
        "zs0.75": 59.98,
        "zs1": 54.9,
        "zs1.25": 50.43,
        "zs1.5": 46.48,
        "zs1.75": 42.99,
        "zs2": 39.89,
        "zs2.25": 37.13,
        "zs2.5": 34.66,
        "zs2.75": 32.46,
        "zs3": 30.47,
        "zs3.25": 28.69,
        "zs3.5": 27.07,
        "zs3.75": 25.61,
        "zs4": 24.28,
        "zs4.25": 23.07,
        "zs4.5": 21.97,
        "zs4.75": 20.96,
        "zs5": 20.03
        },
        {
        "zs0": 79.01,
        "zs0.25": 71.59,
        "zs0.5": 65.11,
        "zs0.75": 59.43,
        "zs1": 54.44,
        "zs1.25": 50.05,
        "zs1.5": 46.17,
        "zs1.75": 42.73,
        "zs2": 39.68,
        "zs2.25": 36.95,
        "zs2.5": 34.52,
        "zs2.75": 32.34,
        "zs3": 30.37,
        "zs3.25": 28.6,
        "zs3.5": 27,
        "zs3.75": 25.55,
        "zs4": 24.23,
        "zs4.25": 23.03,
        "zs4.5": 21.93,
        "zs4.75": 20.93,
        "zs5": 20
        },
        {
        "zs0": 78.01,
        "zs0.25": 70.77,
        "zs0.5": 64.43,
        "zs0.75": 58.87,
        "zs1": 53.98,
        "zs1.25": 49.67,
        "zs1.5": 45.86,
        "zs1.75": 42.47,
        "zs2": 39.46,
        "zs2.25": 36.77,
        "zs2.5": 34.37,
        "zs2.75": 32.21,
        "zs3": 30.27,
        "zs3.25": 28.52,
        "zs3.5": 26.93,
        "zs3.75": 25.49,
        "zs4": 24.18,
        "zs4.25": 22.99,
        "zs4.5": 21.9,
        "zs4.75": 20.9,
        "zs5": 19.98
        },
        {
        "zs0": 77.02,
        "zs0.25": 69.95,
        "zs0.5": 63.75,
        "zs0.75": 58.31,
        "zs1": 53.52,
        "zs1.25": 49.29,
        "zs1.5": 45.54,
        "zs1.75": 42.21,
        "zs2": 39.24,
        "zs2.25": 36.59,
        "zs2.5": 34.22,
        "zs2.75": 32.08,
        "zs3": 30.16,
        "zs3.25": 28.43,
        "zs3.5": 26.86,
        "zs3.75": 25.43,
        "zs4": 24.13,
        "zs4.25": 22.94,
        "zs4.5": 21.86,
        "zs4.75": 20.86,
        "zs5": 19.95
        },
        {
        "zs0": 76.02,
        "zs0.25": 69.12,
        "zs0.5": 63.07,
        "zs0.75": 57.75,
        "zs1": 53.05,
        "zs1.25": 48.9,
        "zs1.5": 45.22,
        "zs1.75": 41.94,
        "zs2": 39.02,
        "zs2.25": 36.4,
        "zs2.5": 34.06,
        "zs2.75": 31.95,
        "zs3": 30.05,
        "zs3.25": 28.33,
        "zs3.5": 26.78,
        "zs3.75": 25.36,
        "zs4": 24.07,
        "zs4.25": 22.9,
        "zs4.5": 21.82,
        "zs4.75": 20.83,
        "zs5": 19.92
        },
        {
        "zs0": 75.02,
        "zs0.25": 68.3,
        "zs0.5": 62.39,
        "zs0.75": 57.18,
        "zs1": 52.58,
        "zs1.25": 48.51,
        "zs1.5": 44.89,
        "zs1.75": 41.66,
        "zs2": 38.79,
        "zs2.25": 36.21,
        "zs2.5": 33.9,
        "zs2.75": 31.82,
        "zs3": 29.94,
        "zs3.25": 28.24,
        "zs3.5": 26.7,
        "zs3.75": 25.29,
        "zs4": 24.02,
        "zs4.25": 22.85,
        "zs4.5": 21.78,
        "zs4.75": 20.79,
        "zs5": 19.89
        },
        {
        "zs0": 74.03,
        "zs0.25": 67.47,
        "zs0.5": 61.7,
        "zs0.75": 56.61,
        "zs1": 52.1,
        "zs1.25": 48.11,
        "zs1.5": 44.55,
        "zs1.75": 41.39,
        "zs2": 38.55,
        "zs2.25": 36.02,
        "zs2.5": 33.73,
        "zs2.75": 31.68,
        "zs3": 29.82,
        "zs3.25": 28.14,
        "zs3.5": 26.61,
        "zs3.75": 25.22,
        "zs4": 23.96,
        "zs4.25": 22.8,
        "zs4.5": 21.73,
        "zs4.75": 20.76,
        "zs5": 19.86
        },
        {
        "zs0": 73.03,
        "zs0.25": 66.64,
        "zs0.5": 61.01,
        "zs0.75": 56.03,
        "zs1": 51.62,
        "zs1.25": 47.7,
        "zs1.5": 44.22,
        "zs1.75": 41.1,
        "zs2": 38.32,
        "zs2.25": 35.82,
        "zs2.5": 33.57,
        "zs2.75": 31.54,
        "zs3": 29.7,
        "zs3.25": 28.04,
        "zs3.5": 26.53,
        "zs3.75": 25.15,
        "zs4": 23.89,
        "zs4.25": 22.74,
        "zs4.5": 21.69,
        "zs4.75": 20.72,
        "zs5": 19.82
        },
        {
        "zs0": 72.03,
        "zs0.25": 65.81,
        "zs0.5": 60.31,
        "zs0.75": 55.45,
        "zs1": 51.13,
        "zs1.25": 47.3,
        "zs1.5": 43.87,
        "zs1.75": 40.81,
        "zs2": 38.07,
        "zs2.25": 35.61,
        "zs2.5": 33.39,
        "zs2.75": 31.39,
        "zs3": 29.58,
        "zs3.25": 27.93,
        "zs3.5": 26.44,
        "zs3.75": 25.08,
        "zs4": 23.83,
        "zs4.25": 22.69,
        "zs4.5": 21.64,
        "zs4.75": 20.68,
        "zs5": 19.79
        },
        {
        "zs0": 71.04,
        "zs0.25": 64.98,
        "zs0.5": 59.62,
        "zs0.75": 54.86,
        "zs1": 50.64,
        "zs1.25": 46.88,
        "zs1.5": 43.53,
        "zs1.75": 40.52,
        "zs2": 37.83,
        "zs2.25": 35.4,
        "zs2.5": 33.22,
        "zs2.75": 31.24,
        "zs3": 29.45,
        "zs3.25": 27.83,
        "zs3.5": 26.35,
        "zs3.75": 25,
        "zs4": 23.76,
        "zs4.25": 22.63,
        "zs4.5": 21.59,
        "zs4.75": 20.64,
        "zs5": 19.75
        },
        {
        "zs0": 70.05,
        "zs0.25": 64.15,
        "zs0.5": 58.92,
        "zs0.75": 54.28,
        "zs1": 50.15,
        "zs1.25": 46.47,
        "zs1.5": 43.18,
        "zs1.75": 40.23,
        "zs2": 37.58,
        "zs2.25": 35.19,
        "zs2.5": 33.04,
        "zs2.75": 31.09,
        "zs3": 29.32,
        "zs3.25": 27.72,
        "zs3.5": 26.25,
        "zs3.75": 24.92,
        "zs4": 23.69,
        "zs4.25": 22.57,
        "zs4.5": 21.54,
        "zs4.75": 20.59,
        "zs5": 19.72
        },
        {
        "zs0": 69.06,
        "zs0.25": 63.31,
        "zs0.5": 58.22,
        "zs0.75": 53.69,
        "zs1": 49.65,
        "zs1.25": 46.05,
        "zs1.5": 42.82,
        "zs1.75": 39.93,
        "zs2": 37.32,
        "zs2.25": 34.98,
        "zs2.5": 32.86,
        "zs2.75": 30.93,
        "zs3": 29.19,
        "zs3.25": 27.6,
        "zs3.5": 26.16,
        "zs3.75": 24.84,
        "zs4": 23.62,
        "zs4.25": 22.51,
        "zs4.5": 21.49,
        "zs4.75": 20.55,
        "zs5": 19.68
        },
        {
        "zs0": 68.07,
        "zs0.25": 62.48,
        "zs0.5": 57.52,
        "zs0.75": 53.1,
        "zs1": 49.15,
        "zs1.25": 45.62,
        "zs1.5": 42.46,
        "zs1.75": 39.62,
        "zs2": 37.07,
        "zs2.25": 34.76,
        "zs2.5": 32.67,
        "zs2.75": 30.77,
        "zs3": 29.05,
        "zs3.25": 27.49,
        "zs3.5": 26.06,
        "zs3.75": 24.75,
        "zs4": 23.55,
        "zs4.25": 22.45,
        "zs4.5": 21.44,
        "zs4.75": 20.5,
        "zs5": 19.64
        },
        {
        "zs0": 67.08,
        "zs0.25": 61.64,
        "zs0.5": 56.81,
        "zs0.75": 52.5,
        "zs1": 48.65,
        "zs1.25": 45.2,
        "zs1.5": 42.1,
        "zs1.75": 39.31,
        "zs2": 36.8,
        "zs2.25": 34.53,
        "zs2.5": 32.48,
        "zs2.75": 30.61,
        "zs3": 28.91,
        "zs3.25": 27.37,
        "zs3.5": 25.96,
        "zs3.75": 24.66,
        "zs4": 23.48,
        "zs4.25": 22.38,
        "zs4.5": 21.38,
        "zs4.75": 20.45,
        "zs5": 19.6
        },
        {
        "zs0": 66.09,
        "zs0.25": 60.81,
        "zs0.5": 56.1,
        "zs0.75": 51.9,
        "zs1": 48.13,
        "zs1.25": 44.76,
        "zs1.5": 41.73,
        "zs1.75": 39,
        "zs2": 36.53,
        "zs2.25": 34.3,
        "zs2.5": 32.28,
        "zs2.75": 30.44,
        "zs3": 28.77,
        "zs3.25": 27.24,
        "zs3.5": 25.85,
        "zs3.75": 24.57,
        "zs4": 23.4,
        "zs4.25": 22.32,
        "zs4.5": 21.32,
        "zs4.75": 20.4,
        "zs5": 19.55
        },
        {
        "zs0": 65.1,
        "zs0.25": 59.96,
        "zs0.5": 55.38,
        "zs0.75": 51.29,
        "zs1": 47.62,
        "zs1.25": 44.32,
        "zs1.5": 41.35,
        "zs1.75": 38.68,
        "zs2": 36.26,
        "zs2.25": 34.07,
        "zs2.5": 32.08,
        "zs2.75": 30.27,
        "zs3": 28.62,
        "zs3.25": 27.12,
        "zs3.5": 25.74,
        "zs3.75": 24.47,
        "zs4": 23.31,
        "zs4.25": 22.24,
        "zs4.5": 21.26,
        "zs4.75": 20.35,
        "zs5": 19.51
        },
        {
        "zs0": 64.11,
        "zs0.25": 59.12,
        "zs0.5": 54.67,
        "zs0.75": 50.67,
        "zs1": 47.09,
        "zs1.25": 43.87,
        "zs1.5": 40.97,
        "zs1.75": 38.35,
        "zs2": 35.98,
        "zs2.25": 33.83,
        "zs2.5": 31.87,
        "zs2.75": 30.09,
        "zs3": 28.47,
        "zs3.25": 26.98,
        "zs3.5": 25.62,
        "zs3.75": 24.37,
        "zs4": 23.23,
        "zs4.25": 22.17,
        "zs4.5": 21.19,
        "zs4.75": 20.29,
        "zs5": 19.46
        },
        {
        "zs0": 63.11,
        "zs0.25": 58.28,
        "zs0.5": 53.94,
        "zs0.75": 50.06,
        "zs1": 46.56,
        "zs1.25": 43.42,
        "zs1.5": 40.58,
        "zs1.75": 38.02,
        "zs2": 35.69,
        "zs2.25": 33.58,
        "zs2.5": 31.66,
        "zs2.75": 29.91,
        "zs3": 28.31,
        "zs3.25": 26.85,
        "zs3.5": 25.5,
        "zs3.75": 24.27,
        "zs4": 23.14,
        "zs4.25": 22.09,
        "zs4.5": 21.13,
        "zs4.75": 20.23,
        "zs5": 19.4
        },
        {
        "zs0": 62.12,
        "zs0.25": 57.43,
        "zs0.5": 53.22,
        "zs0.75": 49.43,
        "zs1": 46.03,
        "zs1.25": 42.96,
        "zs1.5": 40.19,
        "zs1.75": 37.68,
        "zs2": 35.4,
        "zs2.25": 33.33,
        "zs2.5": 31.44,
        "zs2.75": 29.72,
        "zs3": 28.14,
        "zs3.25": 26.7,
        "zs3.5": 25.38,
        "zs3.75": 24.16,
        "zs4": 23.04,
        "zs4.25": 22.01,
        "zs4.5": 21.06,
        "zs4.75": 20.17,
        "zs5": 19.35
        },
        {
        "zs0": 61.13,
        "zs0.25": 56.58,
        "zs0.5": 52.49,
        "zs0.75": 48.81,
        "zs1": 45.49,
        "zs1.25": 42.5,
        "zs1.5": 39.79,
        "zs1.75": 37.33,
        "zs2": 35.1,
        "zs2.25": 33.07,
        "zs2.5": 31.22,
        "zs2.75": 29.52,
        "zs3": 27.98,
        "zs3.25": 26.56,
        "zs3.5": 25.25,
        "zs3.75": 24.05,
        "zs4": 22.95,
        "zs4.25": 21.93,
        "zs4.5": 20.98,
        "zs4.75": 20.11,
        "zs5": 19.29
        },
        {
        "zs0": 60.14,
        "zs0.25": 55.72,
        "zs0.5": 51.75,
        "zs0.75": 48.18,
        "zs1": 44.95,
        "zs1.25": 42.03,
        "zs1.5": 39.38,
        "zs1.75": 36.98,
        "zs2": 34.79,
        "zs2.25": 32.8,
        "zs2.5": 30.99,
        "zs2.75": 29.33,
        "zs3": 27.8,
        "zs3.25": 26.41,
        "zs3.5": 25.12,
        "zs3.75": 23.94,
        "zs4": 22.85,
        "zs4.25": 21.84,
        "zs4.5": 20.9,
        "zs4.75": 20.04,
        "zs5": 19.23
        },
        {
        "zs0": 59.15,
        "zs0.25": 54.87,
        "zs0.5": 51.02,
        "zs0.75": 47.54,
        "zs1": 44.4,
        "zs1.25": 41.55,
        "zs1.5": 38.97,
        "zs1.75": 36.62,
        "zs2": 34.48,
        "zs2.25": 32.53,
        "zs2.5": 30.75,
        "zs2.75": 29.12,
        "zs3": 27.63,
        "zs3.25": 26.25,
        "zs3.5": 24.99,
        "zs3.75": 23.82,
        "zs4": 22.74,
        "zs4.25": 21.75,
        "zs4.5": 20.82,
        "zs4.75": 19.97,
        "zs5": 19.17
        },
        {
        "zs0": 58.15,
        "zs0.25": 54.01,
        "zs0.5": 50.28,
        "zs0.75": 46.9,
        "zs1": 43.84,
        "zs1.25": 41.07,
        "zs1.5": 38.55,
        "zs1.75": 36.26,
        "zs2": 34.17,
        "zs2.25": 32.26,
        "zs2.5": 30.51,
        "zs2.75": 28.91,
        "zs3": 27.44,
        "zs3.25": 26.09,
        "zs3.5": 24.84,
        "zs3.75": 23.7,
        "zs4": 22.63,
        "zs4.25": 21.65,
        "zs4.5": 20.74,
        "zs4.75": 19.89,
        "zs5": 19.1
        },
        {
        "zs0": 57.16,
        "zs0.25": 53.15,
        "zs0.5": 49.53,
        "zs0.75": 46.25,
        "zs1": 43.28,
        "zs1.25": 40.58,
        "zs1.5": 38.13,
        "zs1.75": 35.89,
        "zs2": 33.85,
        "zs2.25": 31.98,
        "zs2.5": 30.27,
        "zs2.75": 28.7,
        "zs3": 27.25,
        "zs3.25": 25.92,
        "zs3.5": 24.7,
        "zs3.75": 23.57,
        "zs4": 22.52,
        "zs4.25": 21.55,
        "zs4.5": 20.65,
        "zs4.75": 19.81,
        "zs5": 19.04
        },
        {
        "zs0": 56.17,
        "zs0.25": 52.29,
        "zs0.5": 48.78,
        "zs0.75": 45.6,
        "zs1": 42.72,
        "zs1.25": 40.09,
        "zs1.5": 37.7,
        "zs1.75": 35.51,
        "zs2": 33.52,
        "zs2.25": 31.69,
        "zs2.5": 30.01,
        "zs2.75": 28.47,
        "zs3": 27.06,
        "zs3.25": 25.75,
        "zs3.5": 24.55,
        "zs3.75": 23.43,
        "zs4": 22.4,
        "zs4.25": 21.45,
        "zs4.5": 20.56,
        "zs4.75": 19.73,
        "zs5": 18.96
        },
        {
        "zs0": 55.18,
        "zs0.25": 51.43,
        "zs0.5": 48.03,
        "zs0.75": 44.95,
        "zs1": 42.14,
        "zs1.25": 39.59,
        "zs1.5": 37.26,
        "zs1.75": 35.13,
        "zs2": 33.18,
        "zs2.25": 31.4,
        "zs2.5": 29.76,
        "zs2.75": 28.25,
        "zs3": 26.86,
        "zs3.25": 25.58,
        "zs3.5": 24.39,
        "zs3.75": 23.3,
        "zs4": 22.28,
        "zs4.25": 21.34,
        "zs4.5": 20.46,
        "zs4.75": 19.65,
        "zs5": 18.89
        },
        {
        "zs0": 54.19,
        "zs0.25": 50.57,
        "zs0.5": 47.28,
        "zs0.75": 44.29,
        "zs1": 41.57,
        "zs1.25": 39.09,
        "zs1.5": 36.82,
        "zs1.75": 34.74,
        "zs2": 32.84,
        "zs2.25": 31.1,
        "zs2.5": 29.49,
        "zs2.75": 28.02,
        "zs3": 26.65,
        "zs3.25": 25.4,
        "zs3.5": 24.23,
        "zs3.75": 23.16,
        "zs4": 22.16,
        "zs4.25": 21.23,
        "zs4.5": 20.36,
        "zs4.75": 19.56,
        "zs5": 18.81
        },
        {
        "zs0": 53.2,
        "zs0.25": 49.71,
        "zs0.5": 46.52,
        "zs0.75": 43.63,
        "zs1": 40.99,
        "zs1.25": 38.58,
        "zs1.5": 36.37,
        "zs1.75": 34.35,
        "zs2": 32.49,
        "zs2.25": 30.79,
        "zs2.5": 29.22,
        "zs2.75": 27.78,
        "zs3": 26.44,
        "zs3.25": 25.21,
        "zs3.5": 24.07,
        "zs3.75": 23.01,
        "zs4": 22.03,
        "zs4.25": 21.11,
        "zs4.5": 20.26,
        "zs4.75": 19.47,
        "zs5": 18.73
        },
        {
        "zs0": 52.22,
        "zs0.25": 48.84,
        "zs0.5": 45.77,
        "zs0.75": 42.96,
        "zs1": 40.4,
        "zs1.25": 38.06,
        "zs1.5": 35.92,
        "zs1.75": 33.95,
        "zs2": 32.14,
        "zs2.25": 30.48,
        "zs2.5": 28.95,
        "zs2.75": 27.53,
        "zs3": 26.23,
        "zs3.25": 25.02,
        "zs3.5": 23.9,
        "zs3.75": 22.86,
        "zs4": 21.89,
        "zs4.25": 20.99,
        "zs4.5": 20.15,
        "zs4.75": 19.37,
        "zs5": 18.64
        },
        {
        "zs0": 51.23,
        "zs0.25": 47.97,
        "zs0.5": 45,
        "zs0.75": 42.29,
        "zs1": 39.81,
        "zs1.25": 37.54,
        "zs1.5": 35.46,
        "zs1.75": 33.54,
        "zs2": 31.78,
        "zs2.25": 30.16,
        "zs2.5": 28.66,
        "zs2.75": 27.28,
        "zs3": 26,
        "zs3.25": 24.82,
        "zs3.5": 23.72,
        "zs3.75": 22.7,
        "zs4": 21.75,
        "zs4.25": 20.87,
        "zs4.5": 20.04,
        "zs4.75": 19.27,
        "zs5": 18.55
        },
        {
        "zs0": 50.24,
        "zs0.25": 47.1,
        "zs0.5": 44.24,
        "zs0.75": 41.62,
        "zs1": 39.21,
        "zs1.25": 37.01,
        "zs1.5": 34.99,
        "zs1.75": 33.13,
        "zs2": 31.42,
        "zs2.25": 29.83,
        "zs2.5": 28.37,
        "zs2.75": 27.02,
        "zs3": 25.77,
        "zs3.25": 24.61,
        "zs3.5": 23.54,
        "zs3.75": 22.54,
        "zs4": 21.6,
        "zs4.25": 20.73,
        "zs4.5": 19.92,
        "zs4.75": 19.17,
        "zs5": 18.46
        },
        {
        "zs0": 49.26,
        "zs0.25": 46.24,
        "zs0.5": 43.47,
        "zs0.75": 40.94,
        "zs1": 38.61,
        "zs1.25": 36.48,
        "zs1.5": 34.52,
        "zs1.75": 32.71,
        "zs2": 31.04,
        "zs2.25": 29.5,
        "zs2.5": 28.08,
        "zs2.75": 26.76,
        "zs3": 25.54,
        "zs3.25": 24.4,
        "zs3.5": 23.35,
        "zs3.75": 22.37,
        "zs4": 21.45,
        "zs4.25": 20.6,
        "zs4.5": 19.8,
        "zs4.75": 19.06,
        "zs5": 18.36
        },
        {
        "zs0": 48.28,
        "zs0.25": 45.37,
        "zs0.5": 42.7,
        "zs0.75": 40.25,
        "zs1": 38.01,
        "zs1.25": 35.94,
        "zs1.5": 34.04,
        "zs1.75": 32.28,
        "zs2": 30.66,
        "zs2.25": 29.17,
        "zs2.5": 27.78,
        "zs2.75": 26.49,
        "zs3": 25.3,
        "zs3.25": 24.19,
        "zs3.5": 23.16,
        "zs3.75": 22.19,
        "zs4": 21.3,
        "zs4.25": 20.46,
        "zs4.5": 19.68,
        "zs4.75": 18.94,
        "zs5": 18.25
        },
        {
        "zs0": 47.3,
        "zs0.25": 44.5,
        "zs0.5": 41.93,
        "zs0.75": 39.57,
        "zs1": 37.4,
        "zs1.25": 35.4,
        "zs1.5": 33.56,
        "zs1.75": 31.85,
        "zs2": 30.28,
        "zs2.25": 28.82,
        "zs2.5": 27.47,
        "zs2.75": 26.22,
        "zs3": 25.05,
        "zs3.25": 23.97,
        "zs3.5": 22.96,
        "zs3.75": 22.02,
        "zs4": 21.14,
        "zs4.25": 20.31,
        "zs4.5": 19.54,
        "zs4.75": 18.82,
        "zs5": 18.15
        },
        {
        "zs0": 46.32,
        "zs0.25": 43.63,
        "zs0.5": 41.15,
        "zs0.75": 38.88,
        "zs1": 36.78,
        "zs1.25": 34.85,
        "zs1.5": 33.07,
        "zs1.75": 31.41,
        "zs2": 29.89,
        "zs2.25": 28.47,
        "zs2.5": 27.16,
        "zs2.75": 25.93,
        "zs3": 24.8,
        "zs3.25": 23.74,
        "zs3.5": 22.75,
        "zs3.75": 21.83,
        "zs4": 20.97,
        "zs4.25": 20.16,
        "zs4.5": 19.41,
        "zs4.75": 18.7,
        "zs5": 18.04
        },
        {
        "zs0": 45.34,
        "zs0.25": 42.75,
        "zs0.5": 40.38,
        "zs0.75": 38.19,
        "zs1": 36.16,
        "zs1.25": 34.3,
        "zs1.5": 32.57,
        "zs1.75": 30.97,
        "zs2": 29.49,
        "zs2.25": 28.11,
        "zs2.5": 26.83,
        "zs2.75": 25.65,
        "zs3": 24.54,
        "zs3.25": 23.51,
        "zs3.5": 22.54,
        "zs3.75": 21.64,
        "zs4": 20.8,
        "zs4.25": 20.01,
        "zs4.5": 19.27,
        "zs4.75": 18.57,
        "zs5": 17.92
        },
        {
        "zs0": 44.36,
        "zs0.25": 41.88,
        "zs0.5": 39.6,
        "zs0.75": 37.49,
        "zs1": 35.54,
        "zs1.25": 33.74,
        "zs1.5": 32.07,
        "zs1.75": 30.52,
        "zs2": 29.08,
        "zs2.25": 27.75,
        "zs2.5": 26.51,
        "zs2.75": 25.35,
        "zs3": 24.27,
        "zs3.25": 23.26,
        "zs3.5": 22.32,
        "zs3.75": 21.44,
        "zs4": 20.62,
        "zs4.25": 19.85,
        "zs4.5": 19.12,
        "zs4.75": 18.44,
        "zs5": 17.8
        },
        {
        "zs0": 43.39,
        "zs0.25": 41.01,
        "zs0.5": 38.82,
        "zs0.75": 36.79,
        "zs1": 34.91,
        "zs1.25": 33.17,
        "zs1.5": 31.56,
        "zs1.75": 30.06,
        "zs2": 28.67,
        "zs2.25": 27.38,
        "zs2.5": 26.17,
        "zs2.75": 25.05,
        "zs3": 24,
        "zs3.25": 23.02,
        "zs3.5": 22.1,
        "zs3.75": 21.24,
        "zs4": 20.44,
        "zs4.25": 19.68,
        "zs4.5": 18.97,
        "zs4.75": 18.3,
        "zs5": 17.67
        },
        {
        "zs0": 42.42,
        "zs0.25": 40.14,
        "zs0.5": 38.04,
        "zs0.75": 36.09,
        "zs1": 34.28,
        "zs1.25": 32.6,
        "zs1.5": 31.05,
        "zs1.75": 29.6,
        "zs2": 28.25,
        "zs2.25": 27,
        "zs2.5": 25.83,
        "zs2.75": 24.74,
        "zs3": 23.72,
        "zs3.25": 22.76,
        "zs3.5": 21.87,
        "zs3.75": 21.03,
        "zs4": 20.25,
        "zs4.25": 19.51,
        "zs4.5": 18.81,
        "zs4.75": 18.16,
        "zs5": 17.54
        },
        {
        "zs0": 41.45,
        "zs0.25": 39.27,
        "zs0.5": 37.25,
        "zs0.75": 35.38,
        "zs1": 33.64,
        "zs1.25": 32.03,
        "zs1.5": 30.53,
        "zs1.75": 29.13,
        "zs2": 27.83,
        "zs2.25": 26.62,
        "zs2.5": 25.48,
        "zs2.75": 24.42,
        "zs3": 23.43,
        "zs3.25": 22.5,
        "zs3.5": 21.63,
        "zs3.75": 20.82,
        "zs4": 20.05,
        "zs4.25": 19.33,
        "zs4.5": 18.65,
        "zs4.75": 18.01,
        "zs5": 17.4
        },
        {
        "zs0": 40.48,
        "zs0.25": 38.4,
        "zs0.5": 36.46,
        "zs0.75": 34.67,
        "zs1": 33,
        "zs1.25": 31.45,
        "zs1.5": 30,
        "zs1.75": 28.66,
        "zs2": 27.4,
        "zs2.25": 26.22,
        "zs2.5": 25.13,
        "zs2.75": 24.1,
        "zs3": 23.14,
        "zs3.25": 22.24,
        "zs3.5": 21.39,
        "zs3.75": 20.59,
        "zs4": 19.85,
        "zs4.25": 19.14,
        "zs4.5": 18.48,
        "zs4.75": 17.85,
        "zs5": 17.26
        },
        {
        "zs0": 39.52,
        "zs0.25": 37.53,
        "zs0.5": 35.68,
        "zs0.75": 33.96,
        "zs1": 32.35,
        "zs1.25": 30.86,
        "zs1.5": 29.47,
        "zs1.75": 28.17,
        "zs2": 26.96,
        "zs2.25": 25.83,
        "zs2.5": 24.76,
        "zs2.75": 23.77,
        "zs3": 22.84,
        "zs3.25": 21.96,
        "zs3.5": 21.14,
        "zs3.75": 20.36,
        "zs4": 19.64,
        "zs4.25": 18.95,
        "zs4.5": 18.3,
        "zs4.75": 17.69,
        "zs5": 17.11
        },
        {
        "zs0": 38.56,
        "zs0.25": 36.66,
        "zs0.5": 34.89,
        "zs0.75": 33.24,
        "zs1": 31.7,
        "zs1.25": 30.27,
        "zs1.5": 28.93,
        "zs1.75": 27.68,
        "zs2": 26.51,
        "zs2.25": 25.42,
        "zs2.5": 24.39,
        "zs2.75": 23.43,
        "zs3": 22.53,
        "zs3.25": 21.68,
        "zs3.5": 20.88,
        "zs3.75": 20.13,
        "zs4": 19.42,
        "zs4.25": 18.75,
        "zs4.5": 18.12,
        "zs4.75": 17.52,
        "zs5": 16.96
        },
        {
        "zs0": 37.6,
        "zs0.25": 35.78,
        "zs0.5": 34.09,
        "zs0.75": 32.52,
        "zs1": 31.05,
        "zs1.25": 29.67,
        "zs1.5": 28.39,
        "zs1.75": 27.19,
        "zs2": 26.06,
        "zs2.25": 25.01,
        "zs2.5": 24.01,
        "zs2.75": 23.08,
        "zs3": 22.21,
        "zs3.25": 21.39,
        "zs3.5": 20.61,
        "zs3.75": 19.88,
        "zs4": 19.19,
        "zs4.25": 18.54,
        "zs4.5": 17.93,
        "zs4.75": 17.35,
        "zs5": 16.8
        },
        {
        "zs0": 36.64,
        "zs0.25": 34.91,
        "zs0.5": 33.3,
        "zs0.75": 31.79,
        "zs1": 30.39,
        "zs1.25": 29.07,
        "zs1.5": 27.84,
        "zs1.75": 26.68,
        "zs2": 25.6,
        "zs2.25": 24.58,
        "zs2.5": 23.63,
        "zs2.75": 22.73,
        "zs3": 21.89,
        "zs3.25": 21.09,
        "zs3.5": 20.34,
        "zs3.75": 19.63,
        "zs4": 18.96,
        "zs4.25": 18.33,
        "zs4.5": 17.73,
        "zs4.75": 17.17,
        "zs5": 16.63
        },
        {
        "zs0": 35.68,
        "zs0.25": 34.04,
        "zs0.5": 32.5,
        "zs0.75": 31.07,
        "zs1": 29.72,
        "zs1.25": 28.46,
        "zs1.5": 27.28,
        "zs1.75": 26.17,
        "zs2": 25.13,
        "zs2.25": 24.16,
        "zs2.5": 23.23,
        "zs2.75": 22.37,
        "zs3": 21.55,
        "zs3.25": 20.78,
        "zs3.5": 20.06,
        "zs3.75": 19.37,
        "zs4": 18.72,
        "zs4.25": 18.11,
        "zs4.5": 17.53,
        "zs4.75": 16.98,
        "zs5": 16.46
        },
        {
        "zs0": 34.73,
        "zs0.25": 33.17,
        "zs0.5": 31.71,
        "zs0.75": 30.34,
        "zs1": 29.05,
        "zs1.25": 27.85,
        "zs1.5": 26.72,
        "zs1.75": 25.66,
        "zs2": 24.66,
        "zs2.25": 23.72,
        "zs2.5": 22.83,
        "zs2.75": 22,
        "zs3": 21.21,
        "zs3.25": 20.47,
        "zs3.5": 19.77,
        "zs3.75": 19.1,
        "zs4": 18.47,
        "zs4.25": 17.88,
        "zs4.5": 17.32,
        "zs4.75": 16.78,
        "zs5": 16.27
        },
        {
        "zs0": 33.78,
        "zs0.25": 32.3,
        "zs0.5": 30.91,
        "zs0.75": 29.6,
        "zs1": 28.38,
        "zs1.25": 27.23,
        "zs1.5": 26.15,
        "zs1.75": 25.13,
        "zs2": 24.18,
        "zs2.25": 23.27,
        "zs2.5": 22.42,
        "zs2.75": 21.62,
        "zs3": 20.86,
        "zs3.25": 20.15,
        "zs3.5": 19.47,
        "zs3.75": 18.83,
        "zs4": 18.22,
        "zs4.25": 17.64,
        "zs4.5": 17.1,
        "zs4.75": 16.58,
        "zs5": 16.08
        },
        {
        "zs0": 32.83,
        "zs0.25": 31.43,
        "zs0.5": 30.11,
        "zs0.75": 28.87,
        "zs1": 27.7,
        "zs1.25": 26.61,
        "zs1.5": 25.57,
        "zs1.75": 24.6,
        "zs2": 23.69,
        "zs2.25": 22.82,
        "zs2.5": 22.01,
        "zs2.75": 21.23,
        "zs3": 20.5,
        "zs3.25": 19.81,
        "zs3.5": 19.16,
        "zs3.75": 18.54,
        "zs4": 17.95,
        "zs4.25": 17.4,
        "zs4.5": 16.87,
        "zs4.75": 16.36,
        "zs5": 15.89
        },
        {
        "zs0": 31.89,
        "zs0.25": 30.56,
        "zs0.5": 29.31,
        "zs0.75": 28.13,
        "zs1": 27.02,
        "zs1.25": 25.98,
        "zs1.5": 24.99,
        "zs1.75": 24.07,
        "zs2": 23.19,
        "zs2.25": 22.36,
        "zs2.5": 21.58,
        "zs2.75": 20.84,
        "zs3": 20.14,
        "zs3.25": 19.48,
        "zs3.5": 18.85,
        "zs3.75": 18.25,
        "zs4": 17.68,
        "zs4.25": 17.14,
        "zs4.5": 16.63,
        "zs4.75": 16.14,
        "zs5": 15.68
        },
        {
        "zs0": 30.95,
        "zs0.25": 29.69,
        "zs0.5": 28.5,
        "zs0.75": 27.39,
        "zs1": 26.34,
        "zs1.25": 25.34,
        "zs1.5": 24.41,
        "zs1.75": 23.52,
        "zs2": 22.69,
        "zs2.25": 21.9,
        "zs2.5": 21.15,
        "zs2.75": 20.44,
        "zs3": 19.77,
        "zs3.25": 19.13,
        "zs3.5": 18.52,
        "zs3.75": 17.95,
        "zs4": 17.4,
        "zs4.25": 16.88,
        "zs4.5": 16.39,
        "zs4.75": 15.92,
        "zs5": 15.47
        },
        {
        "zs0": 30.01,
        "zs0.25": 28.82,
        "zs0.5": 27.7,
        "zs0.75": 26.65,
        "zs1": 25.65,
        "zs1.25": 24.71,
        "zs1.5": 23.82,
        "zs1.75": 22.97,
        "zs2": 22.18,
        "zs2.25": 21.42,
        "zs2.5": 20.71,
        "zs2.75": 20.03,
        "zs3": 19.38,
        "zs3.25": 18.77,
        "zs3.5": 18.19,
        "zs3.75": 17.64,
        "zs4": 17.11,
        "zs4.25": 16.61,
        "zs4.5": 16.14,
        "zs4.75": 15.68,
        "zs5": 15.25
        },
        {
        "zs0": 29.08,
        "zs0.25": 27.96,
        "zs0.5": 26.9,
        "zs0.75": 25.9,
        "zs1": 24.96,
        "zs1.25": 24.07,
        "zs1.5": 23.22,
        "zs1.75": 22.42,
        "zs2": 21.66,
        "zs2.25": 20.94,
        "zs2.5": 20.26,
        "zs2.75": 19.61,
        "zs3": 18.99,
        "zs3.25": 18.41,
        "zs3.5": 17.85,
        "zs3.75": 17.32,
        "zs4": 16.81,
        "zs4.25": 16.33,
        "zs4.5": 15.87,
        "zs4.75": 15.44,
        "zs5": 15.02
        },
        {
        "zs0": 28.15,
        "zs0.25": 27.1,
        "zs0.5": 26.1,
        "zs0.75": 25.16,
        "zs1": 24.27,
        "zs1.25": 23.42,
        "zs1.5": 22.62,
        "zs1.75": 21.86,
        "zs2": 21.14,
        "zs2.25": 20.45,
        "zs2.5": 19.8,
        "zs2.75": 19.18,
        "zs3": 18.6,
        "zs3.25": 18.03,
        "zs3.5": 17.5,
        "zs3.75": 16.99,
        "zs4": 16.51,
        "zs4.25": 16.05,
        "zs4.5": 15.61,
        "zs4.75": 15.18,
        "zs5": 14.78
        },
        {
        "zs0": 27.23,
        "zs0.25": 26.24,
        "zs0.5": 25.3,
        "zs0.75": 24.41,
        "zs1": 23.57,
        "zs1.25": 22.77,
        "zs1.5": 22.01,
        "zs1.75": 21.29,
        "zs2": 20.61,
        "zs2.25": 19.96,
        "zs2.5": 19.34,
        "zs2.75": 18.75,
        "zs3": 18.19,
        "zs3.25": 17.65,
        "zs3.5": 17.14,
        "zs3.75": 16.66,
        "zs4": 16.19,
        "zs4.25": 15.75,
        "zs4.5": 15.33,
        "zs4.75": 14.92,
        "zs5": 14.53
        },
        {
        "zs0": 26.31,
        "zs0.25": 25.38,
        "zs0.5": 24.5,
        "zs0.75": 23.67,
        "zs1": 22.87,
        "zs1.25": 22.12,
        "zs1.5": 21.4,
        "zs1.75": 20.72,
        "zs2": 20.07,
        "zs2.25": 19.46,
        "zs2.5": 18.87,
        "zs2.75": 18.31,
        "zs3": 17.77,
        "zs3.25": 17.27,
        "zs3.5": 16.78,
        "zs3.75": 16.31,
        "zs4": 15.87,
        "zs4.25": 15.45,
        "zs4.5": 15.04,
        "zs4.75": 14.65,
        "zs5": 14.28
        },
        {
        "zs0": 25.4,
        "zs0.25": 24.53,
        "zs0.5": 23.7,
        "zs0.75": 22.92,
        "zs1": 22.17,
        "zs1.25": 21.46,
        "zs1.5": 20.79,
        "zs1.75": 20.15,
        "zs2": 19.53,
        "zs2.25": 18.95,
        "zs2.5": 18.39,
        "zs2.75": 17.86,
        "zs3": 17.35,
        "zs3.25": 16.87,
        "zs3.5": 16.41,
        "zs3.75": 15.96,
        "zs4": 15.54,
        "zs4.25": 15.13,
        "zs4.5": 14.75,
        "zs4.75": 14.37,
        "zs5": 14.02
        },
        {
        "zs0": 24.49,
        "zs0.25": 23.68,
        "zs0.5": 22.91,
        "zs0.75": 22.17,
        "zs1": 21.47,
        "zs1.25": 20.81,
        "zs1.5": 20.17,
        "zs1.75": 19.56,
        "zs2": 18.99,
        "zs2.25": 18.43,
        "zs2.5": 17.91,
        "zs2.75": 17.4,
        "zs3": 16.92,
        "zs3.25": 16.46,
        "zs3.5": 16.02,
        "zs3.75": 15.6,
        "zs4": 15.2,
        "zs4.25": 14.81,
        "zs4.5": 14.44,
        "zs4.75": 14.09,
        "zs5": 13.74
        },
        {
        "zs0": 23.6,
        "zs0.25": 22.84,
        "zs0.5": 22.12,
        "zs0.75": 21.43,
        "zs1": 20.77,
        "zs1.25": 20.15,
        "zs1.5": 19.55,
        "zs1.75": 18.98,
        "zs2": 18.43,
        "zs2.25": 17.91,
        "zs2.5": 17.42,
        "zs2.75": 16.94,
        "zs3": 16.49,
        "zs3.25": 16.05,
        "zs3.5": 15.63,
        "zs3.75": 15.23,
        "zs4": 14.85,
        "zs4.25": 14.48,
        "zs4.5": 14.13,
        "zs4.75": 13.79,
        "zs5": 13.46
        },
        {
        "zs0": 22.7,
        "zs0.25": 22,
        "zs0.5": 21.33,
        "zs0.75": 20.68,
        "zs1": 20.07,
        "zs1.25": 19.48,
        "zs1.5": 18.92,
        "zs1.75": 18.39,
        "zs2": 17.88,
        "zs2.25": 17.39,
        "zs2.5": 16.92,
        "zs2.75": 16.47,
        "zs3": 16.04,
        "zs3.25": 15.63,
        "zs3.5": 15.23,
        "zs3.75": 14.86,
        "zs4": 14.49,
        "zs4.25": 14.14,
        "zs4.5": 13.81,
        "zs4.75": 13.48,
        "zs5": 13.17
        },
        {
        "zs0": 21.82,
        "zs0.25": 21.16,
        "zs0.5": 20.54,
        "zs0.75": 19.94,
        "zs1": 19.37,
        "zs1.25": 18.82,
        "zs1.5": 18.3,
        "zs1.75": 17.8,
        "zs2": 17.32,
        "zs2.25": 16.86,
        "zs2.5": 16.42,
        "zs2.75": 16,
        "zs3": 15.59,
        "zs3.25": 15.2,
        "zs3.5": 14.83,
        "zs3.75": 14.47,
        "zs4": 14.12,
        "zs4.25": 13.79,
        "zs4.5": 13.48,
        "zs4.75": 13.17,
        "zs5": 12.87
        },
        {
        "zs0": 20.94,
        "zs0.25": 20.34,
        "zs0.5": 19.75,
        "zs0.75": 19.2,
        "zs1": 18.67,
        "zs1.25": 18.16,
        "zs1.5": 17.67,
        "zs1.75": 17.2,
        "zs2": 16.75,
        "zs2.25": 16.32,
        "zs2.5": 15.91,
        "zs2.75": 15.51,
        "zs3": 15.13,
        "zs3.25": 14.77,
        "zs3.5": 14.41,
        "zs3.75": 14.08,
        "zs4": 13.75,
        "zs4.25": 13.44,
        "zs4.5": 13.14,
        "zs4.75": 12.85,
        "zs5": 12.57
        },
        {
        "zs0": 20.08,
        "zs0.25": 19.51,
        "zs0.5": 18.98,
        "zs0.75": 18.46,
        "zs1": 17.97,
        "zs1.25": 17.49,
        "zs1.5": 17.04,
        "zs1.75": 16.6,
        "zs2": 16.18,
        "zs2.25": 15.78,
        "zs2.5": 15.4,
        "zs2.75": 15.02,
        "zs3": 14.67,
        "zs3.25": 14.32,
        "zs3.5": 13.99,
        "zs3.75": 13.67,
        "zs4": 13.37,
        "zs4.25": 13.07,
        "zs4.5": 12.79,
        "zs4.75": 12.51,
        "zs5": 12.25
        },
        {
        "zs0": 19.22,
        "zs0.25": 18.7,
        "zs0.5": 18.2,
        "zs0.75": 17.73,
        "zs1": 17.27,
        "zs1.25": 16.83,
        "zs1.5": 16.41,
        "zs1.75": 16,
        "zs2": 15.61,
        "zs2.25": 15.24,
        "zs2.5": 14.88,
        "zs2.75": 14.53,
        "zs3": 14.2,
        "zs3.25": 13.87,
        "zs3.5": 13.56,
        "zs3.75": 13.27,
        "zs4": 12.98,
        "zs4.25": 12.7,
        "zs4.5": 12.43,
        "zs4.75": 12.17,
        "zs5": 11.92
        },
        {
        "zs0": 18.37,
        "zs0.25": 17.89,
        "zs0.5": 17.43,
        "zs0.75": 16.99,
        "zs1": 16.57,
        "zs1.25": 16.17,
        "zs1.5": 15.78,
        "zs1.75": 15.4,
        "zs2": 15.04,
        "zs2.25": 14.69,
        "zs2.5": 14.35,
        "zs2.75": 14.03,
        "zs3": 13.72,
        "zs3.25": 13.42,
        "zs3.5": 13.13,
        "zs3.75": 12.85,
        "zs4": 12.58,
        "zs4.25": 12.32,
        "zs4.5": 12.07,
        "zs4.75": 11.82,
        "zs5": 11.59
        },
        {
        "zs0": 17.53,
        "zs0.25": 17.09,
        "zs0.5": 16.67,
        "zs0.75": 16.27,
        "zs1": 15.88,
        "zs1.25": 15.5,
        "zs1.5": 15.14,
        "zs1.75": 14.8,
        "zs2": 14.46,
        "zs2.25": 14.14,
        "zs2.5": 13.83,
        "zs2.75": 13.53,
        "zs3": 13.24,
        "zs3.25": 12.96,
        "zs3.5": 12.69,
        "zs3.75": 12.43,
        "zs4": 12.17,
        "zs4.25": 11.93,
        "zs4.5": 11.69,
        "zs4.75": 11.47,
        "zs5": 11.25
        },
        {
        "zs0": 16.7,
        "zs0.25": 16.3,
        "zs0.5": 15.92,
        "zs0.75": 15.55,
        "zs1": 15.19,
        "zs1.25": 14.85,
        "zs1.5": 14.51,
        "zs1.75": 14.19,
        "zs2": 13.88,
        "zs2.25": 13.59,
        "zs2.5": 13.3,
        "zs2.75": 13.02,
        "zs3": 12.75,
        "zs3.25": 12.49,
        "zs3.5": 12.24,
        "zs3.75": 12,
        "zs4": 11.76,
        "zs4.25": 11.53,
        "zs4.5": 11.31,
        "zs4.75": 11.1,
        "zs5": 10.9
        },
        {
        "zs0": 15.88,
        "zs0.25": 15.52,
        "zs0.5": 15.17,
        "zs0.75": 14.83,
        "zs1": 14.5,
        "zs1.25": 14.19,
        "zs1.5": 13.88,
        "zs1.75": 13.59,
        "zs2": 13.31,
        "zs2.25": 13.03,
        "zs2.5": 12.77,
        "zs2.75": 12.51,
        "zs3": 12.26,
        "zs3.25": 12.02,
        "zs3.5": 11.79,
        "zs3.75": 11.56,
        "zs4": 11.34,
        "zs4.25": 11.13,
        "zs4.5": 10.93,
        "zs4.75": 10.73,
        "zs5": 10.54
        },
        {
        "zs0": 15.08,
        "zs0.25": 14.75,
        "zs0.5": 14.43,
        "zs0.75": 14.12,
        "zs1": 13.82,
        "zs1.25": 13.54,
        "zs1.5": 13.26,
        "zs1.75": 12.99,
        "zs2": 12.73,
        "zs2.25": 12.48,
        "zs2.5": 12.23,
        "zs2.75": 11.99,
        "zs3": 11.77,
        "zs3.25": 11.54,
        "zs3.5": 11.33,
        "zs3.75": 11.12,
        "zs4": 10.92,
        "zs4.25": 10.72,
        "zs4.5": 10.53,
        "zs4.75": 10.35,
        "zs5": 10.17
        },
        {
        "zs0": 14.29,
        "zs0.25": 13.99,
        "zs0.5": 13.7,
        "zs0.75": 13.42,
        "zs1": 13.15,
        "zs1.25": 12.89,
        "zs1.5": 12.63,
        "zs1.75": 12.39,
        "zs2": 12.15,
        "zs2.25": 11.92,
        "zs2.5": 11.7,
        "zs2.75": 11.48,
        "zs3": 11.27,
        "zs3.25": 11.07,
        "zs3.5": 10.87,
        "zs3.75": 10.68,
        "zs4": 10.49,
        "zs4.25": 10.31,
        "zs4.5": 10.13,
        "zs4.75": 9.96,
        "zs5": 9.8
        },
        {
        "zs0": 13.52,
        "zs0.25": 13.24,
        "zs0.5": 12.98,
        "zs0.75": 12.73,
        "zs1": 12.48,
        "zs1.25": 12.25,
        "zs1.5": 12.02,
        "zs1.75": 11.79,
        "zs2": 11.58,
        "zs2.25": 11.37,
        "zs2.5": 11.16,
        "zs2.75": 10.96,
        "zs3": 10.77,
        "zs3.25": 10.58,
        "zs3.5": 10.4,
        "zs3.75": 10.23,
        "zs4": 10.06,
        "zs4.25": 9.89,
        "zs4.5": 9.73,
        "zs4.75": 9.57,
        "zs5": 9.42
        },
        {
        "zs0": 12.75,
        "zs0.25": 12.51,
        "zs0.5": 12.28,
        "zs0.75": 12.05,
        "zs1": 11.83,
        "zs1.25": 11.61,
        "zs1.5": 11.4,
        "zs1.75": 11.2,
        "zs2": 11,
        "zs2.25": 10.81,
        "zs2.5": 10.63,
        "zs2.75": 10.45,
        "zs3": 10.27,
        "zs3.25": 10.1,
        "zs3.5": 9.94,
        "zs3.75": 9.78,
        "zs4": 9.62,
        "zs4.25": 9.47,
        "zs4.5": 9.32,
        "zs4.75": 9.17,
        "zs5": 9.03
        },
        {
        "zs0": 12.01,
        "zs0.25": 11.79,
        "zs0.5": 11.58,
        "zs0.75": 11.38,
        "zs1": 11.18,
        "zs1.25": 10.98,
        "zs1.5": 10.8,
        "zs1.75": 10.61,
        "zs2": 10.44,
        "zs2.25": 10.26,
        "zs2.5": 10.1,
        "zs2.75": 9.93,
        "zs3": 9.77,
        "zs3.25": 9.62,
        "zs3.5": 9.47,
        "zs3.75": 9.32,
        "zs4": 9.18,
        "zs4.25": 9.04,
        "zs4.5": 8.91,
        "zs4.75": 8.77,
        "zs5": 8.65
        },
        {
        "zs0": 11.28,
        "zs0.25": 11.09,
        "zs0.5": 10.9,
        "zs0.75": 10.72,
        "zs1": 10.54,
        "zs1.25": 10.36,
        "zs1.5": 10.2,
        "zs1.75": 10.03,
        "zs2": 9.87,
        "zs2.25": 9.72,
        "zs2.5": 9.57,
        "zs2.75": 9.42,
        "zs3": 9.28,
        "zs3.25": 9.14,
        "zs3.5": 9,
        "zs3.75": 8.87,
        "zs4": 8.74,
        "zs4.25": 8.61,
        "zs4.5": 8.49,
        "zs4.75": 8.37,
        "zs5": 8.25
        },
        {
        "zs0": 10.57,
        "zs0.25": 10.4,
        "zs0.5": 10.23,
        "zs0.75": 10.07,
        "zs1": 9.91,
        "zs1.25": 9.76,
        "zs1.5": 9.61,
        "zs1.75": 9.46,
        "zs2": 9.32,
        "zs2.25": 9.18,
        "zs2.5": 9.04,
        "zs2.75": 8.91,
        "zs3": 8.78,
        "zs3.25": 8.65,
        "zs3.5": 8.53,
        "zs3.75": 8.41,
        "zs4": 8.3,
        "zs4.25": 8.18,
        "zs4.5": 8.07,
        "zs4.75": 7.96,
        "zs5": 7.86
        },
        {
        "zs0": 9.88,
        "zs0.25": 9.73,
        "zs0.5": 9.58,
        "zs0.75": 9.44,
        "zs1": 9.29,
        "zs1.25": 9.16,
        "zs1.5": 9.02,
        "zs1.75": 8.89,
        "zs2": 8.77,
        "zs2.25": 8.64,
        "zs2.5": 8.52,
        "zs2.75": 8.4,
        "zs3": 8.29,
        "zs3.25": 8.18,
        "zs3.5": 8.07,
        "zs3.75": 7.96,
        "zs4": 7.85,
        "zs4.25": 7.75,
        "zs4.5": 7.65,
        "zs4.75": 7.55,
        "zs5": 7.46
        },
        {
        "zs0": 9.21,
        "zs0.25": 9.07,
        "zs0.5": 8.94,
        "zs0.75": 8.82,
        "zs1": 8.69,
        "zs1.25": 8.57,
        "zs1.5": 8.45,
        "zs1.75": 8.34,
        "zs2": 8.23,
        "zs2.25": 8.12,
        "zs2.5": 8.01,
        "zs2.75": 7.9,
        "zs3": 7.8,
        "zs3.25": 7.7,
        "zs3.5": 7.6,
        "zs3.75": 7.51,
        "zs4": 7.41,
        "zs4.25": 7.32,
        "zs4.5": 7.23,
        "zs4.75": 7.15,
        "zs5": 7.06
        },
        {
        "zs0": 8.56,
        "zs0.25": 8.44,
        "zs0.5": 8.33,
        "zs0.75": 8.22,
        "zs1": 8.11,
        "zs1.25": 8,
        "zs1.5": 7.9,
        "zs1.75": 7.8,
        "zs2": 7.7,
        "zs2.25": 7.6,
        "zs2.5": 7.5,
        "zs2.75": 7.41,
        "zs3": 7.32,
        "zs3.25": 7.23,
        "zs3.5": 7.15,
        "zs3.75": 7.06,
        "zs4": 6.98,
        "zs4.25": 6.9,
        "zs4.5": 6.82,
        "zs4.75": 6.74,
        "zs5": 6.66
        },
        {
        "zs0": 7.93,
        "zs0.25": 7.83,
        "zs0.5": 7.73,
        "zs0.75": 7.63,
        "zs1": 7.54,
        "zs1.25": 7.44,
        "zs1.5": 7.35,
        "zs1.75": 7.27,
        "zs2": 7.18,
        "zs2.25": 7.09,
        "zs2.5": 7.01,
        "zs2.75": 6.93,
        "zs3": 6.85,
        "zs3.25": 6.77,
        "zs3.5": 6.6899999999999995,
        "zs3.75": 6.62,
        "zs4": 6.55,
        "zs4.25": 6.47,
        "zs4.5": 6.4,
        "zs4.75": 6.33,
        "zs5": 6.27
        },
        {
        "zs0": 7.32,
        "zs0.25": 7.24,
        "zs0.5": 7.15,
        "zs0.75": 7.07,
        "zs1": 6.98,
        "zs1.25": 6.9,
        "zs1.5": 6.83,
        "zs1.75": 6.75,
        "zs2": 6.67,
        "zs2.25": 6.6,
        "zs2.5": 6.53,
        "zs2.75": 6.45,
        "zs3": 6.39,
        "zs3.25": 6.32,
        "zs3.5": 6.25,
        "zs3.75": 6.18,
        "zs4": 6.12,
        "zs4.25": 6.06,
        "zs4.5": 5.99,
        "zs4.75": 5.93,
        "zs5": 5.87
        },
        {
        "zs0": 6.74,
        "zs0.25": 6.67,
        "zs0.5": 6.59,
        "zs0.75": 6.52,
        "zs1": 6.45,
        "zs1.25": 6.38,
        "zs1.5": 6.31,
        "zs1.75": 6.25,
        "zs2": 6.18,
        "zs2.25": 6.12,
        "zs2.5": 6.05,
        "zs2.75": 5.99,
        "zs3": 5.93,
        "zs3.25": 5.87,
        "zs3.5": 5.8100000000000005,
        "zs3.75": 5.76,
        "zs4": 5.7,
        "zs4.25": 5.65,
        "zs4.5": 5.59,
        "zs4.75": 5.54,
        "zs5": 5.49
        },
        {
        "zs0": 6.19,
        "zs0.25": 6.12,
        "zs0.5": 6.06,
        "zs0.75": 6,
        "zs1": 5.9399999999999995,
        "zs1.25": 5.88,
        "zs1.5": 5.82,
        "zs1.75": 5.76,
        "zs2": 5.71,
        "zs2.25": 5.65,
        "zs2.5": 5.6,
        "zs2.75": 5.54,
        "zs3": 5.49,
        "zs3.25": 5.44,
        "zs3.5": 5.39,
        "zs3.75": 5.34,
        "zs4": 5.29,
        "zs4.25": 5.24,
        "zs4.5": 5.2,
        "zs4.75": 5.15,
        "zs5": 5.1
        },
        {
        "zs0": 5.66,
        "zs0.25": 5.6,
        "zs0.5": 5.55,
        "zs0.75": 5.5,
        "zs1": 5.45,
        "zs1.25": 5.4,
        "zs1.5": 5.35,
        "zs1.75": 5.3,
        "zs2": 5.25,
        "zs2.25": 5.2,
        "zs2.5": 5.16,
        "zs2.75": 5.11,
        "zs3": 5.07,
        "zs3.25": 5.02,
        "zs3.5": 4.98,
        "zs3.75": 4.94,
        "zs4": 4.89,
        "zs4.25": 4.85,
        "zs4.5": 4.81,
        "zs4.75": 4.77,
        "zs5": 4.73
        },
        {
        "zs0": 5.16,
        "zs0.25": 5.11,
        "zs0.5": 5.06,
        "zs0.75": 5.02,
        "zs1": 4.98,
        "zs1.25": 4.93,
        "zs1.5": 4.89,
        "zs1.75": 4.85,
        "zs2": 4.81,
        "zs2.25": 4.77,
        "zs2.5": 4.73,
        "zs2.75": 4.69,
        "zs3": 4.65,
        "zs3.25": 4.62,
        "zs3.5": 4.58,
        "zs3.75": 4.54,
        "zs4": 4.51,
        "zs4.25": 4.47,
        "zs4.5": 4.44,
        "zs4.75": 4.4,
        "zs5": 4.37
        },
        {
        "zs0": 4.68,
        "zs0.25": 4.64,
        "zs0.5": 4.6,
        "zs0.75": 4.57,
        "zs1": 4.53,
        "zs1.25": 4.5,
        "zs1.5": 4.46,
        "zs1.75": 4.43,
        "zs2": 4.39,
        "zs2.25": 4.36,
        "zs2.5": 4.32,
        "zs2.75": 4.29,
        "zs3": 4.26,
        "zs3.25": 4.23,
        "zs3.5": 4.2,
        "zs3.75": 4.17,
        "zs4": 4.14,
        "zs4.25": 4.11,
        "zs4.5": 4.08,
        "zs4.75": 4.05,
        "zs5": 4.02
        },
        {
        "zs0": 4.23,
        "zs0.25": 4.2,
        "zs0.5": 4.17,
        "zs0.75": 4.14,
        "zs1": 4.11,
        "zs1.25": 4.08,
        "zs1.5": 4.05,
        "zs1.75": 4.02,
        "zs2": 3.99,
        "zs2.25": 3.96,
        "zs2.5": 3.94,
        "zs2.75": 3.91,
        "zs3": 3.88,
        "zs3.25": 3.86,
        "zs3.5": 3.83,
        "zs3.75": 3.8,
        "zs4": 3.7800000000000002,
        "zs4.25": 3.75,
        "zs4.5": 3.73,
        "zs4.75": 3.7,
        "zs5": 3.68
        },
        {
        "zs0": 3.82,
        "zs0.25": 3.79,
        "zs0.5": 3.76,
        "zs0.75": 3.74,
        "zs1": 3.71,
        "zs1.25": 3.69,
        "zs1.5": 3.66,
        "zs1.75": 3.64,
        "zs2": 3.62,
        "zs2.25": 3.59,
        "zs2.5": 3.57,
        "zs2.75": 3.55,
        "zs3": 3.5300000000000002,
        "zs3.25": 3.5,
        "zs3.5": 3.48,
        "zs3.75": 3.46,
        "zs4": 3.44,
        "zs4.25": 3.42,
        "zs4.5": 3.4,
        "zs4.75": 3.38,
        "zs5": 3.36
        },
        {
        "zs0": 3.43,
        "zs0.25": 3.41,
        "zs0.5": 3.38,
        "zs0.75": 3.36,
        "zs1": 3.34,
        "zs1.25": 3.32,
        "zs1.5": 3.3,
        "zs1.75": 3.2800000000000002,
        "zs2": 3.26,
        "zs2.25": 3.24,
        "zs2.5": 3.23,
        "zs2.75": 3.21,
        "zs3": 3.19,
        "zs3.25": 3.17,
        "zs3.5": 3.15,
        "zs3.75": 3.13,
        "zs4": 3.12,
        "zs4.25": 3.1,
        "zs4.5": 3.08,
        "zs4.75": 3.07,
        "zs5": 3.05
        },
        {
        "zs0": 3.07,
        "zs0.25": 3.05,
        "zs0.5": 3.03,
        "zs0.75": 3.02,
        "zs1": 3,
        "zs1.25": 2.98,
        "zs1.5": 2.9699999999999998,
        "zs1.75": 2.95,
        "zs2": 2.94,
        "zs2.25": 2.92,
        "zs2.5": 2.9,
        "zs2.75": 2.89,
        "zs3": 2.87,
        "zs3.25": 2.86,
        "zs3.5": 2.84,
        "zs3.75": 2.83,
        "zs4": 2.82,
        "zs4.25": 2.8,
        "zs4.5": 2.79,
        "zs4.75": 2.77,
        "zs5": 2.76
        },
        {
        "zs0": 2.74,
        "zs0.25": 2.73,
        "zs0.5": 2.71,
        "zs0.75": 2.7,
        "zs1": 2.69,
        "zs1.25": 2.67,
        "zs1.5": 2.66,
        "zs1.75": 2.65,
        "zs2": 2.63,
        "zs2.25": 2.62,
        "zs2.5": 2.61,
        "zs2.75": 2.6,
        "zs3": 2.58,
        "zs3.25": 2.57,
        "zs3.5": 2.56,
        "zs3.75": 2.55,
        "zs4": 2.54,
        "zs4.25": 2.53,
        "zs4.5": 2.51,
        "zs4.75": 2.5,
        "zs5": 2.49
        },
        {
        "zs0": 2.45,
        "zs0.25": 2.44,
        "zs0.5": 2.43,
        "zs0.75": 2.41,
        "zs1": 2.4,
        "zs1.25": 2.39,
        "zs1.5": 2.38,
        "zs1.75": 2.37,
        "zs2": 2.36,
        "zs2.25": 2.35,
        "zs2.5": 2.34,
        "zs2.75": 2.33,
        "zs3": 2.32,
        "zs3.25": 2.31,
        "zs3.5": 2.3,
        "zs3.75": 2.29,
        "zs4": 2.28,
        "zs4.25": 2.27,
        "zs4.5": 2.26,
        "zs4.75": 2.25,
        "zs5": 2.25
        },
        {
        "zs0": 2.18,
        "zs0.25": 2.17,
        "zs0.5": 2.16,
        "zs0.75": 2.15,
        "zs1": 2.15,
        "zs1.25": 2.14,
        "zs1.5": 2.13,
        "zs1.75": 2.12,
        "zs2": 2.11,
        "zs2.25": 2.1,
        "zs2.5": 2.1,
        "zs2.75": 2.09,
        "zs3": 2.08,
        "zs3.25": 2.07,
        "zs3.5": 2.06,
        "zs3.75": 2.06,
        "zs4": 2.05,
        "zs4.25": 2.04,
        "zs4.5": 2.03,
        "zs4.75": 2.03,
        "zs5": 2.02
        },
        {
        "zs0": 1.94,
        "zs0.25": 1.9300000000000002,
        "zs0.5": 1.92,
        "zs0.75": 1.92,
        "zs1": 1.9100000000000001,
        "zs1.25": 1.9,
        "zs1.5": 1.9,
        "zs1.75": 1.8900000000000001,
        "zs2": 1.88,
        "zs2.25": 1.88,
        "zs2.5": 1.87,
        "zs2.75": 1.8599999999999999,
        "zs3": 1.8599999999999999,
        "zs3.25": 1.85,
        "zs3.5": 1.8399999999999999,
        "zs3.75": 1.8399999999999999,
        "zs4": 1.83,
        "zs4.25": 1.83,
        "zs4.5": 1.8199999999999998,
        "zs4.75": 1.81,
        "zs5": 1.81
        },
        {
        "zs0": 1.72,
        "zs0.25": 1.71,
        "zs0.5": 1.71,
        "zs0.75": 1.7,
        "zs1": 1.7,
        "zs1.25": 1.69,
        "zs1.5": 1.69,
        "zs1.75": 1.6800000000000002,
        "zs2": 1.6800000000000002,
        "zs2.25": 1.67,
        "zs2.5": 1.67,
        "zs2.75": 1.6600000000000001,
        "zs3": 1.6600000000000001,
        "zs3.25": 1.65,
        "zs3.5": 1.65,
        "zs3.75": 1.6400000000000001,
        "zs4": 1.6400000000000001,
        "zs4.25": 1.63,
        "zs4.5": 1.63,
        "zs4.75": 1.62,
        "zs5": 1.62
        },
        {
        "zs0": 1.53,
        "zs0.25": 1.52,
        "zs0.5": 1.52,
        "zs0.75": 1.51,
        "zs1": 1.51,
        "zs1.25": 1.51,
        "zs1.5": 1.5,
        "zs1.75": 1.5,
        "zs2": 1.49,
        "zs2.25": 1.49,
        "zs2.5": 1.49,
        "zs2.75": 1.48,
        "zs3": 1.48,
        "zs3.25": 1.48,
        "zs3.5": 1.47,
        "zs3.75": 1.47,
        "zs4": 1.46,
        "zs4.25": 1.46,
        "zs4.5": 1.46,
        "zs4.75": 1.45,
        "zs5": 1.45
        },
        {
        "zs0": 1.3599999999999999,
        "zs0.25": 1.3599999999999999,
        "zs0.5": 1.3599999999999999,
        "zs0.75": 1.35,
        "zs1": 1.35,
        "zs1.25": 1.35,
        "zs1.5": 1.34,
        "zs1.75": 1.34,
        "zs2": 1.34,
        "zs2.25": 1.33,
        "zs2.5": 1.33,
        "zs2.75": 1.33,
        "zs3": 1.32,
        "zs3.25": 1.32,
        "zs3.5": 1.32,
        "zs3.75": 1.32,
        "zs4": 1.31,
        "zs4.25": 1.31,
        "zs4.5": 1.31,
        "zs4.75": 1.3,
        "zs5": 1.3
        },
        {
        "zs0": 1.22,
        "zs0.25": 1.22,
        "zs0.5": 1.22,
        "zs0.75": 1.21,
        "zs1": 1.21,
        "zs1.25": 1.21,
        "zs1.5": 1.21,
        "zs1.75": 1.2,
        "zs2": 1.2,
        "zs2.25": 1.2,
        "zs2.5": 1.2,
        "zs2.75": 1.19,
        "zs3": 1.19,
        "zs3.25": 1.19,
        "zs3.5": 1.19,
        "zs3.75": 1.18,
        "zs4": 1.18,
        "zs4.25": 1.18,
        "zs4.5": 1.18,
        "zs4.75": 1.18,
        "zs5": 1.17
        },
        {
        "zs0": 1.11,
        "zs0.25": 1.11,
        "zs0.5": 1.11,
        "zs0.75": 1.11,
        "zs1": 1.11,
        "zs1.25": 1.1,
        "zs1.5": 1.1,
        "zs1.75": 1.1,
        "zs2": 1.1,
        "zs2.25": 1.1,
        "zs2.5": 1.09,
        "zs2.75": 1.09,
        "zs3": 1.09,
        "zs3.25": 1.09,
        "zs3.5": 1.09,
        "zs3.75": 1.08,
        "zs4": 1.08,
        "zs4.25": 1.08,
        "zs4.5": 1.08,
        "zs4.75": 1.08,
        "zs5": 1.08
        },
        {
        "zs0": 1.02,
        "zs0.25": 1.02,
        "zs0.5": 1.02,
        "zs0.75": 1.02,
        "zs1": 1.02,
        "zs1.25": 1.02,
        "zs1.5": 1.01,
        "zs1.75": 1.01,
        "zs2": 1.01,
        "zs2.25": 1.01,
        "zs2.5": 1.01,
        "zs2.75": 1.01,
        "zs3": 1.01,
        "zs3.25": 1,
        "zs3.5": 1,
        "zs3.75": 1,
        "zs4": 1,
        "zs4.25": 1,
        "zs4.5": 1,
        "zs4.75": 1,
        "zs5": 0.99
        },
        {
        "zs0": 0.9,
        "zs0.25": 0.9,
        "zs0.5": 0.9,
        "zs0.75": 0.9,
        "zs1": 0.9,
        "zs1.25": 0.9,
        "zs1.5": 0.89,
        "zs1.75": 0.89,
        "zs2": 0.89,
        "zs2.25": 0.89,
        "zs2.5": 0.89,
        "zs2.75": 0.89,
        "zs3": 0.89,
        "zs3.25": 0.89,
        "zs3.5": 0.89,
        "zs3.75": 0.89,
        "zs4": 0.88,
        "zs4.25": 0.88,
        "zs4.5": 0.88,
        "zs4.75": 0.88,
        "zs5": 0.88
        },
        {
        "zs0": 0.71,
        "zs0.25": 0.71,
        "zs0.5": 0.71,
        "zs0.75": 0.71,
        "zs1": 0.71,
        "zs1.25": 0.71,
        "zs1.5": 0.71,
        "zs1.75": 0.71,
        "zs2": 0.71,
        "zs2.25": 0.7,
        "zs2.5": 0.7,
        "zs2.75": 0.7,
        "zs3": 0.7,
        "zs3.25": 0.7,
        "zs3.5": 0.7,
        "zs3.75": 0.7,
        "zs4": 0.7,
        "zs4.25": 0.7,
        "zs4.5": 0.7,
        "zs4.75": 0.7,
        "zs5": 0.7
        },
        {
        "zs0": 0.54,
        "zs0.25": 0.54,
        "zs0.5": 0.54,
        "zs0.75": 0.54,
        "zs1": 0.54,
        "zs1.25": 0.54,
        "zs1.5": 0.54,
        "zs1.75": 0.54,
        "zs2": 0.54,
        "zs2.25": 0.54,
        "zs2.5": 0.54,
        "zs2.75": 0.54,
        "zs3": 0.54,
        "zs3.25": 0.54,
        "zs3.5": 0.54,
        "zs3.75": 0.54,
        "zs4": 0.54,
        "zs4.25": 0.54,
        "zs4.5": 0.54,
        "zs4.75": 0.54,
        "zs5": 0.54
        }
    ],

    bwfMann:  
    [
        {
         "zs0": 85.43,
         "zs0.25": 76.76,
         "zs0.5": 69.28,
         "zs0.75": 62.79,
         "zs1": 57.16,
         "zs1.25": 52.24,
         "zs1.5": 47.94,
         "zs1.75": 44.16,
         "zs2": 40.83,
         "zs2.25": 37.88,
         "zs2.5": 35.27,
         "zs2.75": 32.94,
         "zs3": 30.86,
         "zs3.25": 28.99,
         "zs3.5": 27.31,
         "zs3.75": 25.8,
         "zs4": 24.43,
         "zs4.25": 23.18,
         "zs4.5": 22.05,
         "zs4.75": 21.02,
         "zs5": 20.07
        },
        {
         "zs0": 84.64,
         "zs0.25": 76.13,
         "zs0.5": 68.78,
         "zs0.75": 62.41,
         "zs1": 56.86,
         "zs1.25": 52.01,
         "zs1.5": 47.76,
         "zs1.75": 44.03,
         "zs2": 40.73,
         "zs2.25": 37.81,
         "zs2.5": 35.22,
         "zs2.75": 32.91,
         "zs3": 30.84,
         "zs3.25": 28.98,
         "zs3.5": 27.31,
         "zs3.75": 25.81,
         "zs4": 24.44,
         "zs4.25": 23.2,
         "zs4.5": 22.07,
         "zs4.75": 21.04,
         "zs5": 20.1
        },
        {
         "zs0": 83.66,
         "zs0.25": 75.35,
         "zs0.5": 68.15,
         "zs0.75": 61.89,
         "zs1": 56.44,
         "zs1.25": 51.67,
         "zs1.5": 47.49,
         "zs1.75": 43.8,
         "zs2": 40.55,
         "zs2.25": 37.66,
         "zs2.5": 35.1,
         "zs2.75": 32.81,
         "zs3": 30.76,
         "zs3.25": 28.92,
         "zs3.5": 27.26,
         "zs3.75": 25.76,
         "zs4": 24.41,
         "zs4.25": 23.17,
         "zs4.5": 22.05,
         "zs4.75": 21.02,
         "zs5": 20.08
        },
        {
         "zs0": 82.68,
         "zs0.25": 74.55,
         "zs0.5": 67.5,
         "zs0.75": 61.36,
         "zs1": 56.01,
         "zs1.25": 51.32,
         "zs1.5": 47.2,
         "zs1.75": 43.57,
         "zs2": 40.35,
         "zs2.25": 37.5,
         "zs2.5": 34.97,
         "zs2.75": 32.7,
         "zs3": 30.67,
         "zs3.25": 28.85,
         "zs3.5": 27.2,
         "zs3.75": 25.71,
         "zs4": 24.36,
         "zs4.25": 23.14,
         "zs4.5": 22.02,
         "zs4.75": 21,
         "zs5": 20.06
        },
        {
         "zs0": 81.69,
         "zs0.25": 73.74,
         "zs0.5": 66.84,
         "zs0.75": 60.83,
         "zs1": 55.57,
         "zs1.25": 50.96,
         "zs1.5": 46.91,
         "zs1.75": 43.33,
         "zs2": 40.16,
         "zs2.25": 37.34,
         "zs2.5": 34.83,
         "zs2.75": 32.59,
         "zs3": 30.58,
         "zs3.25": 28.77,
         "zs3.5": 27.14,
         "zs3.75": 25.66,
         "zs4": 24.32,
         "zs4.25": 23.1,
         "zs4.5": 21.99,
         "zs4.75": 20.97,
         "zs5": 20.04
        },
        {
         "zs0": 80.69,
         "zs0.25": 72.93,
         "zs0.5": 66.18,
         "zs0.75": 60.28,
         "zs1": 55.13,
         "zs1.25": 50.6,
         "zs1.5": 46.6,
         "zs1.75": 43.08,
         "zs2": 39.95,
         "zs2.25": 37.17,
         "zs2.5": 34.69,
         "zs2.75": 32.47,
         "zs3": 30.48,
         "zs3.25": 28.69,
         "zs3.5": 27.07,
         "zs3.75": 25.6,
         "zs4": 24.27,
         "zs4.25": 23.06,
         "zs4.5": 21.96,
         "zs4.75": 20.94,
         "zs5": 20.02
        },
        {
         "zs0": 79.7,
         "zs0.25": 72.12,
         "zs0.5": 65.51,
         "zs0.75": 59.74,
         "zs1": 54.67,
         "zs1.25": 50.22,
         "zs1.5": 46.3,
         "zs1.75": 42.83,
         "zs2": 39.74,
         "zs2.25": 37,
         "zs2.5": 34.55,
         "zs2.75": 32.35,
         "zs3": 30.38,
         "zs3.25": 28.61,
         "zs3.5": 27,
         "zs3.75": 25.55,
         "zs4": 24.22,
         "zs4.25": 23.02,
         "zs4.5": 21.92,
         "zs4.75": 20.91,
         "zs5": 19.99
        },
        {
         "zs0": 78.7,
         "zs0.25": 71.3,
         "zs0.5": 64.84,
         "zs0.75": 59.18,
         "zs1": 54.22,
         "zs1.25": 49.85,
         "zs1.5": 45.99,
         "zs1.75": 42.57,
         "zs2": 39.53,
         "zs2.25": 36.82,
         "zs2.5": 34.4,
         "zs2.75": 32.23,
         "zs3": 30.28,
         "zs3.25": 28.52,
         "zs3.5": 26.93,
         "zs3.75": 25.48,
         "zs4": 24.17,
         "zs4.25": 22.98,
         "zs4.5": 21.88,
         "zs4.75": 20.88,
         "zs5": 19.96
        },
        {
         "zs0": 77.71,
         "zs0.25": 70.48,
         "zs0.5": 64.16,
         "zs0.75": 58.62,
         "zs1": 53.76,
         "zs1.25": 49.46,
         "zs1.5": 45.67,
         "zs1.75": 42.3,
         "zs2": 39.31,
         "zs2.25": 36.64,
         "zs2.5": 34.25,
         "zs2.75": 32.1,
         "zs3": 30.17,
         "zs3.25": 28.43,
         "zs3.5": 26.85,
         "zs3.75": 25.42,
         "zs4": 24.12,
         "zs4.25": 22.93,
         "zs4.5": 21.85,
         "zs4.75": 20.85,
         "zs5": 19.93
        },
        {
         "zs0": 76.71,
         "zs0.25": 69.65,
         "zs0.5": 63.48,
         "zs0.75": 58.06,
         "zs1": 53.29,
         "zs1.25": 49.08,
         "zs1.5": 45.35,
         "zs1.75": 42.04,
         "zs2": 39.09,
         "zs2.25": 36.45,
         "zs2.5": 34.09,
         "zs2.75": 31.97,
         "zs3": 30.06,
         "zs3.25": 28.34,
         "zs3.5": 26.77,
         "zs3.75": 25.35,
         "zs4": 24.06,
         "zs4.25": 22.88,
         "zs4.5": 21.8,
         "zs4.75": 20.82,
         "zs5": 19.91
        },
        {
         "zs0": 75.71,
         "zs0.25": 68.83,
         "zs0.5": 62.8,
         "zs0.75": 57.49,
         "zs1": 52.82,
         "zs1.25": 48.69,
         "zs1.5": 45.02,
         "zs1.75": 41.76,
         "zs2": 38.86,
         "zs2.25": 36.26,
         "zs2.5": 33.93,
         "zs2.75": 31.83,
         "zs3": 29.95,
         "zs3.25": 28.24,
         "zs3.5": 26.69,
         "zs3.75": 25.29,
         "zs4": 24,
         "zs4.25": 22.83,
         "zs4.5": 21.76,
         "zs4.75": 20.78,
         "zs5": 19.87
        },
        {
         "zs0": 74.71,
         "zs0.25": 68,
         "zs0.5": 62.11,
         "zs0.75": 56.92,
         "zs1": 52.34,
         "zs1.25": 48.29,
         "zs1.5": 44.69,
         "zs1.75": 41.48,
         "zs2": 38.62,
         "zs2.25": 36.06,
         "zs2.5": 33.76,
         "zs2.75": 31.7,
         "zs3": 29.83,
         "zs3.25": 28.14,
         "zs3.5": 26.61,
         "zs3.75": 25.21,
         "zs4": 23.94,
         "zs4.25": 22.78,
         "zs4.5": 21.72,
         "zs4.75": 20.74,
         "zs5": 19.84
        },
        {
         "zs0": 73.71,
         "zs0.25": 67.17,
         "zs0.5": 61.42,
         "zs0.75": 56.34,
         "zs1": 51.86,
         "zs1.25": 47.89,
         "zs1.5": 44.35,
         "zs1.75": 41.2,
         "zs2": 38.39,
         "zs2.25": 35.86,
         "zs2.5": 33.6,
         "zs2.75": 31.55,
         "zs3": 29.71,
         "zs3.25": 28.04,
         "zs3.5": 26.52,
         "zs3.75": 25.14,
         "zs4": 23.88,
         "zs4.25": 22.73,
         "zs4.5": 21.67,
         "zs4.75": 20.7,
         "zs5": 19.81
        },
        {
         "zs0": 72.71,
         "zs0.25": 66.34,
         "zs0.5": 60.72,
         "zs0.75": 55.76,
         "zs1": 51.37,
         "zs1.25": 47.48,
         "zs1.5": 44.01,
         "zs1.75": 40.91,
         "zs2": 38.14,
         "zs2.25": 35.66,
         "zs2.5": 33.42,
         "zs2.75": 31.41,
         "zs3": 29.58,
         "zs3.25": 27.93,
         "zs3.5": 26.43,
         "zs3.75": 25.06,
         "zs4": 23.81,
         "zs4.25": 22.67,
         "zs4.5": 21.62,
         "zs4.75": 20.66,
         "zs5": 19.77
        },
        {
         "zs0": 71.71,
         "zs0.25": 65.5,
         "zs0.5": 60.02,
         "zs0.75": 55.18,
         "zs1": 50.88,
         "zs1.25": 47.07,
         "zs1.5": 43.66,
         "zs1.75": 40.62,
         "zs2": 37.9,
         "zs2.25": 35.45,
         "zs2.5": 33.25,
         "zs2.75": 31.26,
         "zs3": 29.46,
         "zs3.25": 27.82,
         "zs3.5": 26.34,
         "zs3.75": 24.98,
         "zs4": 23.74,
         "zs4.25": 22.61,
         "zs4.5": 21.57,
         "zs4.75": 20.61,
         "zs5": 19.73
        },
        {
         "zs0": 70.71,
         "zs0.25": 64.67,
         "zs0.5": 59.32,
         "zs0.75": 54.59,
         "zs1": 50.39,
         "zs1.25": 46.65,
         "zs1.5": 43.31,
         "zs1.75": 40.32,
         "zs2": 37.64,
         "zs2.25": 35.24,
         "zs2.5": 33.06,
         "zs2.75": 31.1,
         "zs3": 29.32,
         "zs3.25": 27.71,
         "zs3.5": 26.24,
         "zs3.75": 24.9,
         "zs4": 23.67,
         "zs4.25": 22.55,
         "zs4.5": 21.52,
         "zs4.75": 20.57,
         "zs5": 19.69
        },
        {
         "zs0": 69.72,
         "zs0.25": 63.83,
         "zs0.5": 58.62,
         "zs0.75": 54,
         "zs1": 49.89,
         "zs1.25": 46.23,
         "zs1.5": 42.95,
         "zs1.75": 40.02,
         "zs2": 37.39,
         "zs2.25": 35.02,
         "zs2.5": 32.88,
         "zs2.75": 30.94,
         "zs3": 29.19,
         "zs3.25": 27.59,
         "zs3.5": 26.14,
         "zs3.75": 24.81,
         "zs4": 23.6,
         "zs4.25": 22.49,
         "zs4.5": 21.46,
         "zs4.75": 20.52,
         "zs5": 19.65
        },
        {
         "zs0": 68.72,
         "zs0.25": 62.99,
         "zs0.5": 57.92,
         "zs0.75": 53.4,
         "zs1": 49.39,
         "zs1.25": 45.8,
         "zs1.5": 42.59,
         "zs1.75": 39.72,
         "zs2": 37.13,
         "zs2.25": 34.8,
         "zs2.5": 32.69,
         "zs2.75": 30.78,
         "zs3": 29.05,
         "zs3.25": 27.48,
         "zs3.5": 26.04,
         "zs3.75": 24.73,
         "zs4": 23.52,
         "zs4.25": 22.42,
         "zs4.5": 21.41,
         "zs4.75": 20.47,
         "zs5": 19.61
        },
        {
         "zs0": 67.74,
         "zs0.25": 62.16,
         "zs0.5": 57.21,
         "zs0.75": 52.81,
         "zs1": 48.88,
         "zs1.25": 45.38,
         "zs1.5": 42.23,
         "zs1.75": 39.41,
         "zs2": 36.87,
         "zs2.25": 34.57,
         "zs2.5": 32.5,
         "zs2.75": 30.62,
         "zs3": 28.91,
         "zs3.25": 27.36,
         "zs3.5": 25.94,
         "zs3.75": 24.64,
         "zs4": 23.45,
         "zs4.25": 22.35,
         "zs4.5": 21.35,
         "zs4.75": 20.42,
         "zs5": 19.56
        },
        {
         "zs0": 66.75,
         "zs0.25": 61.33,
         "zs0.5": 56.51,
         "zs0.75": 52.22,
         "zs1": 48.38,
         "zs1.25": 44.95,
         "zs1.5": 41.87,
         "zs1.75": 39.1,
         "zs2": 36.6,
         "zs2.25": 34.35,
         "zs2.5": 32.31,
         "zs2.75": 30.45,
         "zs3": 28.77,
         "zs3.25": 27.23,
         "zs3.5": 25.83,
         "zs3.75": 24.55,
         "zs4": 23.37,
         "zs4.25": 22.29,
         "zs4.5": 21.29,
         "zs4.75": 20.37,
         "zs5": 19.52
        },
        {
         "zs0": 65.78,
         "zs0.25": 60.5,
         "zs0.5": 55.81,
         "zs0.75": 51.62,
         "zs1": 47.88,
         "zs1.25": 44.52,
         "zs1.5": 41.5,
         "zs1.75": 38.79,
         "zs2": 36.34,
         "zs2.25": 34.12,
         "zs2.5": 32.11,
         "zs2.75": 30.29,
         "zs3": 28.63,
         "zs3.25": 27.11,
         "zs3.5": 25.73,
         "zs3.75": 24.46,
         "zs4": 23.29,
         "zs4.25": 22.22,
         "zs4.5": 21.23,
         "zs4.75": 20.32,
         "zs5": 19.48
        },
        {
         "zs0": 64.8,
         "zs0.25": 59.68,
         "zs0.5": 55.11,
         "zs0.75": 51.03,
         "zs1": 47.37,
         "zs1.25": 44.09,
         "zs1.5": 41.13,
         "zs1.75": 38.47,
         "zs2": 36.07,
         "zs2.25": 33.89,
         "zs2.5": 31.91,
         "zs2.75": 30.12,
         "zs3": 28.48,
         "zs3.25": 26.99,
         "zs3.5": 25.62,
         "zs3.75": 24.36,
         "zs4": 23.21,
         "zs4.25": 22.15,
         "zs4.5": 21.17,
         "zs4.75": 20.27,
         "zs5": 19.43
        },
        {
         "zs0": 63.83,
         "zs0.25": 58.85,
         "zs0.5": 54.4,
         "zs0.75": 50.43,
         "zs1": 46.86,
         "zs1.25": 43.65,
         "zs1.5": 40.76,
         "zs1.75": 38.15,
         "zs2": 35.79,
         "zs2.25": 33.65,
         "zs2.5": 31.71,
         "zs2.75": 29.94,
         "zs3": 28.33,
         "zs3.25": 26.86,
         "zs3.5": 25.51,
         "zs3.75": 24.27,
         "zs4": 23.13,
         "zs4.25": 22.08,
         "zs4.5": 21.11,
         "zs4.75": 20.21,
         "zs5": 19.38
        },
        {
         "zs0": 62.86,
         "zs0.25": 58.02,
         "zs0.5": 53.7,
         "zs0.75": 49.82,
         "zs1": 46.34,
         "zs1.25": 43.21,
         "zs1.5": 40.38,
         "zs1.75": 37.83,
         "zs2": 35.51,
         "zs2.25": 33.41,
         "zs2.5": 31.5,
         "zs2.75": 29.76,
         "zs3": 28.18,
         "zs3.25": 26.72,
         "zs3.5": 25.39,
         "zs3.75": 24.17,
         "zs4": 23.04,
         "zs4.25": 22,
         "zs4.5": 21.04,
         "zs4.75": 20.16,
         "zs5": 19.33
        },
        {
         "zs0": 61.88,
         "zs0.25": 57.19,
         "zs0.5": 52.98,
         "zs0.75": 49.21,
         "zs1": 45.81,
         "zs1.25": 42.75,
         "zs1.5": 39.99,
         "zs1.75": 37.49,
         "zs2": 35.23,
         "zs2.25": 33.17,
         "zs2.5": 31.29,
         "zs2.75": 29.58,
         "zs3": 28.02,
         "zs3.25": 26.58,
         "zs3.5": 25.27,
         "zs3.75": 24.06,
         "zs4": 22.95,
         "zs4.25": 21.92,
         "zs4.5": 20.97,
         "zs4.75": 20.09,
         "zs5": 19.28
        },
        {
         "zs0": 60.9,
         "zs0.25": 56.35,
         "zs0.5": 52.26,
         "zs0.75": 48.59,
         "zs1": 45.28,
         "zs1.25": 42.3,
         "zs1.5": 39.6,
         "zs1.75": 37.15,
         "zs2": 34.93,
         "zs2.25": 32.91,
         "zs2.5": 31.07,
         "zs2.75": 29.39,
         "zs3": 27.85,
         "zs3.25": 26.44,
         "zs3.5": 25.14,
         "zs3.75": 23.95,
         "zs4": 22.85,
         "zs4.25": 21.84,
         "zs4.5": 20.9,
         "zs4.75": 20.03,
         "zs5": 19.22
        },
        {
         "zs0": 59.92,
         "zs0.25": 55.5,
         "zs0.5": 51.54,
         "zs0.75": 47.96,
         "zs1": 44.74,
         "zs1.25": 41.83,
         "zs1.5": 39.2,
         "zs1.75": 36.8,
         "zs2": 34.63,
         "zs2.25": 32.65,
         "zs2.5": 30.84,
         "zs2.75": 29.19,
         "zs3": 27.68,
         "zs3.25": 26.29,
         "zs3.5": 25.01,
         "zs3.75": 23.84,
         "zs4": 22.75,
         "zs4.25": 21.75,
         "zs4.5": 20.82,
         "zs4.75": 19.96,
         "zs5": 19.16
        },
        {
         "zs0": 58.94,
         "zs0.25": 54.66,
         "zs0.5": 50.81,
         "zs0.75": 47.33,
         "zs1": 44.2,
         "zs1.25": 41.36,
         "zs1.5": 38.79,
         "zs1.75": 36.45,
         "zs2": 34.32,
         "zs2.25": 32.38,
         "zs2.5": 30.61,
         "zs2.75": 28.99,
         "zs3": 27.5,
         "zs3.25": 26.13,
         "zs3.5": 24.88,
         "zs3.75": 23.72,
         "zs4": 22.65,
         "zs4.25": 21.66,
         "zs4.5": 20.74,
         "zs4.75": 19.89,
         "zs5": 19.1
        },
        {
         "zs0": 57.95,
         "zs0.25": 53.81,
         "zs0.5": 50.07,
         "zs0.75": 46.7,
         "zs1": 43.65,
         "zs1.25": 40.88,
         "zs1.5": 38.37,
         "zs1.75": 36.09,
         "zs2": 34.01,
         "zs2.25": 32.11,
         "zs2.5": 30.37,
         "zs2.75": 28.78,
         "zs3": 27.32,
         "zs3.25": 25.97,
         "zs3.5": 24.74,
         "zs3.75": 23.59,
         "zs4": 22.54,
         "zs4.25": 21.56,
         "zs4.5": 20.66,
         "zs4.75": 19.82,
         "zs5": 19.03
        },
        {
         "zs0": 56.97,
         "zs0.25": 52.96,
         "zs0.5": 49.33,
         "zs0.75": 46.06,
         "zs1": 43.09,
         "zs1.25": 40.4,
         "zs1.5": 37.95,
         "zs1.75": 35.72,
         "zs2": 33.69,
         "zs2.25": 31.83,
         "zs2.5": 30.12,
         "zs2.75": 28.56,
         "zs3": 27.13,
         "zs3.25": 25.81,
         "zs3.5": 24.59,
         "zs3.75": 23.47,
         "zs4": 22.43,
         "zs4.25": 21.46,
         "zs4.5": 20.57,
         "zs4.75": 19.74,
         "zs5": 18.96
        },
        {
         "zs0": 55.99,
         "zs0.25": 52.1,
         "zs0.5": 48.59,
         "zs0.75": 45.41,
         "zs1": 42.53,
         "zs1.25": 39.91,
         "zs1.5": 37.52,
         "zs1.75": 35.35,
         "zs2": 33.36,
         "zs2.25": 31.54,
         "zs2.5": 29.87,
         "zs2.75": 28.34,
         "zs3": 26.93,
         "zs3.25": 25.64,
         "zs3.5": 24.44,
         "zs3.75": 23.33,
         "zs4": 22.31,
         "zs4.25": 21.36,
         "zs4.5": 20.48,
         "zs4.75": 19.66,
         "zs5": 18.89
        },
        {
         "zs0": 55,
         "zs0.25": 51.25,
         "zs0.5": 47.85,
         "zs0.75": 44.76,
         "zs1": 41.96,
         "zs1.25": 39.41,
         "zs1.5": 37.09,
         "zs1.75": 34.97,
         "zs2": 33.03,
         "zs2.25": 31.25,
         "zs2.5": 29.61,
         "zs2.75": 28.11,
         "zs3": 26.73,
         "zs3.25": 25.46,
         "zs3.5": 24.28,
         "zs3.75": 23.19,
         "zs4": 22.19,
         "zs4.25": 21.25,
         "zs4.5": 20.38,
         "zs4.75": 19.57,
         "zs5": 18.81
        },
        {
         "zs0": 54.02,
         "zs0.25": 50.39,
         "zs0.5": 47.1,
         "zs0.75": 44.11,
         "zs1": 41.39,
         "zs1.25": 38.91,
         "zs1.5": 36.65,
         "zs1.75": 34.58,
         "zs2": 32.68,
         "zs2.25": 30.95,
         "zs2.5": 29.35,
         "zs2.75": 27.88,
         "zs3": 26.53,
         "zs3.25": 25.28,
         "zs3.5": 24.12,
         "zs3.75": 23.05,
         "zs4": 22.06,
         "zs4.25": 21.14,
         "zs4.5": 20.28,
         "zs4.75": 19.48,
         "zs5": 18.73
        },
        {
         "zs0": 53.04,
         "zs0.25": 49.53,
         "zs0.5": 46.35,
         "zs0.75": 43.45,
         "zs1": 40.81,
         "zs1.25": 38.4,
         "zs1.5": 36.2,
         "zs1.75": 34.19,
         "zs2": 32.34,
         "zs2.25": 30.64,
         "zs2.5": 29.08,
         "zs2.75": 27.64,
         "zs3": 26.32,
         "zs3.25": 25.09,
         "zs3.5": 23.95,
         "zs3.75": 22.9,
         "zs4": 21.93,
         "zs4.25": 21.02,
         "zs4.5": 20.17,
         "zs4.75": 19.38,
         "zs5": 18.65
        },
        {
         "zs0": 52.05,
         "zs0.25": 48.67,
         "zs0.5": 45.59,
         "zs0.75": 42.79,
         "zs1": 40.23,
         "zs1.25": 37.89,
         "zs1.5": 35.75,
         "zs1.75": 33.79,
         "zs2": 31.99,
         "zs2.25": 30.33,
         "zs2.5": 28.8,
         "zs2.75": 27.4,
         "zs3": 26.1,
         "zs3.25": 24.9,
         "zs3.5": 23.78,
         "zs3.75": 22.75,
         "zs4": 21.79,
         "zs4.25": 20.9,
         "zs4.5": 20.06,
         "zs4.75": 19.29,
         "zs5": 18.56
        },
        {
         "zs0": 51.07,
         "zs0.25": 47.81,
         "zs0.5": 44.83,
         "zs0.75": 42.12,
         "zs1": 39.64,
         "zs1.25": 37.37,
         "zs1.5": 35.29,
         "zs1.75": 33.38,
         "zs2": 31.63,
         "zs2.25": 30.01,
         "zs2.5": 28.52,
         "zs2.75": 27.15,
         "zs3": 25.87,
         "zs3.25": 24.7,
         "zs3.5": 23.6,
         "zs3.75": 22.59,
         "zs4": 21.65,
         "zs4.25": 20.77,
         "zs4.5": 19.95,
         "zs4.75": 19.18,
         "zs5": 18.47
        },
        {
         "zs0": 50.09,
         "zs0.25": 46.94,
         "zs0.5": 44.07,
         "zs0.75": 41.44,
         "zs1": 39.04,
         "zs1.25": 36.84,
         "zs1.5": 34.82,
         "zs1.75": 32.97,
         "zs2": 31.26,
         "zs2.25": 29.68,
         "zs2.5": 28.23,
         "zs2.75": 26.89,
         "zs3": 25.64,
         "zs3.25": 24.49,
         "zs3.5": 23.42,
         "zs3.75": 22.42,
         "zs4": 21.5,
         "zs4.25": 20.64,
         "zs4.5": 19.83,
         "zs4.75": 19.08,
         "zs5": 18.37
        },
        {
         "zs0": 49.11,
         "zs0.25": 46.08,
         "zs0.5": 43.31,
         "zs0.75": 40.77,
         "zs1": 38.44,
         "zs1.25": 36.31,
         "zs1.5": 34.35,
         "zs1.75": 32.55,
         "zs2": 30.89,
         "zs2.25": 29.35,
         "zs2.5": 27.93,
         "zs2.75": 26.62,
         "zs3": 25.41,
         "zs3.25": 24.28,
         "zs3.5": 23.23,
         "zs3.75": 22.25,
         "zs4": 21.35,
         "zs4.25": 20.5,
         "zs4.5": 19.71,
         "zs4.75": 18.97,
         "zs5": 18.27
        },
        {
         "zs0": 48.13,
         "zs0.25": 45.21,
         "zs0.5": 42.54,
         "zs0.75": 40.09,
         "zs1": 37.84,
         "zs1.25": 35.78,
         "zs1.5": 33.87,
         "zs1.75": 32.12,
         "zs2": 30.51,
         "zs2.25": 29.01,
         "zs2.5": 27.63,
         "zs2.75": 26.35,
         "zs3": 25.16,
         "zs3.25": 24.06,
         "zs3.5": 23.03,
         "zs3.75": 22.08,
         "zs4": 21.19,
         "zs4.25": 20.36,
         "zs4.5": 19.58,
         "zs4.75": 18.85,
         "zs5": 18.17
        },
        {
         "zs0": 47.16,
         "zs0.25": 44.34,
         "zs0.5": 41.77,
         "zs0.75": 39.4,
         "zs1": 37.23,
         "zs1.25": 35.23,
         "zs1.5": 33.39,
         "zs1.75": 31.69,
         "zs2": 30.12,
         "zs2.25": 28.67,
         "zs2.5": 27.32,
         "zs2.75": 26.07,
         "zs3": 24.92,
         "zs3.25": 23.84,
         "zs3.5": 22.83,
         "zs3.75": 21.9,
         "zs4": 21.02,
         "zs4.25": 20.21,
         "zs4.5": 19.44,
         "zs4.75": 18.73,
         "zs5": 18.06
        },
        {
         "zs0": 46.18,
         "zs0.25": 43.48,
         "zs0.5": 41,
         "zs0.75": 38.72,
         "zs1": 36.62,
         "zs1.25": 34.69,
         "zs1.5": 32.9,
         "zs1.75": 31.25,
         "zs2": 29.73,
         "zs2.25": 28.32,
         "zs2.5": 27.01,
         "zs2.75": 25.79,
         "zs3": 24.66,
         "zs3.25": 23.61,
         "zs3.5": 22.63,
         "zs3.75": 21.71,
         "zs4": 20.85,
         "zs4.25": 20.05,
         "zs4.5": 19.3,
         "zs4.75": 18.6,
         "zs5": 17.94
        },
        {
         "zs0": 45.21,
         "zs0.25": 42.61,
         "zs0.5": 40.22,
         "zs0.75": 38.03,
         "zs1": 36,
         "zs1.25": 34.13,
         "zs1.5": 32.41,
         "zs1.75": 30.81,
         "zs2": 29.33,
         "zs2.25": 27.96,
         "zs2.5": 26.68,
         "zs2.75": 25.5,
         "zs3": 24.4,
         "zs3.25": 23.37,
         "zs3.5": 22.41,
         "zs3.75": 21.52,
         "zs4": 20.68,
         "zs4.25": 19.9,
         "zs4.5": 19.16,
         "zs4.75": 18.47,
         "zs5": 17.82
        },
        {
         "zs0": 44.24,
         "zs0.25": 41.74,
         "zs0.5": 39.45,
         "zs0.75": 37.33,
         "zs1": 35.38,
         "zs1.25": 33.57,
         "zs1.5": 31.91,
         "zs1.75": 30.36,
         "zs2": 28.93,
         "zs2.25": 27.59,
         "zs2.5": 26.36,
         "zs2.75": 25.2,
         "zs3": 24.13,
         "zs3.25": 23.13,
         "zs3.5": 22.19,
         "zs3.75": 21.32,
         "zs4": 20.5,
         "zs4.25": 19.73,
         "zs4.5": 19.01,
         "zs4.75": 18.33,
         "zs5": 17.7
        },
        {
         "zs0": 43.27,
         "zs0.25": 40.88,
         "zs0.5": 38.67,
         "zs0.75": 36.63,
         "zs1": 34.75,
         "zs1.25": 33.01,
         "zs1.5": 31.4,
         "zs1.75": 29.9,
         "zs2": 28.51,
         "zs2.25": 27.22,
         "zs2.5": 26.02,
         "zs2.75": 24.9,
         "zs3": 23.86,
         "zs3.25": 22.88,
         "zs3.5": 21.97,
         "zs3.75": 21.11,
         "zs4": 20.31,
         "zs4.25": 19.56,
         "zs4.5": 18.86,
         "zs4.75": 18.19,
         "zs5": 17.57
        },
        {
         "zs0": 42.3,
         "zs0.25": 40.01,
         "zs0.5": 37.89,
         "zs0.75": 35.94,
         "zs1": 34.12,
         "zs1.25": 32.44,
         "zs1.5": 30.89,
         "zs1.75": 29.44,
         "zs2": 28.1,
         "zs2.25": 26.85,
         "zs2.5": 25.68,
         "zs2.75": 24.59,
         "zs3": 23.57,
         "zs3.25": 22.62,
         "zs3.5": 21.73,
         "zs3.75": 20.9,
         "zs4": 20.12,
         "zs4.25": 19.39,
         "zs4.5": 18.7,
         "zs4.75": 18.05,
         "zs5": 17.44
        },
        {
         "zs0": 41.34,
         "zs0.25": 39.14,
         "zs0.5": 37.11,
         "zs0.75": 35.23,
         "zs1": 33.49,
         "zs1.25": 31.87,
         "zs1.5": 30.37,
         "zs1.75": 28.97,
         "zs2": 27.67,
         "zs2.25": 26.46,
         "zs2.5": 25.33,
         "zs2.75": 24.27,
         "zs3": 23.29,
         "zs3.25": 22.36,
         "zs3.5": 21.5,
         "zs3.75": 20.68,
         "zs4": 19.92,
         "zs4.25": 19.2,
         "zs4.5": 18.53,
         "zs4.75": 17.89,
         "zs5": 17.3
        },
        {
         "zs0": 40.38,
         "zs0.25": 38.28,
         "zs0.5": 36.33,
         "zs0.75": 34.53,
         "zs1": 32.85,
         "zs1.25": 31.3,
         "zs1.5": 29.85,
         "zs1.75": 28.5,
         "zs2": 27.24,
         "zs2.25": 26.07,
         "zs2.5": 24.98,
         "zs2.75": 23.95,
         "zs3": 22.99,
         "zs3.25": 22.09,
         "zs3.5": 21.25,
         "zs3.75": 20.46,
         "zs4": 19.72,
         "zs4.25": 19.02,
         "zs4.5": 18.36,
         "zs4.75": 17.74,
         "zs5": 17.15
        },
        {
         "zs0": 39.42,
         "zs0.25": 37.41,
         "zs0.5": 35.55,
         "zs0.75": 33.82,
         "zs1": 32.21,
         "zs1.25": 30.71,
         "zs1.5": 29.32,
         "zs1.75": 28.02,
         "zs2": 26.81,
         "zs2.25": 25.67,
         "zs2.5": 24.61,
         "zs2.75": 23.62,
         "zs3": 22.69,
         "zs3.25": 21.82,
         "zs3.5": 21,
         "zs3.75": 20.23,
         "zs4": 19.51,
         "zs4.25": 18.82,
         "zs4.5": 18.18,
         "zs4.75": 17.57,
         "zs5": 17
        },
        {
         "zs0": 38.47,
         "zs0.25": 36.55,
         "zs0.5": 34.77,
         "zs0.75": 33.11,
         "zs1": 31.57,
         "zs1.25": 30.13,
         "zs1.5": 28.79,
         "zs1.75": 27.53,
         "zs2": 26.36,
         "zs2.25": 25.27,
         "zs2.5": 24.24,
         "zs2.75": 23.28,
         "zs3": 22.38,
         "zs3.25": 21.54,
         "zs3.5": 20.74,
         "zs3.75": 19.99,
         "zs4": 19.29,
         "zs4.25": 18.62,
         "zs4.5": 18,
         "zs4.75": 17.4,
         "zs5": 16.84
        },
        {
         "zs0": 37.52,
         "zs0.25": 35.69,
         "zs0.5": 33.98,
         "zs0.75": 32.4,
         "zs1": 30.92,
         "zs1.25": 29.54,
         "zs1.5": 28.25,
         "zs1.75": 27.04,
         "zs2": 25.92,
         "zs2.25": 24.86,
         "zs2.5": 23.87,
         "zs2.75": 22.94,
         "zs3": 22.07,
         "zs3.25": 21.25,
         "zs3.5": 20.48,
         "zs3.75": 19.75,
         "zs4": 19.06,
         "zs4.25": 18.42,
         "zs4.5": 17.81,
         "zs4.75": 17.23,
         "zs5": 16.68
        },
        {
         "zs0": 36.57,
         "zs0.25": 34.83,
         "zs0.5": 33.2,
         "zs0.75": 31.68,
         "zs1": 30.27,
         "zs1.25": 28.94,
         "zs1.5": 27.7,
         "zs1.75": 26.55,
         "zs2": 25.46,
         "zs2.25": 24.44,
         "zs2.5": 23.49,
         "zs2.75": 22.59,
         "zs3": 21.75,
         "zs3.25": 20.95,
         "zs3.5": 20.2,
         "zs3.75": 19.5,
         "zs4": 18.83,
         "zs4.25": 18.2,
         "zs4.5": 17.61,
         "zs4.75": 17.05,
         "zs5": 16.51
        },
        {
         "zs0": 35.63,
         "zs0.25": 33.97,
         "zs0.5": 32.41,
         "zs0.75": 30.96,
         "zs1": 29.61,
         "zs1.25": 28.34,
         "zs1.5": 27.15,
         "zs1.75": 26.04,
         "zs2": 25,
         "zs2.25": 24.02,
         "zs2.5": 23.1,
         "zs2.75": 22.23,
         "zs3": 21.42,
         "zs3.25": 20.65,
         "zs3.5": 19.92,
         "zs3.75": 19.24,
         "zs4": 18.59,
         "zs4.25": 17.98,
         "zs4.5": 17.4,
         "zs4.75": 16.86,
         "zs5": 16.34
        },
        {
         "zs0": 34.69,
         "zs0.25": 33.11,
         "zs0.5": 31.63,
         "zs0.75": 30.24,
         "zs1": 28.95,
         "zs1.25": 27.74,
         "zs1.5": 26.6,
         "zs1.75": 25.53,
         "zs2": 24.53,
         "zs2.25": 23.59,
         "zs2.5": 22.7,
         "zs2.75": 21.87,
         "zs3": 21.08,
         "zs3.25": 20.34,
         "zs3.5": 19.63,
         "zs3.75": 18.97,
         "zs4": 18.35,
         "zs4.25": 17.75,
         "zs4.5": 17.19,
         "zs4.75": 16.66,
         "zs5": 16.16
        },
        {
         "zs0": 33.75,
         "zs0.25": 32.25,
         "zs0.5": 30.84,
         "zs0.75": 29.52,
         "zs1": 28.29,
         "zs1.25": 27.13,
         "zs1.5": 26.04,
         "zs1.75": 25.02,
         "zs2": 24.05,
         "zs2.25": 23.15,
         "zs2.5": 22.3,
         "zs2.75": 21.49,
         "zs3": 20.73,
         "zs3.25": 20.02,
         "zs3.5": 19.34,
         "zs3.75": 18.7,
         "zs4": 18.09,
         "zs4.25": 17.52,
         "zs4.5": 16.97,
         "zs4.75": 16.46,
         "zs5": 15.97
        },
        {
         "zs0": 32.82,
         "zs0.25": 31.39,
         "zs0.5": 30.05,
         "zs0.75": 28.8,
         "zs1": 27.62,
         "zs1.25": 26.51,
         "zs1.5": 25.47,
         "zs1.75": 24.49,
         "zs2": 23.57,
         "zs2.25": 22.7,
         "zs2.5": 21.88,
         "zs2.75": 21.11,
         "zs3": 20.38,
         "zs3.25": 19.69,
         "zs3.5": 19.04,
         "zs3.75": 18.42,
         "zs4": 17.83,
         "zs4.25": 17.28,
         "zs4.5": 16.75,
         "zs4.75": 16.25,
         "zs5": 15.77
        },
        {
         "zs0": 31.89,
         "zs0.25": 30.54,
         "zs0.5": 29.27,
         "zs0.75": 28.07,
         "zs1": 26.95,
         "zs1.25": 25.9,
         "zs1.5": 24.9,
         "zs1.75": 23.97,
         "zs2": 23.08,
         "zs2.25": 22.25,
         "zs2.5": 21.47,
         "zs2.75": 20.72,
         "zs3": 20.02,
         "zs3.25": 19.36,
         "zs3.5": 18.73,
         "zs3.75": 18.13,
         "zs4": 17.56,
         "zs4.25": 17.02,
         "zs4.5": 16.51,
         "zs4.75": 16.03,
         "zs5": 15.57
        },
        {
         "zs0": 30.96,
         "zs0.25": 29.68,
         "zs0.5": 28.48,
         "zs0.75": 27.34,
         "zs1": 26.28,
         "zs1.25": 25.27,
         "zs1.5": 24.33,
         "zs1.75": 23.43,
         "zs2": 22.59,
         "zs2.25": 21.79,
         "zs2.5": 21.04,
         "zs2.75": 20.33,
         "zs3": 19.65,
         "zs3.25": 19.01,
         "zs3.5": 18.41,
         "zs3.75": 17.83,
         "zs4": 17.29,
         "zs4.25": 16.77,
         "zs4.5": 16.27,
         "zs4.75": 15.8,
         "zs5": 15.36
        },
        {
         "zs0": 30.04,
         "zs0.25": 28.83,
         "zs0.5": 27.69,
         "zs0.75": 26.62,
         "zs1": 25.6,
         "zs1.25": 24.65,
         "zs1.5": 23.75,
         "zs1.75": 22.89,
         "zs2": 22.09,
         "zs2.25": 21.33,
         "zs2.5": 20.61,
         "zs2.75": 19.92,
         "zs3": 19.28,
         "zs3.25": 18.66,
         "zs3.5": 18.08,
         "zs3.75": 17.53,
         "zs4": 17,
         "zs4.25": 16.5,
         "zs4.5": 16.02,
         "zs4.75": 15.57,
         "zs5": 15.14
        },
        {
         "zs0": 29.13,
         "zs0.25": 27.98,
         "zs0.5": 26.9,
         "zs0.75": 25.89,
         "zs1": 24.93,
         "zs1.25": 24.02,
         "zs1.5": 23.16,
         "zs1.75": 22.35,
         "zs2": 21.58,
         "zs2.25": 20.86,
         "zs2.5": 20.17,
         "zs2.75": 19.51,
         "zs3": 18.89,
         "zs3.25": 18.31,
         "zs3.5": 17.75,
         "zs3.75": 17.21,
         "zs4": 16.71,
         "zs4.25": 16.23,
         "zs4.5": 15.77,
         "zs4.75": 15.33,
         "zs5": 14.91
        },
        {
         "zs0": 28.22,
         "zs0.25": 27.14,
         "zs0.5": 26.12,
         "zs0.75": 25.16,
         "zs1": 24.25,
         "zs1.25": 23.39,
         "zs1.5": 22.57,
         "zs1.75": 21.8,
         "zs2": 21.07,
         "zs2.25": 20.38,
         "zs2.5": 19.72,
         "zs2.75": 19.1,
         "zs3": 18.5,
         "zs3.25": 17.94,
         "zs3.5": 17.4,
         "zs3.75": 16.89,
         "zs4": 16.41,
         "zs4.25": 15.94,
         "zs4.5": 15.5,
         "zs4.75": 15.08,
         "zs5": 14.68
        },
        {
         "zs0": 27.32,
         "zs0.25": 26.3,
         "zs0.5": 25.34,
         "zs0.75": 24.43,
         "zs1": 23.57,
         "zs1.25": 22.75,
         "zs1.5": 21.98,
         "zs1.75": 21.25,
         "zs2": 20.56,
         "zs2.25": 19.9,
         "zs2.5": 19.27,
         "zs2.75": 18.67,
         "zs3": 18.11,
         "zs3.25": 17.57,
         "zs3.5": 17.06,
         "zs3.75": 16.57,
         "zs4": 16.1,
         "zs4.25": 15.65,
         "zs4.5": 15.23,
         "zs4.75": 14.82,
         "zs5": 14.44
        },
        {
         "zs0": 26.42,
         "zs0.25": 25.46,
         "zs0.5": 24.56,
         "zs0.75": 23.7,
         "zs1": 22.89,
         "zs1.25": 22.12,
         "zs1.5": 21.39,
         "zs1.75": 20.69,
         "zs2": 20.03,
         "zs2.25": 19.41,
         "zs2.5": 18.81,
         "zs2.75": 18.24,
         "zs3": 17.7,
         "zs3.25": 17.19,
         "zs3.5": 16.7,
         "zs3.75": 16.23,
         "zs4": 15.78,
         "zs4.25": 15.36,
         "zs4.5": 14.95,
         "zs4.75": 14.56,
         "zs5": 14.19
        },
        {
         "zs0": 25.53,
         "zs0.25": 24.63,
         "zs0.5": 23.78,
         "zs0.75": 22.97,
         "zs1": 22.21,
         "zs1.25": 21.48,
         "zs1.5": 20.79,
         "zs1.75": 20.13,
         "zs2": 19.51,
         "zs2.25": 18.91,
         "zs2.5": 18.35,
         "zs2.75": 17.81,
         "zs3": 17.29,
         "zs3.25": 16.8,
         "zs3.5": 16.34,
         "zs3.75": 15.89,
         "zs4": 15.46,
         "zs4.25": 15.05,
         "zs4.5": 14.66,
         "zs4.75": 14.29,
         "zs5": 13.93
        },
        {
         "zs0": 24.65,
         "zs0.25": 23.81,
         "zs0.5": 23.01,
         "zs0.75": 22.25,
         "zs1": 21.53,
         "zs1.25": 20.84,
         "zs1.5": 20.19,
         "zs1.75": 19.57,
         "zs2": 18.98,
         "zs2.25": 18.42,
         "zs2.5": 17.88,
         "zs2.75": 17.37,
         "zs3": 16.88,
         "zs3.25": 16.41,
         "zs3.5": 15.97,
         "zs3.75": 15.54,
         "zs4": 15.13,
         "zs4.25": 14.74,
         "zs4.5": 14.37,
         "zs4.75": 14.01,
         "zs5": 13.67
        },
        {
         "zs0": 23.77,
         "zs0.25": 22.99,
         "zs0.5": 22.24,
         "zs0.75": 21.53,
         "zs1": 20.85,
         "zs1.25": 20.2,
         "zs1.5": 19.59,
         "zs1.75": 19.01,
         "zs2": 18.45,
         "zs2.25": 17.91,
         "zs2.5": 17.41,
         "zs2.75": 16.92,
         "zs3": 16.46,
         "zs3.25": 16.01,
         "zs3.5": 15.59,
         "zs3.75": 15.18,
         "zs4": 14.79,
         "zs4.25": 14.42,
         "zs4.5": 14.07,
         "zs4.75": 13.72,
         "zs5": 13.39
        },
        {
         "zs0": 22.91,
         "zs0.25": 22.17,
         "zs0.5": 21.47,
         "zs0.75": 20.81,
         "zs1": 20.17,
         "zs1.25": 19.57,
         "zs1.5": 18.99,
         "zs1.75": 18.44,
         "zs2": 17.91,
         "zs2.25": 17.41,
         "zs2.5": 16.93,
         "zs2.75": 16.47,
         "zs3": 16.03,
         "zs3.25": 15.61,
         "zs3.5": 15.21,
         "zs3.75": 14.82,
         "zs4": 14.45,
         "zs4.25": 14.1,
         "zs4.5": 13.76,
         "zs4.75": 13.43,
         "zs5": 13.12
        },
        {
         "zs0": 22.05,
         "zs0.25": 21.37,
         "zs0.5": 20.71,
         "zs0.75": 20.09,
         "zs1": 19.49,
         "zs1.25": 18.93,
         "zs1.5": 18.38,
         "zs1.75": 17.87,
         "zs2": 17.37,
         "zs2.25": 16.9,
         "zs2.5": 16.45,
         "zs2.75": 16.01,
         "zs3": 15.6,
         "zs3.25": 15.2,
         "zs3.5": 14.82,
         "zs3.75": 14.45,
         "zs4": 14.1,
         "zs4.25": 13.76,
         "zs4.5": 13.44,
         "zs4.75": 13.13,
         "zs5": 12.83
        },
        {
         "zs0": 21.2,
         "zs0.25": 20.57,
         "zs0.5": 19.96,
         "zs0.75": 19.38,
         "zs1": 18.82,
         "zs1.25": 18.29,
         "zs1.5": 17.78,
         "zs1.75": 17.3,
         "zs2": 16.83,
         "zs2.25": 16.39,
         "zs2.5": 15.96,
         "zs2.75": 15.55,
         "zs3": 15.16,
         "zs3.25": 14.78,
         "zs3.5": 14.42,
         "zs3.75": 14.08,
         "zs4": 13.74,
         "zs4.25": 13.42,
         "zs4.5": 13.12,
         "zs4.75": 12.82,
         "zs5": 12.54
        },
        {
         "zs0": 20.37,
         "zs0.25": 19.78,
         "zs0.5": 19.21,
         "zs0.75": 18.67,
         "zs1": 18.15,
         "zs1.25": 17.65,
         "zs1.5": 17.18,
         "zs1.75": 16.72,
         "zs2": 16.29,
         "zs2.25": 15.87,
         "zs2.5": 15.47,
         "zs2.75": 15.09,
         "zs3": 14.72,
         "zs3.25": 14.36,
         "zs3.5": 14.02,
         "zs3.75": 13.69,
         "zs4": 13.38,
         "zs4.25": 13.08,
         "zs4.5": 12.79,
         "zs4.75": 12.5,
         "zs5": 12.23
        },
        {
         "zs0": 19.54,
         "zs0.25": 18.99,
         "zs0.5": 18.47,
         "zs0.75": 17.96,
         "zs1": 17.48,
         "zs1.25": 17.02,
         "zs1.5": 16.58,
         "zs1.75": 16.15,
         "zs2": 15.75,
         "zs2.25": 15.35,
         "zs2.5": 14.98,
         "zs2.75": 14.62,
         "zs3": 14.27,
         "zs3.25": 13.94,
         "zs3.5": 13.62,
         "zs3.75": 13.31,
         "zs4": 13.01,
         "zs4.25": 12.72,
         "zs4.5": 12.45,
         "zs4.75": 12.18,
         "zs5": 11.93
        },
        {
         "zs0": 18.73,
         "zs0.25": 18.22,
         "zs0.5": 17.73,
         "zs0.75": 17.27,
         "zs1": 16.82,
         "zs1.25": 16.39,
         "zs1.5": 15.98,
         "zs1.75": 15.58,
         "zs2": 15.2,
         "zs2.25": 14.84,
         "zs2.5": 14.48,
         "zs2.75": 14.15,
         "zs3": 13.82,
         "zs3.25": 13.51,
         "zs3.5": 13.21,
         "zs3.75": 12.92,
         "zs4": 12.64,
         "zs4.25": 12.37,
         "zs4.5": 12.11,
         "zs4.75": 11.86,
         "zs5": 11.61
        },
        {
         "zs0": 17.93,
         "zs0.25": 17.46,
         "zs0.5": 17.01,
         "zs0.75": 16.58,
         "zs1": 16.16,
         "zs1.25": 15.76,
         "zs1.5": 15.38,
         "zs1.75": 15.01,
         "zs2": 14.66,
         "zs2.25": 14.32,
         "zs2.5": 13.99,
         "zs2.75": 13.67,
         "zs3": 13.37,
         "zs3.25": 13.08,
         "zs3.5": 12.79,
         "zs3.75": 12.52,
         "zs4": 12.26,
         "zs4.25": 12,
         "zs4.5": 11.76,
         "zs4.75": 11.52,
         "zs5": 11.29
        },
        {
         "zs0": 17.14,
         "zs0.25": 16.71,
         "zs0.5": 16.29,
         "zs0.75": 15.89,
         "zs1": 15.51,
         "zs1.25": 15.14,
         "zs1.5": 14.78,
         "zs1.75": 14.44,
         "zs2": 14.11,
         "zs2.25": 13.8,
         "zs2.5": 13.49,
         "zs2.75": 13.2,
         "zs3": 12.91,
         "zs3.25": 12.64,
         "zs3.5": 12.37,
         "zs3.75": 12.12,
         "zs4": 11.87,
         "zs4.25": 11.64,
         "zs4.5": 11.41,
         "zs4.75": 11.18,
         "zs5": 10.97
        },
        {
         "zs0": 16.36,
         "zs0.25": 15.96,
         "zs0.5": 15.58,
         "zs0.75": 15.22,
         "zs1": 14.86,
         "zs1.25": 14.52,
         "zs1.5": 14.19,
         "zs1.75": 13.88,
         "zs2": 13.57,
         "zs2.25": 13.28,
         "zs2.5": 12.99,
         "zs2.75": 12.72,
         "zs3": 12.46,
         "zs3.25": 12.2,
         "zs3.5": 11.95,
         "zs3.75": 11.72,
         "zs4": 11.49,
         "zs4.25": 11.26,
         "zs4.5": 11.05,
         "zs4.75": 10.84,
         "zs5": 10.64
        },
        {
         "zs0": 15.6,
         "zs0.25": 15.24,
         "zs0.5": 14.89,
         "zs0.75": 14.55,
         "zs1": 14.22,
         "zs1.25": 13.91,
         "zs1.5": 13.61,
         "zs1.75": 13.31,
         "zs2": 13.03,
         "zs2.25": 12.76,
         "zs2.5": 12.5,
         "zs2.75": 12.24,
         "zs3": 12,
         "zs3.25": 11.76,
         "zs3.5": 11.53,
         "zs3.75": 11.31,
         "zs4": 11.09,
         "zs4.25": 10.89,
         "zs4.5": 10.69,
         "zs4.75": 10.49,
         "zs5": 10.3
        },
        {
         "zs0": 14.85,
         "zs0.25": 14.52,
         "zs0.5": 14.2,
         "zs0.75": 13.89,
         "zs1": 13.59,
         "zs1.25": 13.3,
         "zs1.5": 13.02,
         "zs1.75": 12.76,
         "zs2": 12.5,
         "zs2.25": 12.24,
         "zs2.5": 12,
         "zs2.75": 11.77,
         "zs3": 11.54,
         "zs3.25": 11.32,
         "zs3.5": 11.11,
         "zs3.75": 10.9,
         "zs4": 10.7,
         "zs4.25": 10.51,
         "zs4.5": 10.32,
         "zs4.75": 10.14,
         "zs5": 9.96
        },
        {
         "zs0": 14.12,
         "zs0.25": 13.82,
         "zs0.5": 13.52,
         "zs0.75": 13.24,
         "zs1": 12.97,
         "zs1.25": 12.7,
         "zs1.5": 12.45,
         "zs1.75": 12.2,
         "zs2": 11.96,
         "zs2.25": 11.73,
         "zs2.5": 11.51,
         "zs2.75": 11.29,
         "zs3": 11.08,
         "zs3.25": 10.88,
         "zs3.5": 10.68,
         "zs3.75": 10.49,
         "zs4": 10.3,
         "zs4.25": 10.12,
         "zs4.5": 9.95,
         "zs4.75": 9.78,
         "zs5": 9.62
        },
        {
         "zs0": 13.4,
         "zs0.25": 13.13,
         "zs0.5": 12.86,
         "zs0.75": 12.6,
         "zs1": 12.35,
         "zs1.25": 12.11,
         "zs1.5": 11.88,
         "zs1.75": 11.65,
         "zs2": 11.43,
         "zs2.25": 11.22,
         "zs2.5": 11.02,
         "zs2.75": 10.82,
         "zs3": 10.62,
         "zs3.25": 10.44,
         "zs3.5": 10.25,
         "zs3.75": 10.08,
         "zs4": 9.91,
         "zs4.25": 9.74,
         "zs4.5": 9.58,
         "zs4.75": 9.42,
         "zs5": 9.27
        },
        {
         "zs0": 12.7,
         "zs0.25": 12.45,
         "zs0.5": 12.21,
         "zs0.75": 11.98,
         "zs1": 11.75,
         "zs1.25": 11.53,
         "zs1.5": 11.32,
         "zs1.75": 11.11,
         "zs2": 10.91,
         "zs2.25": 10.72,
         "zs2.5": 10.53,
         "zs2.75": 10.35,
         "zs3": 10.17,
         "zs3.25": 10,
         "zs3.5": 9.83,
         "zs3.75": 9.67,
         "zs4": 9.51,
         "zs4.25": 9.35,
         "zs4.5": 9.21,
         "zs4.75": 9.06,
         "zs5": 8.92
        },
        {
         "zs0": 12.02,
         "zs0.25": 11.79,
         "zs0.5": 11.57,
         "zs0.75": 11.36,
         "zs1": 11.16,
         "zs1.25": 10.96,
         "zs1.5": 10.76,
         "zs1.75": 10.58,
         "zs2": 10.39,
         "zs2.25": 10.22,
         "zs2.5": 10.04,
         "zs2.75": 9.88,
         "zs3": 9.72,
         "zs3.25": 9.56,
         "zs3.5": 9.4,
         "zs3.75": 9.25,
         "zs4": 9.11,
         "zs4.25": 8.97,
         "zs4.5": 8.83,
         "zs4.75": 8.7,
         "zs5": 8.57
        },
        {
         "zs0": 11.35,
         "zs0.25": 11.15,
         "zs0.5": 10.95,
         "zs0.75": 10.76,
         "zs1": 10.57,
         "zs1.25": 10.39,
         "zs1.5": 10.22,
         "zs1.75": 10.05,
         "zs2": 9.88,
         "zs2.25": 9.72,
         "zs2.5": 9.57,
         "zs2.75": 9.41,
         "zs3": 9.27,
         "zs3.25": 9.12,
         "zs3.5": 8.98,
         "zs3.75": 8.84,
         "zs4": 8.71,
         "zs4.25": 8.58,
         "zs4.5": 8.46,
         "zs4.75": 8.33,
         "zs5": 8.21
        },
        {
         "zs0": 10.7,
         "zs0.25": 10.52,
         "zs0.5": 10.34,
         "zs0.75": 10.17,
         "zs1": 10,
         "zs1.25": 9.84,
         "zs1.5": 9.68,
         "zs1.75": 9.53,
         "zs2": 9.38,
         "zs2.25": 9.23,
         "zs2.5": 9.09,
         "zs2.75": 8.95,
         "zs3": 8.82,
         "zs3.25": 8.69,
         "zs3.5": 8.56,
         "zs3.75": 8.44,
         "zs4": 8.31,
         "zs4.25": 8.2,
         "zs4.5": 8.08,
         "zs4.75": 7.97,
         "zs5": 7.86
        },
        {
         "zs0": 10.07,
         "zs0.25": 9.91,
         "zs0.5": 9.75,
         "zs0.75": 9.6,
         "zs1": 9.45,
         "zs1.25": 9.3,
         "zs1.5": 9.16,
         "zs1.75": 9.02,
         "zs2": 8.89,
         "zs2.25": 8.75,
         "zs2.5": 8.63,
         "zs2.75": 8.5,
         "zs3": 8.38,
         "zs3.25": 8.26,
         "zs3.5": 8.14,
         "zs3.75": 8.03,
         "zs4": 7.92,
         "zs4.25": 7.8100000000000005,
         "zs4.5": 7.71,
         "zs4.75": 7.61,
         "zs5": 7.51
        },
        {
         "zs0": 9.46,
         "zs0.25": 9.32,
         "zs0.5": 9.18,
         "zs0.75": 9.04,
         "zs1": 8.9,
         "zs1.25": 8.77,
         "zs1.5": 8.65,
         "zs1.75": 8.52,
         "zs2": 8.4,
         "zs2.25": 8.28,
         "zs2.5": 8.17,
         "zs2.75": 8.05,
         "zs3": 7.9399999999999995,
         "zs3.25": 7.84,
         "zs3.5": 7.73,
         "zs3.75": 7.63,
         "zs4": 7.53,
         "zs4.25": 7.43,
         "zs4.5": 7.34,
         "zs4.75": 7.24,
         "zs5": 7.15
        },
        {
         "zs0": 8.87,
         "zs0.25": 8.74,
         "zs0.5": 8.62,
         "zs0.75": 8.49,
         "zs1": 8.38,
         "zs1.25": 8.26,
         "zs1.5": 8.14,
         "zs1.75": 8.03,
         "zs2": 7.92,
         "zs2.25": 7.82,
         "zs2.5": 7.71,
         "zs2.75": 7.61,
         "zs3": 7.51,
         "zs3.25": 7.42,
         "zs3.5": 7.32,
         "zs3.75": 7.23,
         "zs4": 7.14,
         "zs4.25": 7.05,
         "zs4.5": 6.97,
         "zs4.75": 6.88,
         "zs5": 6.8
        },
        {
         "zs0": 8.3,
         "zs0.25": 8.19,
         "zs0.5": 8.08,
         "zs0.75": 7.97,
         "zs1": 7.86,
         "zs1.25": 7.76,
         "zs1.5": 7.66,
         "zs1.75": 7.5600000000000005,
         "zs2": 7.46,
         "zs2.25": 7.37,
         "zs2.5": 7.27,
         "zs2.75": 7.18,
         "zs3": 7.09,
         "zs3.25": 7.01,
         "zs3.5": 6.92,
         "zs3.75": 6.84,
         "zs4": 6.76,
         "zs4.25": 6.68,
         "zs4.5": 6.6,
         "zs4.75": 6.53,
         "zs5": 6.45
        },
        {
         "zs0": 7.75,
         "zs0.25": 7.65,
         "zs0.5": 7.55,
         "zs0.75": 7.46,
         "zs1": 7.36,
         "zs1.25": 7.27,
         "zs1.5": 7.18,
         "zs1.75": 7.09,
         "zs2": 7.01,
         "zs2.25": 6.92,
         "zs2.5": 6.84,
         "zs2.75": 6.76,
         "zs3": 6.68,
         "zs3.25": 6.6,
         "zs3.5": 6.53,
         "zs3.75": 6.45,
         "zs4": 6.38,
         "zs4.25": 6.31,
         "zs4.5": 6.24,
         "zs4.75": 6.17,
         "zs5": 6.11
        },
        {
         "zs0": 7.22,
         "zs0.25": 7.13,
         "zs0.5": 7.05,
         "zs0.75": 6.96,
         "zs1": 6.88,
         "zs1.25": 6.8,
         "zs1.5": 6.72,
         "zs1.75": 6.64,
         "zs2": 6.57,
         "zs2.25": 6.49,
         "zs2.5": 6.42,
         "zs2.75": 6.35,
         "zs3": 6.28,
         "zs3.25": 6.21,
         "zs3.5": 6.14,
         "zs3.75": 6.08,
         "zs4": 6.01,
         "zs4.25": 5.95,
         "zs4.5": 5.89,
         "zs4.75": 5.83,
         "zs5": 5.77
        },
        {
         "zs0": 6.71,
         "zs0.25": 6.63,
         "zs0.5": 6.5600000000000005,
         "zs0.75": 6.49,
         "zs1": 6.41,
         "zs1.25": 6.34,
         "zs1.5": 6.27,
         "zs1.75": 6.2,
         "zs2": 6.14,
         "zs2.25": 6.07,
         "zs2.5": 6.01,
         "zs2.75": 5.95,
         "zs3": 5.88,
         "zs3.25": 5.82,
         "zs3.5": 5.76,
         "zs3.75": 5.71,
         "zs4": 5.65,
         "zs4.25": 5.59,
         "zs4.5": 5.54,
         "zs4.75": 5.48,
         "zs5": 5.43
        },
        {
         "zs0": 6.22,
         "zs0.25": 6.16,
         "zs0.5": 6.09,
         "zs0.75": 6.03,
         "zs1": 5.96,
         "zs1.25": 5.9,
         "zs1.5": 5.84,
         "zs1.75": 5.78,
         "zs2": 5.72,
         "zs2.25": 5.67,
         "zs2.5": 5.61,
         "zs2.75": 5.55,
         "zs3": 5.5,
         "zs3.25": 5.45,
         "zs3.5": 5.4,
         "zs3.75": 5.34,
         "zs4": 5.29,
         "zs4.25": 5.24,
         "zs4.5": 5.2,
         "zs4.75": 5.15,
         "zs5": 5.1
        },
        {
         "zs0": 5.76,
         "zs0.25": 5.7,
         "zs0.5": 5.64,
         "zs0.75": 5.59,
         "zs1": 5.53,
         "zs1.25": 5.48,
         "zs1.5": 5.43,
         "zs1.75": 5.37,
         "zs2": 5.32,
         "zs2.25": 5.27,
         "zs2.5": 5.22,
         "zs2.75": 5.18,
         "zs3": 5.13,
         "zs3.25": 5.08,
         "zs3.5": 5.04,
         "zs3.75": 4.99,
         "zs4": 4.95,
         "zs4.25": 4.9,
         "zs4.5": 4.86,
         "zs4.75": 4.82,
         "zs5": 4.78
        },
        {
         "zs0": 5.31,
         "zs0.25": 5.26,
         "zs0.5": 5.21,
         "zs0.75": 5.16,
         "zs1": 5.12,
         "zs1.25": 5.07,
         "zs1.5": 5.03,
         "zs1.75": 4.98,
         "zs2": 4.94,
         "zs2.25": 4.89,
         "zs2.5": 4.85,
         "zs2.75": 4.81,
         "zs3": 4.77,
         "zs3.25": 4.73,
         "zs3.5": 4.69,
         "zs3.75": 4.65,
         "zs4": 4.61,
         "zs4.25": 4.57,
         "zs4.5": 4.54,
         "zs4.75": 4.5,
         "zs5": 4.46
        },
        {
         "zs0": 4.89,
         "zs0.25": 4.84,
         "zs0.5": 4.8,
         "zs0.75": 4.76,
         "zs1": 4.72,
         "zs1.25": 4.68,
         "zs1.5": 4.64,
         "zs1.75": 4.6,
         "zs2": 4.57,
         "zs2.25": 4.53,
         "zs2.5": 4.49,
         "zs2.75": 4.46,
         "zs3": 4.42,
         "zs3.25": 4.39,
         "zs3.5": 4.35,
         "zs3.75": 4.32,
         "zs4": 4.28,
         "zs4.25": 4.25,
         "zs4.5": 4.22,
         "zs4.75": 4.19,
         "zs5": 4.16
        },
        {
         "zs0": 4.48,
         "zs0.25": 4.45,
         "zs0.5": 4.41,
         "zs0.75": 4.38,
         "zs1": 4.34,
         "zs1.25": 4.31,
         "zs1.5": 4.28,
         "zs1.75": 4.24,
         "zs2": 4.21,
         "zs2.25": 4.18,
         "zs2.5": 4.15,
         "zs2.75": 4.12,
         "zs3": 4.09,
         "zs3.25": 4.06,
         "zs3.5": 4.03,
         "zs3.75": 4,
         "zs4": 3.9699999999999998,
         "zs4.25": 3.94,
         "zs4.5": 3.91,
         "zs4.75": 3.89,
         "zs5": 3.86
        },
        {
         "zs0": 4.1,
         "zs0.25": 4.07,
         "zs0.5": 4.04,
         "zs0.75": 4.01,
         "zs1": 3.98,
         "zs1.25": 3.95,
         "zs1.5": 3.93,
         "zs1.75": 3.9,
         "zs2": 3.87,
         "zs2.25": 3.84,
         "zs2.5": 3.82,
         "zs2.75": 3.79,
         "zs3": 3.76,
         "zs3.25": 3.74,
         "zs3.5": 3.71,
         "zs3.75": 3.69,
         "zs4": 3.66,
         "zs4.25": 3.64,
         "zs4.5": 3.62,
         "zs4.75": 3.59,
         "zs5": 3.57
        },
        {
         "zs0": 3.74,
         "zs0.25": 3.71,
         "zs0.5": 3.69,
         "zs0.75": 3.66,
         "zs1": 3.64,
         "zs1.25": 3.62,
         "zs1.5": 3.59,
         "zs1.75": 3.57,
         "zs2": 3.55,
         "zs2.25": 3.52,
         "zs2.5": 3.5,
         "zs2.75": 3.48,
         "zs3": 3.46,
         "zs3.25": 3.43,
         "zs3.5": 3.41,
         "zs3.75": 3.39,
         "zs4": 3.37,
         "zs4.25": 3.35,
         "zs4.5": 3.33,
         "zs4.75": 3.31,
         "zs5": 3.29
        },
        {
         "zs0": 3.39,
         "zs0.25": 3.37,
         "zs0.5": 3.35,
         "zs0.75": 3.33,
         "zs1": 3.31,
         "zs1.25": 3.29,
         "zs1.5": 3.27,
         "zs1.75": 3.25,
         "zs2": 3.23,
         "zs2.25": 3.21,
         "zs2.5": 3.2,
         "zs2.75": 3.18,
         "zs3": 3.16,
         "zs3.25": 3.14,
         "zs3.5": 3.12,
         "zs3.75": 3.1,
         "zs4": 3.09,
         "zs4.25": 3.07,
         "zs4.5": 3.05,
         "zs4.75": 3.04,
         "zs5": 3.02
        },
        {
         "zs0": 3.07,
         "zs0.25": 3.05,
         "zs0.5": 3.03,
         "zs0.75": 3.02,
         "zs1": 3,
         "zs1.25": 2.98,
         "zs1.5": 2.9699999999999998,
         "zs1.75": 2.95,
         "zs2": 2.94,
         "zs2.25": 2.92,
         "zs2.5": 2.9,
         "zs2.75": 2.89,
         "zs3": 2.87,
         "zs3.25": 2.86,
         "zs3.5": 2.84,
         "zs3.75": 2.83,
         "zs4": 2.81,
         "zs4.25": 2.8,
         "zs4.5": 2.79,
         "zs4.75": 2.77,
         "zs5": 2.76
        },
        {
         "zs0": 2.76,
         "zs0.25": 2.75,
         "zs0.5": 2.73,
         "zs0.75": 2.7199999999999998,
         "zs1": 2.7,
         "zs1.25": 2.69,
         "zs1.5": 2.68,
         "zs1.75": 2.66,
         "zs2": 2.65,
         "zs2.25": 2.64,
         "zs2.5": 2.63,
         "zs2.75": 2.61,
         "zs3": 2.6,
         "zs3.25": 2.59,
         "zs3.5": 2.58,
         "zs3.75": 2.56,
         "zs4": 2.55,
         "zs4.25": 2.54,
         "zs4.5": 2.53,
         "zs4.75": 2.52,
         "zs5": 2.51
        },
        {
         "zs0": 2.47,
         "zs0.25": 2.46,
         "zs0.5": 2.44,
         "zs0.75": 2.43,
         "zs1": 2.42,
         "zs1.25": 2.41,
         "zs1.5": 2.4,
         "zs1.75": 2.39,
         "zs2": 2.38,
         "zs2.25": 2.37,
         "zs2.5": 2.36,
         "zs2.75": 2.35,
         "zs3": 2.34,
         "zs3.25": 2.33,
         "zs3.5": 2.32,
         "zs3.75": 2.31,
         "zs4": 2.3,
         "zs4.25": 2.29,
         "zs4.5": 2.28,
         "zs4.75": 2.27,
         "zs5": 2.26
        },
        {
         "zs0": 2.2,
         "zs0.25": 2.19,
         "zs0.5": 2.18,
         "zs0.75": 2.17,
         "zs1": 2.16,
         "zs1.25": 2.15,
         "zs1.5": 2.15,
         "zs1.75": 2.14,
         "zs2": 2.13,
         "zs2.25": 2.12,
         "zs2.5": 2.11,
         "zs2.75": 2.1,
         "zs3": 2.1,
         "zs3.25": 2.09,
         "zs3.5": 2.08,
         "zs3.75": 2.07,
         "zs4": 2.07,
         "zs4.25": 2.06,
         "zs4.5": 2.05,
         "zs4.75": 2.04,
         "zs5": 2.03
        },
        {
         "zs0": 1.95,
         "zs0.25": 1.95,
         "zs0.5": 1.94,
         "zs0.75": 1.9300000000000002,
         "zs1": 1.9300000000000002,
         "zs1.25": 1.92,
         "zs1.5": 1.9100000000000001,
         "zs1.75": 1.9100000000000001,
         "zs2": 1.9,
         "zs2.25": 1.8900000000000001,
         "zs2.5": 1.8900000000000001,
         "zs2.75": 1.88,
         "zs3": 1.87,
         "zs3.25": 1.87,
         "zs3.5": 1.8599999999999999,
         "zs3.75": 1.85,
         "zs4": 1.85,
         "zs4.25": 1.8399999999999999,
         "zs4.5": 1.8399999999999999,
         "zs4.75": 1.83,
         "zs5": 1.8199999999999998
        },
        {
         "zs0": 1.74,
         "zs0.25": 1.73,
         "zs0.5": 1.72,
         "zs0.75": 1.72,
         "zs1": 1.71,
         "zs1.25": 1.71,
         "zs1.5": 1.7,
         "zs1.75": 1.7,
         "zs2": 1.69,
         "zs2.25": 1.69,
         "zs2.5": 1.6800000000000002,
         "zs2.75": 1.6800000000000002,
         "zs3": 1.67,
         "zs3.25": 1.67,
         "zs3.5": 1.6600000000000001,
         "zs3.75": 1.6600000000000001,
         "zs4": 1.65,
         "zs4.25": 1.65,
         "zs4.5": 1.6400000000000001,
         "zs4.75": 1.6400000000000001,
         "zs5": 1.63
        },
        {
         "zs0": 1.54,
         "zs0.25": 1.54,
         "zs0.5": 1.53,
         "zs0.75": 1.53,
         "zs1": 1.52,
         "zs1.25": 1.52,
         "zs1.5": 1.52,
         "zs1.75": 1.51,
         "zs2": 1.51,
         "zs2.25": 1.5,
         "zs2.5": 1.5,
         "zs2.75": 1.5,
         "zs3": 1.49,
         "zs3.25": 1.49,
         "zs3.5": 1.48,
         "zs3.75": 1.48,
         "zs4": 1.48,
         "zs4.25": 1.47,
         "zs4.5": 1.47,
         "zs4.75": 1.46,
         "zs5": 1.46
        },
        {
         "zs0": 1.37,
         "zs0.25": 1.37,
         "zs0.5": 1.3599999999999999,
         "zs0.75": 1.3599999999999999,
         "zs1": 1.3599999999999999,
         "zs1.25": 1.35,
         "zs1.5": 1.35,
         "zs1.75": 1.35,
         "zs2": 1.34,
         "zs2.25": 1.34,
         "zs2.5": 1.34,
         "zs2.75": 1.33,
         "zs3": 1.33,
         "zs3.25": 1.33,
         "zs3.5": 1.32,
         "zs3.75": 1.32,
         "zs4": 1.32,
         "zs4.25": 1.32,
         "zs4.5": 1.31,
         "zs4.75": 1.31,
         "zs5": 1.31
        },
        {
         "zs0": 1.22,
         "zs0.25": 1.21,
         "zs0.5": 1.21,
         "zs0.75": 1.21,
         "zs1": 1.21,
         "zs1.25": 1.2,
         "zs1.5": 1.2,
         "zs1.75": 1.2,
         "zs2": 1.2,
         "zs2.25": 1.2,
         "zs2.5": 1.19,
         "zs2.75": 1.19,
         "zs3": 1.19,
         "zs3.25": 1.19,
         "zs3.5": 1.18,
         "zs3.75": 1.18,
         "zs4": 1.18,
         "zs4.25": 1.18,
         "zs4.5": 1.17,
         "zs4.75": 1.17,
         "zs5": 1.17
        },
        {
         "zs0": 1.1,
         "zs0.25": 1.1,
         "zs0.5": 1.09,
         "zs0.75": 1.09,
         "zs1": 1.09,
         "zs1.25": 1.09,
         "zs1.5": 1.09,
         "zs1.75": 1.08,
         "zs2": 1.08,
         "zs2.25": 1.08,
         "zs2.5": 1.08,
         "zs2.75": 1.08,
         "zs3": 1.08,
         "zs3.25": 1.07,
         "zs3.5": 1.07,
         "zs3.75": 1.07,
         "zs4": 1.07,
         "zs4.25": 1.07,
         "zs4.5": 1.07,
         "zs4.75": 1.06,
         "zs5": 1.06
        },
        {
         "zs0": 0.95,
         "zs0.25": 0.95,
         "zs0.5": 0.95,
         "zs0.75": 0.95,
         "zs1": 0.95,
         "zs1.25": 0.95,
         "zs1.5": 0.94,
         "zs1.75": 0.94,
         "zs2": 0.94,
         "zs2.25": 0.94,
         "zs2.5": 0.94,
         "zs2.75": 0.94,
         "zs3": 0.94,
         "zs3.25": 0.94,
         "zs3.5": 0.94,
         "zs3.75": 0.93,
         "zs4": 0.93,
         "zs4.25": 0.93,
         "zs4.5": 0.93,
         "zs4.75": 0.93,
         "zs5": 0.93
        },
        {
         "zs0": 0.77,
         "zs0.25": 0.77,
         "zs0.5": 0.77,
         "zs0.75": 0.77,
         "zs1": 0.77,
         "zs1.25": 0.77,
         "zs1.5": 0.77,
         "zs1.75": 0.77,
         "zs2": 0.77,
         "zs2.25": 0.77,
         "zs2.5": 0.77,
         "zs2.75": 0.77,
         "zs3": 0.76,
         "zs3.25": 0.76,
         "zs3.5": 0.76,
         "zs3.75": 0.76,
         "zs4": 0.76,
         "zs4.25": 0.76,
         "zs4.5": 0.76,
         "zs4.75": 0.76,
         "zs5": 0.76
        },
        {
         "zs0": 0.74,
         "zs0.25": 0.74,
         "zs0.5": 0.74,
         "zs0.75": 0.74,
         "zs1": 0.74,
         "zs1.25": 0.74,
         "zs1.5": 0.74,
         "zs1.75": 0.74,
         "zs2": 0.74,
         "zs2.25": 0.74,
         "zs2.5": 0.74,
         "zs2.75": 0.74,
         "zs3": 0.74,
         "zs3.25": 0.74,
         "zs3.5": 0.73,
         "zs3.75": 0.73,
         "zs4": 0.73,
         "zs4.25": 0.73,
         "zs4.5": 0.73,
         "zs4.75": 0.73,
         "zs5": 0.73
        },
        {
         "zs0": 0.54,
         "zs0.25": 0.54,
         "zs0.5": 0.54,
         "zs0.75": 0.54,
         "zs1": 0.54,
         "zs1.25": 0.54,
         "zs1.5": 0.54,
         "zs1.75": 0.54,
         "zs2": 0.54,
         "zs2.25": 0.54,
         "zs2.5": 0.54,
         "zs2.75": 0.54,
         "zs3": 0.54,
         "zs3.25": 0.54,
         "zs3.5": 0.54,
         "zs3.75": 0.54,
         "zs4": 0.54,
         "zs4.25": 0.54,
         "zs4.5": 0.54,
         "zs4.75": 0.54,
         "zs5": 0.54
        }
       ]    
}

