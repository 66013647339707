import axios from '@axios'

export default {
  async getBeleg(dosID, sprache) {
    try {
      const r = await axios.get('beleg/getBeleg', {
        params: {
          dosID,
          sprache,
        },
      })
      return r
    } catch (error) {
      // console.log(error)
      this.errored = true
    }
    return null
  },
  async getDossierBelege(dosID, sprache) {
    try {
      const r = await axios.get('beleg/getDossierBelege', {
        params: {
          dosID,
          sprache,
        },
      })
      return r
    } catch (error) {
      // console.log(error)
      this.errored = true
    }
    return null
  },
  async getDossierBeleg(dosBelegID, dosID, belegID, sprache) {
    try {
      const r = await axios.get('beleg/getDossierBeleg', {
        params: {
          dosBelegID,
          dosID,
          belegID,
          sprache,
        },
      })
      return r
    } catch (error) {
      // console.log(error)
      this.errored = true
    }
    return null
  },
  async putDossierBeleg(data) {
    try {
      const r = await axios.get('beleg/getDossierBelegInsUpd', {
        params: {
          data: JSON.stringify(data),
        },
      })
      return r
    } catch (error) {
      // console.log(error)
      this.errored = true
    }
    return null
  },
  async postBelegDatei(dosID, datei, dosBelegID) { // Upload...
    const formData = new FormData()
    try {
      formData.append('beleg', datei)
      const r = await axios.post('beleg/postBelegDatei', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
        params: {
          dosID,
          dosBelegID,
          dateiName: datei.name,
          groesse: datei.size,
        },
      })
      return r
    } catch (error) {
      // console.log(error)
    }
    return null
  },
  async getBelegDatei(dosID, dosBelegID, mime) { // Download...
    try {
      const r = await axios.get('beleg/getBelegDatei', {
        responseType: 'blob',
        headers: { Accept: mime },
        params: {
          dosID,
          dosBelegID,
        },
      })
      return r
    } catch (error) {
      // console.log(error)
    }
    return null
  },
  async getBelegZip(dosID) { // Download archive...
    try {
      const r = await axios.get('beleg/getBelegZip', {
        responseType: 'blob',
        headers: { Accept: 'pplication/zip' },
        params: {
          dosID,
        },
      })
      return r
    } catch (error) {
      // console.log(error)
    }
    return null
  },
  async deleteBeleg(dosID, dosBelegID) {
    try {
      const r = await axios.get('beleg/getBelegDelete', {
        params: {
          dosID,
          dosBelegID,
        },
      })
      return r
    } catch (error) {
      // console.log(error)
      this.errored = true
    }
    return null
  },
}
